import { useStyles } from "./PushNotification.styles";
import { Button, Card, Input, Typography } from "../../ui/atoms";
import { updateSettingProps } from "./PushNotification.types";
import {
  Box,
  Chip,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
} from "@mui/material";
import { colors } from "../../constants/colors";
// import { Tooltip } from "@mui/material";
// import ToggleOnIcon from "@mui/icons-material/ToggleOn";
// import ToggleOffIcon from "@mui/icons-material/ToggleOff";
// import { Input } from "../../../../ui/atoms";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
export const PushNotification = ({
  onSubmit,
  register,
  sendTo,
  setSendTo,
  selectedBoardRoomId,
  handleBoardRoomChange,
  getAllBoardRoomData,
  getAllUserData,
  handleUserChange,
  selectedUserMobileNumber,
  findSelectedBoardRoomName,
  findSelectedUserName,
}: updateSettingProps) => {
  const styles = useStyles();

  return (
    <div className={styles.root}>
      <div className={styles.flexBox}>
        <h2
          style={{
            fontWeight: "bold",
            marginRight: 20,
            // backgroundColor: "rosybrown",
          }}
        >
          Notification Management
        </h2>
      </div>
      <form noValidate onSubmit={onSubmit} className={styles.formCard}>
        <Input
          className={styles.input}
          name="title"
          register={register}
          label="Title"
          InputLabelProps={{
            style: { fontWeight: "500", color: "#344054" },
          }}
        />
        <Input
          multiline
          rows="3"
          className={styles.input}
          register={register}
          name="content"
          label="Content"
          InputLabelProps={{
            style: { fontWeight: "500", color: "#344054" },
          }}
        />
        <InputLabel
          style={{
            fontFamily: "",
            marginBottom: 8,
            color: "#344054",
            fontWeight: "500",
          }}
        >
          Select To
        </InputLabel>
        <Select
          placeholder="Select Status"
          value={sendTo}
          name="sendTo"
          onChange={(event) => setSendTo(`${event.target.value}`)}
          style={{ width: "100%" }}
        >
          <MenuItem key={1} value="1">
            <ListItemText primary="All Customer" />
          </MenuItem>
          <MenuItem key={2} value="2">
            <ListItemText primary="Individual Customer" />
          </MenuItem>
          <MenuItem key={3} value="3">
            <ListItemText primary="Board Room wise" />
          </MenuItem>
        </Select>
        <br />
        <br />
        {sendTo === "3" && (
          <>
            <InputLabel>Select Board Room</InputLabel>
            <Select
              label="Age"
              style={{ width: "100%" }}
              value={selectedBoardRoomId}
              onChange={(event) => handleBoardRoomChange(event)}
              name="boardRoomId"
              multiple
              renderValue={() => (
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                  {findSelectedBoardRoomName?.map((value) => (
                    <>
                      <Chip
                        key={value.id}
                        label={`${value.name} (${value.description})`}
                        onDelete={() => console.log("first")}
                      />
                    </>
                  ))}
                </Box>
              )}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                    width: 250,
                  },
                },
              }}
            >
              {getAllBoardRoomData?.data.map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  <ListItemText
                    primary={`${item.name} (${item.description})`}
                  />
                </MenuItem>
              ))}
            </Select>
          </>
        )}

        {sendTo === "2" && (
          <>
            <InputLabel>Select User</InputLabel>
            <Select
              label="Age"
              style={{ width: "100%" }}
              value={selectedUserMobileNumber}
              onChange={(event) => handleUserChange(event)}
              name="userMobileNumber"
              multiple
              renderValue={() => (
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                  {findSelectedUserName?.map((value) => (
                    <>
                      <Chip
                        key={`${value.first_name} ${value.last_name}`}
                        label={`${value.first_name} ${value.last_name} (${value.email})`}
                        onDelete={() => console.log("first")}
                      />
                    </>
                  ))}
                </Box>
              )}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                    width: 250,
                  },
                },
              }}
            >
              {getAllUserData?.data.map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  <ListItemText
                    primary={`${item.first_name} ${item.last_name} (${item.email})`}
                  />
                </MenuItem>
              ))}
            </Select>
          </>
        )}

        <div className={styles.updateButton}>
          <Button
            size="large"
            type="submit"
            variant="contained"
            bold
            className={styles.buttonUpdate}
            style={{ backgroundColor: colors.primary }}
          >
            Submit
          </Button>
        </div>
      </form>
      {/* </div> */}
    </div>
  );
};
