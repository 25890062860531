// import { AxiosInstance } from "axios";
import { createContext } from "react";

// import { activityTrackerApi } from 'api';

import { LocaleContextValueType } from "./LocaleContext.types";

export const LocaleContext = createContext<LocaleContextValueType | undefined>(
  undefined
);

// export const ActivityTrackerContext = createContext<AxiosInstance>(activityTrackerApi);
