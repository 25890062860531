import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles<Theme>(
  ({ breakpoints, palette, spacing, typography }) => ({
    root: {
      gridTemplateColumns: "repeat(2, 50%)",
      minHeight: "100vh",
      width: "100%",
      padding: 30,
      flexWrap: "unset",
      [breakpoints.down("lg")]: {
        gridTemplateColumns: "100%",
      },
      paddingTop: 20,
    },
    mainDiv: {
      display: "flex",
      flexDirection: "row",
      gap: 30,
      marginTop: 30,
      [breakpoints.down("md")]: {},
    },
    mainDivMedium: {
      display: "flex",
      justifyContent: "center",
      alignContent: "center",
      flexDirection: "row",
      gap: 30,
      marginTop: 30,
      [breakpoints.up("md")]: {
        display: "none",
      },
    },
    mediumSmallDiv: {
      flexDirection: "column",
      display: "flex",
      gap: spacing(2),
    },
    card: {
      backgroundColor: palette.primary.contrastText,
      width: spacing(40),
      height: spacing(30),
      borderRadius: spacing(8),
      display: "flex",
      flexDirection: "column",
      alignContent: "center",
      alignItems: "center",
      alignSelf: "center",
      flexWrap: "unset",
      justifyContent: "center",
      textAlign: "center",
      [breakpoints.down("md")]: {
        width: spacing(42),
      },
    },
    header: {
      fontFamily: "",
      marginBottom: spacing(5),
      fontSize: spacing(10),
      alignItems: "center",
      justifyContent: "center",
      alignSelf: "center",
      [breakpoints.only("sm")]: {
        marginBottom: spacing(4),
      },

      [breakpoints.down("lg")]: {
        fontSize: typography.h3.fontSize,
        lineHeight: typography.h3.lineHeight,
      },
    },
    input: {
      fontFamily: "",
      marginBottom: spacing(5),
      color: "#212121",
    },
    submit: {
      backgroundColor: "#212121",
      color: "white",
    },
    bigCard: {
      backgroundColor: palette.primary.contrastText,
      width: "100%",
      height: spacing(70),
      borderRadius: spacing(8),
      display: "flex",
      flexDirection: "column",
      alignContent: "center",
      alignItems: "center",
      alignSelf: "center",
      flexWrap: "unset",
      justifyContent: "center",
    },
    count: {
      fontSize: spacing(5),
      fontWeight: "bolder",
      textAlign: "center",
    },
    heading: {
      fontSize: spacing(3),
      fontWeight: "normal",
      textAlign: "center",
    },
  })
);
