import { useState } from "react";

import { useStyles } from "./BoradRoomDetails.styles";
import { TabTypes } from "./BoardRoomTabsEnum";
import { TabType } from "../../../ui/molecules/tabs/Tabs.types";
import { Card } from "@mui/material";
import { Tabs } from "../../../ui/molecules";
import { RegistrationDeskContainer } from "./registrationDesk/RegistrationDeskContainer";
import { ResolutionContainer } from "./resolutions/ResolutionContainer";
import { BoardMembersContainer } from "./boardMembers/BoardMembersContainer";
import { useParams } from "react-router-dom";
import { BoardRoomId } from "../../../routing/AppRoute.types";
import { CorrespondentsContainer } from "./correspondents/CorrespondentsContainer";
import { DocumentGeneratorContainer } from "./documentGenerator/DocumentGeneratorContainer";
import { TicketContainer } from "./tickets/TicketsContainer";

export const BoardRoomDetails = () => {
  const { id } = useParams<BoardRoomId>();

  const styles = useStyles();

  const [BoardRoomTabType, setBoardRoomTabType] = useState<TabTypes>(
    TabTypes.RegistrationDesk
  );

  const tabs: TabType<TabTypes>[] = [
    {
      value: TabTypes.RegistrationDesk,
      label: "Registration Desk",
      component: <RegistrationDeskContainer id={id} />,
    },
    {
      value: TabTypes.Resolutions,
      label: "Resolutions",
      component: <ResolutionContainer id={id} />,
    },
    {
      value: TabTypes.BoardMembers,
      label: "Board Members",
      component: <BoardMembersContainer id={id} />,
    },
    {
      value: TabTypes.Correspondents,
      label: "Correspondents",
      component: <CorrespondentsContainer id={id} />,
    },
    {
      value: TabTypes.DocumentGenerator,
      label: "Document Generator",
      component: <DocumentGeneratorContainer id={id} />,
    },
    {
      value: TabTypes.Tickets,
      label: "Tickets",
      component: <TicketContainer id={id} />,
    },
  ];

  return (
    <>
      <div className={styles.container}>
        <Card className={styles.root}>
          <Tabs
            tabs={tabs}
            activeTab={BoardRoomTabType}
            setTab={(tab) => setBoardRoomTabType(tab)}
          />
        </Card>
      </div>
    </>
  );
};
