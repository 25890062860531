import axios from "axios";

// import { patchRefreshToken } from "./signin/signin";
import { Tokens } from "./index.types";

export const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return Promise.reject(error.response);
  }
);

// const JWT_EXPIRED_MESSAGE = "jwt expired";

export const createClient = (
  setTokens: (tokens: Tokens) => void,
  previousTokens: Tokens
) => {
  const client = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${previousTokens.access_token}`,
    },
  });

  client.interceptors.request.use((config: any) => {
    return {
      ...config,
      headers: {
        ...(previousTokens.access_token && {
          Authorization: `Bearer ${previousTokens.access_token}`,
          "Content-Type": "application/json",
        }),
        ...config.headers,
      },
    };
  }, Promise.reject);

  // client.interceptors.response.use(
  //   (response) => response,
  //   async ({ config, response }) => {
  //     if (
  //       response.status === 401 &&
  //       response.data?.message === JWT_EXPIRED_MESSAGE &&
  //       !config._retry
  //     ) {
  //       try {
  //         const {
  //           data: { refresh_token, api_token },
  //         } = await patchRefreshToken({
  //           refresh_token: previousTokens.refreshToken,
  //         });
  //         setTokens({ token: api_token, refreshToken: refresh_token });
  //         return axios({
  //           ...config,
  //           headers: {
  //             ...config.headers,
  //             Authorization: `Bearer ${api_token}`,
  //           },
  //           _retry: true,
  //         });
  //       } catch (error) {
  //         return Promise.reject(response);
  //       }
  //     }

  //     return Promise.reject(response);
  //   }
  // );

  return client;
};
