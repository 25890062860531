import { GlobalLayoutProps } from "./GlobalLayout.types";

export const GlobalLayout = ({ children }: GlobalLayoutProps) => {
  return (
    <>
      {/* Commented out */}
      {/* Task [SCP-1801] for show widget back */}
      {/* <HelpWidget isOpen={isHelpWidgetOpen} onClick={() => setIsHelpWidgetOpen((v) => !v)} /> */}
      {children}
    </>
  );
};
