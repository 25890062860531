import {
  useDeleteBoardRoomById,
  useGetAssignedBoardRoomUsers,
  useGetBoardRoomUsersId,
} from "../../../api/boardRoom/boardRoom";
import { toast, ToastContainer } from "react-toastify";
import { useClient } from "../../../hooks/useClient/useClient";
import { ManageBoardRoom } from "./ManageBoardRoom";
import { useState } from "react";
import { GetBoardRoomUserSuccessResponse } from "../../../api/boardRoom/boardRoom.types";

export const ManageBoardRoomContainer = () => {
  const client = useClient();
  const [userId, setUserId] = useState(0);
  const [boardRoomId, setBoardRoomId] = useState("");
  const [boardRoomAssignedUsers, setBoardRoomAssignedUsers] =
    useState<GetBoardRoomUserSuccessResponse>();

  const { mutate: deleteBoardRoom } = useDeleteBoardRoomById({
    onSuccess: () => {
      toast.success("Boardroom deleted successfully");
    },
    onError: () => {
      toast.error("Failed to delete boardroom");
    },
  });

  const handleDelete = async (id: number) => {
    await deleteBoardRoom({ client, id });
  };

  const { mutate: getBoardroomUsers } = useGetBoardRoomUsersId({
    onSuccess: (data) => {
      setBoardRoomAssignedUsers(data);
    },
    onError: () => {
      toast.error("Failed to fetch boardroom users");
    },
  });

  const { data: assignedBoardRoomUsers } = useGetAssignedBoardRoomUsers({
    client,
    id: boardRoomId,
    options: {
      enabled: !!boardRoomId,
    },
  });

  const handleAssignedBoardRoomUsers = (id: string) => {
    setBoardRoomId(id);
  };

  return (
    <>
      <ManageBoardRoom
        handleDelete={handleDelete}
        setUserId={setUserId}
        userId={userId}
        boardRoomsUsers={boardRoomAssignedUsers}
        getBoardroomUsers={getBoardroomUsers}
        boardRoomId={boardRoomId}
        setBoardRoomId={setBoardRoomId}
        handleAssignedBoardRoomUsers={handleAssignedBoardRoomUsers}
        assignedBoardRoomUsers={assignedBoardRoomUsers}
      />
      <ToastContainer autoClose={3000} />
    </>
  );
};
