import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles<Theme>(
  ({
    breakpoints,
    spacing,
    typography: { fontWeightBold, h3 },
    palette: { darkGray, white, red, black, green },
  }) => ({
    root: {
      gridTemplateColumns: "repeat(2, 50%)",
      minHeight: "100vh",
      width: "100%",
      padding: 30,
      flexWrap: "unset",
      [breakpoints.down("lg")]: {
        gridTemplateColumns: "100%",
      },
      paddingTop: 20,
    },
    root2: {
      width: "100%",
      display: "flex",
      flexDirection: "row",
      // backgroundColor: "green",
    },
    right1: {
      // backgroundColor: "blue",
      display: "flex",
      flexDirection: "column",
      marginBottom: 30,
    },
    flexBox: {
      // backgroundColor: "green",
    },
    formCard: {
      // backgroundColor: "greenyellow",
      padding: 25,
      width: "50%",
    },
    mainDiv: {
      padding: spacing(2),
    },
    head: {
      display: "flex",
      FlexDirection: "column",
      width: "100%",
    },
    headingStandard: {
      background: "#f6f6f6",
      padding: `${spacing(1)}`,
      margin: `${spacing(0)} ${spacing(10)} ${spacing(0)} ${spacing(2)} `,
    },
    row: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      gap: `${spacing(20)}`,
      marginLeft: `${spacing(6)}`,
    },
    descriptionInput: {
      width: `${spacing(60)}`,
    },
    amountInput: {
      width: `${spacing(60)}`,
    },
    updateButton: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-end",
      justifyContent: "flex-end",
    },
    buttonUpdate: {
      color: `${white.main} !important`,
      borderColor: `${red.main} !important`,
      background: "##213c97 !important",
    },
    columnField: {
      margin: `${spacing(2)}`,
    },
  })
);
