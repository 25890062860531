import clsx from "clsx";
import { useTable, useGridLayout, useSortBy } from "react-table";

import { useStyles } from "./Table.styles";
import { TableProps } from "./Table.types";

export const Table = <T extends Record<never, never>>({
  columns,
  data,
  className,
  onRowClick,
}: TableProps<T>) => {
  const styles = useStyles();
  const { getTableProps, headerGroups, rows, prepareRow, getTableBodyProps } =
    useTable<T>(
      {
        columns,
        data,
      },
      useGridLayout,
      useSortBy
    );

  return (
    <div {...getTableProps({ className: clsx(styles.table, className) })}>
      {headerGroups.map(({ headers }) =>
        headers.map((column) => (
          <div
            {...column.getHeaderProps({ className: styles.th })}
            style={{
              paddingLeft: 10,
            }}
          >
            {column.render("Header")}
          </div>
        ))
      )}
      <div {...getTableBodyProps({ className: styles.tr })}>
        {rows.map((row, index) => {
          prepareRow(row);

          return (
            <div
              {...row.getRowProps({ className: styles.tr, key: index })}
              onClick={() =>
                onRowClick ? onRowClick(row.original) : undefined
              }
            >
              {row.cells.map((cell, index) => (
                <div
                  {...cell.getCellProps()}
                  key={index}
                  className={styles.td}
                  style={{
                    paddingLeft: 10,
                  }}
                >
                  {cell.render("Cell")}
                </div>
              ))}
            </div>
          );
        })}
      </div>
    </div>
  );
};
