import { useMediaQuery, useTheme } from "@mui/material";
import Link from "@mui/material/Link";
import clsx from "clsx";
import { useLocation } from "react-router";
import { Link as ReactRouterLink } from "react-router-dom";

import { useStyles } from "./MenuOption.styles";
import { MenuOptionProps } from "./MenuOption.types";
import { Typography } from "../../../../../ui/atoms";
import { useLocale } from "../../../../../hooks/useLocale/useLocale";

export const MenuOption = ({
  id,
  path,
  icon,
  isSidebarExpanded,
  handleSidebarDisplayed,
}: MenuOptionProps) => {
  const { formatMessage } = useLocale();
  const styles = useStyles({ isSidebarExpanded });
  const { pathname } = useLocation();
  const isDesktop = useMediaQuery(useTheme().breakpoints.up("lg"));

  return (
    <Link
      key={id}
      to={path}
      component={ReactRouterLink}
      className={clsx(
        styles.navigationItem,
        path === pathname
          ? styles.navigationActiveItem
          : styles.navigationInactiveItem
      )}
      onClick={() => !isDesktop && handleSidebarDisplayed(false)}
      underline="none"
    >
      <div
        className={clsx(
          "navigationItemInnerWrapper",
          styles.navigationItemInnerWrapper
        )}
        style={
          {
            // backgroundColor: "red",
          }
        }
      >
        <div className={clsx("navigationIcon", styles.navigationIcon)}>
          {icon}
        </div>
        {isSidebarExpanded && (
          <Typography
            variant="body1"
            className={styles.navigationText}
            style={{
              // backgroundColor: "green",
              fontWeight: "600",
            }}
          >
            {formatMessage({ id: id as string })}
          </Typography>
        )}
      </div>
    </Link>
  );
};
