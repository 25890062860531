import { Card } from "@mui/material";
import { Input, Typography, Button } from "../../ui/atoms";
import { useStyles } from "./ForgotPassword.styles";

export const ForgotPassword = () => {
  const styles = useStyles();

  return (
    <div className={styles.root}>
      <Card
        elevation={10}
        className={styles.card}
        style={{ borderRadius: "8%" }}
      >
        <Typography className={styles.header}>Forgot Password</Typography>
        <Input
          boldLabel
          label={"Email"}
          name="email"
          type="email"
          aria-label="Email address"
          className={styles.input}
          placeholder={"Enter email address"}
        />
        <div className={styles.buttonDiv}>
          <Button type="submit" size="large" bold className={styles.submit}>
            Submit
          </Button>
        </div>
      </Card>
    </div>
  );
};
