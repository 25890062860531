import MenuIcon from "@mui/icons-material/Menu";
import LogoutIcon from "@mui/icons-material/Logout";
import { Link } from "@mui/material";
import clsx from "clsx";
import BoardRoomLogo from "../../../../assets/img/boardroom.png";
import { MenuOptionTypes } from "../Dashboard.types";
import { menuItems } from "../Dashboard.utils";

import { ListMenuOption } from "./menuOption/listMenuOption/ListMenuOption";
import { MenuOption } from "./menuOption/MenuOption";
import { useStyles } from "./Sidebar.styles";
import { SidebarProps } from "./Sidebar.types";
import { Typography } from "../../../../ui/atoms";
import { useNavigate } from "react-router-dom";
import { useLocale } from "../../../../hooks/useLocale/useLocale";
import { useDispatch } from "react-redux";

export const Sidebar = ({
  handleSidebarDisplayed,
  isSidebarDisplayed,
  isSidebarExpanded,
  onSidebarExpanded,
}: SidebarProps) => {
  const styles = useStyles({ isSidebarExpanded, isSidebarDisplayed });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { formatMessage } = useLocale();

  return (
    <>
      <div className={styles.sidebar}>
        <div className={styles.sidebarContainer}>
          <div className={styles.sidebarNavigationContainer}>
            <div className={styles.logoSection}>
              <img
                src={BoardRoomLogo}
                alt="Boardroom image"
                className={styles.logo}
              />
              <Typography variant="h5" className={styles.header}>
                The Boardroom
              </Typography>
            </div>
            <div className="scrollTest">
              <div className={styles.sidebarNavigation}>
                <div className={styles.menuOptionsWrapper}>
                  {menuItems.map(({ icon, id, path, subMenuItems, type }) =>
                    type === MenuOptionTypes.Single ? (
                      <MenuOption
                        key={id}
                        isSidebarExpanded={isSidebarExpanded}
                        handleSidebarDisplayed={handleSidebarDisplayed}
                        id={id}
                        path={path}
                        icon={icon}
                      />
                    ) : (
                      <ListMenuOption
                        key={id}
                        isSidebarExpanded={isSidebarExpanded}
                        handleSidebarDisplayed={handleSidebarDisplayed}
                        id={id}
                        path={path}
                        icon={icon}
                        subMenuItems={subMenuItems || []}
                      />
                    )
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
