import { TypographyOptions } from "@mui/material/styles/createTypography";

export const fontWeight = {
  regular: 400,
  bold: 700,
};

const fontFamily = {
  heading: "Arial",
  body: "Arial",
};

export const typography: TypographyOptions = {
  fontFamily: fontFamily.body,
  fontWeightRegular: fontWeight.regular,
  fontWeightBold: fontWeight.bold,

  h1: {
    fontFamily: fontFamily.heading,
    fontSize: 46,
    fontWeight: fontWeight.regular,
    lineHeight: "72px",
  },
  h2: {
    fontFamily: fontFamily.heading,
    fontSize: 32,
    fontWeight: fontWeight.regular,
    lineHeight: "54px",
  },
  h3: {
    fontFamily: fontFamily.heading,
    fontSize: 28,
    fontWeight: fontWeight.regular,
    lineHeight: "45px",
  },
  h4: {
    fontFamily: fontFamily.heading,
    fontSize: 24,
    fontWeight: fontWeight.regular,
    lineHeight: "39px",
  },
  h5: {
    fontFamily: fontFamily.heading,
    fontSize: 19,
    fontWeight: fontWeight.regular,
    lineHeight: "31.5px",
  },
  subtitle1: {
    fontSize: 22,
    fontWeight: fontWeight.regular,
    lineHeight: "36px",
  },
  body1: {
    fontSize: 14,
    fontWeight: fontWeight.regular,
    lineHeight: "24px",
  },
  body2: {
    fontSize: 12,
    fontWeight: fontWeight.regular,
    lineHeight: "21px",
  },
  caption: {
    fontSize: 12,
    fontWeight: fontWeight.regular,
    lineHeight: "21px",
    fontStyle: "italic",
  },
  button: {
    fontSize: 14,
    fontWeight: fontWeight.regular,
    textTransform: "none",
    lineHeight: "24px",
  },
};
