import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles<Theme>(
  ({ breakpoints, palette, spacing, typography }) => ({
    root: {
      gridTemplateColumns: "repeat(2, 50%)",
      minHeight: "100vh",
      width: "100%",
      padding: 30,
      flexWrap: "unset",
      [breakpoints.down("lg")]: {
        gridTemplateColumns: "100%",
      },
      paddingTop: 20,
    },
    card: {
      backgroundColor: palette.primary.contrastText,
      width: "95%",
      padding: "5%",
      flexDirection: "row",
      alignContent: "center",
      alignItems: "center",
      alignSelf: "center",
      flexWrap: "unset",
    },
    header: {
      fontFamily: "",
      marginBottom: spacing(5),
      fontSize: spacing(6),
      alignItems: "center",
      justifyContent: "center",
      alignSelf: "center",
      [breakpoints.only("sm")]: {
        marginBottom: spacing(4),
      },

      [breakpoints.down("lg")]: {
        fontSize: typography.h3.fontSize,
        lineHeight: typography.h3.lineHeight,
      },
    },
    input: {
      fontFamily: "",
      marginBottom: spacing(3),
      color: "#212121",
      borderColor: ` ${palette.grey[300]} !important`,
    },
    submit: {
      // color: "white"
      display: "flex",
      justifyContent: "center",
      alignContent: "center",
      alignItems: "center",
      width: `200px !important`,
      margin: `${spacing(1, 2)} !important`,
      color: "white",
      // color: `${palette.green.main} !important`,
      // borderColor: `${palette.green.main} !important`,
    },
    cancel: {
      // color: "white"
      display: "flex",
      justifyContent: "center",
      alignContent: "center",
      alignItems: "center",
      width: `200px !important`,
      margin: `${spacing(1, 2)} !important`,
      color: `${palette.red.main} !important`,
      borderColor: `${palette.red.main} !important`,
    },
    rowDiv: {
      display: "flex",
      flexDirection: "column",
      gap: spacing(2),
      marginBottom: spacing(3),
    },
    buttonRow: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-end",
    },
    datePicker: {
      width: "100% !important",
    },
  })
);
