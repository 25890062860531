import { Input, Button } from "../../../../ui/atoms";
import { useStyles } from "./RegistrationDesk.styles";
import { DropzoneBox } from "../../../../ui/atoms/dropZoneBox/DropZoneBox";
import { useMemo } from "react";
import { CreateRegistrationDeskProps } from "./RegistrationDesk.types";
import { Column } from "react-table";
import { Table } from "../../../../ui/organisms/table/Table";
import { Modal } from "../../../../ui/organisms/modal/Modal";
import { DialogContent, Tooltip } from "@mui/material";
import { Typography } from "@mui/material";
import { SuccessData } from "../../../../api/boardRoom/correspondents/Correspondents.types";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { PDFViewer } from "react-view-pdf";
import { useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import { EditRegistrationDeskModalContainer } from "./editRegistrationDeskModal/EditRegistrationDeskModalContainer";

export const RegistrationDesk = ({
  register,
  onSubmit,
  errors,
  onDropHandleFunc,
  thumbnail,
  registrationDesk,
  setOpenContactsModal,
  setOpenAddModal,
  isOpenContactsModal,
  isOpenAddModal,
  handleDeleteRegistrationDesk,
  openEditRegistrationDeskModal,
  setOpenEditRegistrationDeskModal,
  setRegistrationDeskId,
  getRegistrationDeskDataById,
  disableSubmitButton,
}: CreateRegistrationDeskProps) => {
  const styles = useStyles();
  const [isOpenPDFModal, setOpenPDFModal] = useState(false);
  const [file, setFile] = useState("");
  const [userId, setUserId] = useState(0);
  const [Id, setId] = useState("");
  const accept = {
    "application/pdf": [],
    // "image/jpeg": [".jpg", ".jpeg"],
    // "image/png": [".png"],
  };

  const openInNewTab = (url: string) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  const columns: Column<SuccessData>[] = useMemo(
    () => [
      {
        id: "name",
        Header: "Name",
        accessor: "name",
        Cell: ({ value }) => (
          <div className={styles.truncatedText}>{value}</div>
        ),
      },
      {
        id: "description",
        Header: "Description",
        accessor: "description",
        Cell: ({ value }) => (
          <div className={styles.truncatedText}>{value}</div>
        ),
      },
      {
        id: "id",
        Header: "Actions",
        accessor: "id",
        Cell: ({
          row: {
            original: { files, id },
          },
        }) => (
          <div className={styles.truncatedText}>
            <div className={styles.actionButtons}>
              <Button
                variant="text"
                onClick={() => {
                  const data = JSON.parse(files as unknown as string);
                  // setOpenPDFModal(true);
                  setFile(data[0]);
                  openInNewTab(data[0]);
                }}
              >
                <Tooltip title="View Document">
                  <PictureAsPdfIcon />
                </Tooltip>
              </Button>
              <Button
                className={styles.buttonView}
                variant="text"
                onClick={async () => {
                  setRegistrationDeskId(`${id}`);
                  setId(`${id}`);
                  await setOpenEditRegistrationDeskModal(true);
                }}
              >
                <Tooltip title="Edit">
                  <EditIcon />
                </Tooltip>
              </Button>
              <Button
                className={styles.buttonView}
                variant="text"
                onClick={async () => {
                  await setUserId(id);
                  setOpenContactsModal(true);
                }}
              >
                <Tooltip title="Delete">
                  <DeleteOutlineIcon />
                </Tooltip>
              </Button>
            </div>
          </div>
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [registrationDesk?.data]
  );

  return (
    <div className={styles.mainDiv}>
      <div className={styles.buttonDiv}>
        <Button
          type="button"
          size="large"
          variant="outlined"
          bold
          className={styles.submit}
          onClick={() => setOpenAddModal(true)}
        >
          Add Document
        </Button>
      </div>
      {registrationDesk?.data && registrationDesk?.data?.length > 0 ? (
        <Table
          columns={columns}
          data={registrationDesk?.data || []}
          className={styles.table}
        />
      ) : (
        <>No DataAvailable</>
      )}
      {isOpenAddModal && (
        <Modal open={isOpenAddModal} onClose={() => setOpenAddModal(false)}>
          <form noValidate className={styles.cardForm}>
            <DialogContent>
              <div className={styles.head}>
                <div className={styles.rowDiv}>
                  <Typography
                    align="center"
                    variant="h4"
                    className={styles.lable}
                  >
                    Add Registration Desk
                  </Typography>
                  <Input
                    label="Index"
                    aria-label="index"
                    placeholder={"Enter sort order"}
                    name="index"
                    className={styles.input}
                    error={!!errors?.index}
                    helperText={errors?.index?.message}
                    register={register}
                  />
                  <Input
                    required
                    label="Name"
                    aria-label="name"
                    placeholder={"Enter name"}
                    name="name"
                    className={styles.input}
                    error={!!errors?.name}
                    helperText={errors?.name?.message}
                    register={register}
                  />
                  <Input
                    required
                    label="Description"
                    aria-label="description"
                    placeholder={"Enter Description"}
                    name="description"
                    className={styles.input}
                    error={!!errors?.description}
                    helperText={errors?.description?.message}
                    register={register}
                  />
                  <div className={styles.rowDiv}>
                    {thumbnail !== null && thumbnail.length !== 0 ? (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          gap: "5%",
                          width: "100%",
                        }}
                      >
                        <PDFViewer url={thumbnail} />
                        <div style={{ width: "28%" }}>
                          <DropzoneBox
                            multiple={false}
                            description="Choose document"
                            onDrop={onDropHandleFunc}
                            accept={accept}
                          >
                            + Choose Document
                          </DropzoneBox>
                        </div>
                      </div>
                    ) : (
                      <DropzoneBox
                        multiple={false}
                        description="Choose document"
                        onDrop={onDropHandleFunc}
                        accept={accept}
                      >
                        + Choose Document
                      </DropzoneBox>
                    )}
                  </div>
                </div>
              </div>
            </DialogContent>
            <Modal.Footer>
              <div className={styles.buttonsContainer}>
                <Button
                  type="button"
                  bold
                  size="large"
                  onClick={() => setOpenAddModal(false)}
                  variant="outlined"
                  className={styles.buttonyes}
                >
                  Cancel
                </Button>

                <Button
                  // disabled={disableSubmitButton}
                  size="large"
                  type="button"
                  variant="outlined"
                  color="inherit"
                  bold
                  className={styles.closeButton}
                  onClick={() => onSubmit()}
                >
                  Submit
                </Button>
              </div>
            </Modal.Footer>
          </form>
        </Modal>
      )}
      {isOpenContactsModal && (
        <Modal
          open={isOpenContactsModal}
          onClose={() => setOpenContactsModal(false)}
        >
          <DialogContent>
            <div className={styles.head}>
              <Typography align="center" variant="h5" className={styles.lable}>
                Are you sure you want to delete this registration desk?
              </Typography>
            </div>
          </DialogContent>
          <Modal.Footer>
            <div className={styles.buttonsContainer}>
              <Button
                type="button"
                bold
                size="large"
                onClick={() => setOpenContactsModal(false)}
                variant="outlined"
                className={styles.closeButton}
              >
                No
              </Button>

              <Button
                size="large"
                type="button"
                variant="outlined"
                color="inherit"
                bold
                className={styles.buttonyes}
                onClick={async () => {
                  await handleDeleteRegistrationDesk(userId);
                  setOpenContactsModal(false);
                }}
              >
                Yes
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
      )}
      {isOpenPDFModal && (
        <Modal
          open={isOpenPDFModal}
          onClose={() => setOpenPDFModal(false)}
          style={{ width: "100%", height: "90%" }}
        >
          <DialogContent>
            <div className={styles.head}>
              {file?.includes("pdf") ? (
                <PDFViewer url={file} />
              ) : (
                <div
                  style={{
                    display: "flex",
                    alignSelf: "center",
                    justifyContent: "center",
                    width: "100%",
                  }}
                >
                  <img src={file} width="500" height="500" alt="img" />
                </div>
              )}
            </div>
          </DialogContent>
          <Modal.Footer>
            <div className={styles.buttonsContainer}>
              <Button
                type="button"
                bold
                size="large"
                onClick={() => setOpenPDFModal(false)}
                variant="outlined"
                className={styles.closeButton}
              >
                Ok
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
      )}

      {openEditRegistrationDeskModal && (
        <EditRegistrationDeskModalContainer
          isOpen={openEditRegistrationDeskModal}
          onClose={() => setOpenEditRegistrationDeskModal(false)}
          registrationDeskId={Id}
          getRegistrationDeskDataById={getRegistrationDeskDataById}

          // requestCCopyId={String(reqId)}
          // handleStatusChangeAPICall={handleStatusChangeAPICall}
        />
      )}
    </div>
  );
};
