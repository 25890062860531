import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

import { UseStylesProps } from "./Tabs.types";

export const useStyles = makeStyles<Theme, UseStylesProps>(
  ({
    palette: { gray, black, white, orange },
    spacing,
    typography: { fontWeightBold },
    breakpoints,
  }) => ({
    tabsContainer: {
      borderBottom: spacing(0.125, "solid", gray.main),
      minHeight: "initial",
      width: "100%",
      backgroundColor: white.main,
      "& .MuiTabs-indicator": {
        backgroundColor: orange.light,
        height: 3,
      },
      "& .MuiTab-root.Mui-selected": {
        color: orange.light,
      },
    },
    tab: {
      paddingLeft: 8,
      paddingRight: 8,
      minHeight: "initial",
      fontWeight: fontWeightBold,
      minWidth: `${spacing(0)} !important`,
      width: ({ tabsCount }) => `calc(100% / ${tabsCount})`,
      [breakpoints.down("sm")]: {
        flexGrow: 1,
        maxWidth: "none",
        flexBasis: 0,
        flexShrink: 1,
      },
    },
    tabLink: {
      width: "100%",
      padding: spacing(0),
      color: `${black.dark} !important`,
      fontWeight: `${fontWeightBold} !important`,
      fontSize: spacing(1.75),
    },
    selectedTab: {
      color: `${black.main} !important`,
      fontWeight: fontWeightBold,
      borderColor: `${black.main} !important`,
    },
    select: {
      "& .tabs-select": {
        "&__control": {
          borderStyle: "none",
          borderBottomStyle: "solid",
          borderBottomWidth: spacing(0.25),
          borderRadius: 0,
          borderColor: `${orange.light} !important`,
          boxShadow: "none",
          "&:hover": {
            borderWidth: spacing(0, 0, 0.25, 0),
            borderColor: `${orange.light} !important`,
          },
        },
        "&__control--menu-is-open": {
          "& .tabs-select__dropdown-indicator": {
            transform: "rotate(180deg)",
          },
        },
        "&__value-container": {
          padding: spacing(1, 4),
        },
        "&__single-value": {
          fontFamily: "Open Sans",
          fontSize: spacing(2.625),
          color: `${orange.light} !important`,
          lineHeight: 1.5,
          fontWeight: "bold",
        },
        "&__indicator-separator": {
          display: "none",
        },
        "&__dropdown-indicator": {
          color: `${orange.light} !important`,
        },
        "&__menu": {
          margin: 0,
          zIndex: 100,
          "&-list": {
            padding: 0,
          },
        },
        "&__option": {
          fontSize: `${spacing(2)} !important`,
          color: gray.dark,
          lineHeight: 1.5,
          fontWeight: "bold",
          padding: spacing(1, 3),
          backgroundColor: orange.light,
          "&:focus": { backgroundColor: orange.light },
          "&:active": { backgroundColor: orange.light },
          "&:hover": { backgroundColor: orange.light },
        },
      },
    },
    tabColor: { backgroundColor: orange.light },
  })
);
