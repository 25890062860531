import GroupIcon from "@mui/icons-material/Group";
import StoreIcon from "@mui/icons-material/Store";
import AddBusinessIcon from "@mui/icons-material/AddBusiness";
import BusinessIcon from "@mui/icons-material/Business";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import SyncAltIcon from "@mui/icons-material/SyncAlt";
import SettingsIcon from "@mui/icons-material/Settings";
import { MenuOptionTypes } from "./Dashboard.types";
import { AppRoute } from "../../../routing/AppRoute.enum";
import NotificationsIcon from "@mui/icons-material/Notifications";
import LogoutIcon from "@mui/icons-material/Logout";
// import ArticleIcon from "@mui/icons-material/Article";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import VillaOutlinedIcon from "@mui/icons-material/VillaOutlined";

export const menuItems = [
  {
    icon: <SupportAgentIcon />,
    path: AppRoute.manageCustomers,
    type: MenuOptionTypes.Single,
    // subMenuItems: [
    //   {
    //     id: "Create Board Rooms",
    //     path: AppRoute.createBoardRoom,
    //     icon: <AddBusinessIcon />,
    //   },
    //   {
    //     id: "Manage Board Rooms",
    //     path: AppRoute.manageBoardRoom,
    //     icon: <BusinessIcon />,
    //   },
    // ],
    id: "Customer Management",
  },
  {
    icon: <VillaOutlinedIcon />,
    path: AppRoute.manageBoardRoom,
    type: MenuOptionTypes.Single,
    subMenuItems: [
      {
        id: "Create Board Rooms",
        path: AppRoute.createBoardRoom,
        icon: <AddBusinessIcon />,
      },
      {
        id: "Manage Board Rooms",
        path: AppRoute.manageBoardRoom,
        icon: <BusinessIcon />,
      },
    ],
    id: "Board Rooms",
  },
  // {
  //   icon: <GroupIcon />,
  //   path: AppRoute.manageUser,
  //   type: MenuOptionTypes.Single,

  //   // subMenuItems: [
  //   //   {
  //   //     id: "Create Users",
  //   //     path: AppRoute.createUser,
  //   //     icon: <PersonAddIcon />,
  //   //   },
  //   //   {
  //   //     id: "Manage Users",
  //   //     path: AppRoute.manageUser,
  //   //     icon: <ManageAccountsIcon />,
  //   //   },
  //   // ],
  //   id: "User Management",
  // },
  // {
  //   icon: <ArticleIcon />,
  //   path: AppRoute.document,
  //   type: MenuOptionTypes.Single,

  //   // subMenuItems: [
  //   //   {
  //   //     id: "Create Users",
  //   //     path: AppRoute.createUser,
  //   //     icon: <PersonAddIcon />,
  //   //   },
  //   //   {
  //   //     id: "Manage Users",
  //   //     path: AppRoute.manageUser,
  //   //     icon: <ManageAccountsIcon />,
  //   //   },
  //   // ],
  //   id: "Document",
  // },

  {
    icon: <SyncAltIcon />,
    path: AppRoute.reuestCC,
    type: MenuOptionTypes.Single,
    id: "Request CC",
  },
  // {
  //   icon: <NotificationsIcon />,
  //   path: AppRoute.pushNotification,
  //   type: MenuOptionTypes.Single,
  //   id: "Notification",
  // },
  {
    icon: <SettingsOutlinedIcon />,
    path: AppRoute.settings,
    type: MenuOptionTypes.Single,
    id: "Settings",
  },
  {
    icon: <LogoutIcon />,
    path: AppRoute.logout,
    type: MenuOptionTypes.Single,
    id: "Log out",
  },
];

export const TOPBAR_HEIGHT = 56;
export const SIDEBAR_WIDTH = 80;
export const SIDEBAR_WIDTH_EXPANDED = "15%";
