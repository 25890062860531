import { DialogContent, Typography } from "@mui/material";
import { useStyles } from "./BoardMembers.styles";
import { useMemo, useState } from "react";
import { Column } from "react-table";
import { Button } from "../../../../ui/atoms/button/Button";
import { Table } from "../../../../ui/organisms/table/Table";
import { Modal } from "../../../../ui/organisms/modal/Modal";
import { CreateBoardMemberProps } from "./BoardMembers.types";
import { Input } from "../../../../ui/atoms";
import { DropzoneBox } from "../../../../ui/atoms/dropZoneBox/DropZoneBox";
import { SuccessData } from "../../../../api/boardRoom/boardMember/BoardMember.types";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { Tooltip } from "@mui/material";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import EditIcon from "@mui/icons-material/Edit";

export const BoardRoomMembers = ({
  register,
  onSubmit,
  errors,
  onDropHandleFunc,
  thumbnail,
  handleDelete,
  id,
  setOpenAddModal,
  isOpenAddModal,
  mobile,
  setMobile,
  boardRooms,
  isOpenEditModal,
  setOpenEditModal,
  openEditBoardRoomMemberModal,
  onUpdateBoardRoomMember,
  setBoardRoomMemberId,
}: CreateBoardMemberProps) => {
  const styles = useStyles();
  const [isOpenContactsModal, setOpenContactsModal] = useState(false);
  const [userId, setUserId] = useState(0);
  const accept = {
    "image/jpeg": [],
    "image/png": [],
  };

  const columns: Column<SuccessData>[] = useMemo(
    () => [
      {
        id: "image",
        Header: "Photo",
        accessor: "image",
        Cell: ({ value }) => (
          <div className={styles.image}>
            <img
              src={value}
              alt="logo"
              height={50}
              width={50}
              style={{ borderRadius: 5 }}
            />
          </div>
        ),
      },
      {
        id: "name",
        Header: "Name",
        accessor: "name",
        Cell: ({ value }) => (
          <div className={styles.truncatedText}>{value}</div>
        ),
      },
      {
        id: "nic",
        Header: "NIC",
        accessor: "nic",
        Cell: ({ value }) => (
          <div className={styles.truncatedText}>{value}</div>
        ),
      },
      {
        id: "mobile",
        Header: "Phone",
        accessor: "mobile",
        Cell: ({ value }) => (
          <div className={styles.truncatedText}>
            {Array.from(value)[0] !== "+" ? `+${value}` : value}
          </div>
        ),
      },
      {
        id: "email",
        Header: "Email",
        accessor: "email",
        Cell: ({ value }) => (
          <div className={styles.truncatedText}>{value}</div>
        ),
      },
      {
        id: "designation",
        Header: "Designation",
        accessor: "designation",
        Cell: ({ value }) => (
          <div className={styles.truncatedText}>{value}</div>
        ),
      },
      {
        id: "id",
        Header: "Actions",
        accessor: "id",
        Cell: ({ value }) => (
          <div className={styles.truncatedText}>
            <div className={styles.actionButtons}>
              <Button
                className={styles.buttonView}
                variant="text"
                onClick={() => {
                  openEditBoardRoomMemberModal(value);
                  setBoardRoomMemberId(value);
                }}
              >
                <Tooltip title="Edit">
                  <EditIcon color="success" />
                </Tooltip>
              </Button>
              <Button
                className={styles.buttonView}
                variant="text"
                onClick={async () => {
                  await setUserId(value);
                  setOpenContactsModal(true);
                }}
              >
                <Tooltip title="Delete">
                  <DeleteOutlineIcon color="error" />
                </Tooltip>
              </Button>
            </div>
          </div>
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [boardRooms?.data]
  );

  return (
    <div className={styles.mainDiv}>
      <div className={styles.buttonDiv}>
        <Button
          type="button"
          size="large"
          variant="outlined"
          bold
          className={styles.submit}
          onClick={() => setOpenAddModal(true)}
        >
          Add Board Members
        </Button>
      </div>
      <Table
        columns={columns}
        data={boardRooms?.data || []}
        className={styles.table}
      />
      {isOpenAddModal && (
        <Modal open={isOpenAddModal} onClose={() => setOpenAddModal(false)}>
          <form noValidate onSubmit={onSubmit} className={styles.cardForm}>
            <DialogContent>
              <div className={styles.head}>
                <div className={styles.rowDiv}>
                  <Typography
                    align="center"
                    variant="h4"
                    className={styles.lable}
                  >
                    Add Board Member
                  </Typography>
                  <Input
                    required
                    label="Name"
                    aria-label="name"
                    placeholder={"Enter name"}
                    name="name"
                    className={styles.input}
                    error={!!errors?.name}
                    helperText={errors?.name?.message}
                    register={register}
                  />
                  <Typography
                    align="left"
                    variant="h5"
                    style={{ fontSize: "15px", marginBottom: -10 }}
                  >
                    Phone number
                  </Typography>
                  <PhoneInput
                    country={"lk"}
                    value={mobile}
                    onChange={(phone) => setMobile(phone)}
                    enableSearch={true}
                    inputStyle={{ height: "50px", width: "100%" }}
                    countryCodeEditable={false}
                  />
                  {/* <Input
                    required
                    label="Phone number"
                    aria-label="mobile"
                    placeholder={"Enter phone number"}
                    name="mobile"
                    className={styles.input}
                    error={!!errors?.mobile}
                    helperText={errors?.mobile?.message}
                    register={register}
                  /> */}
                  {/* <Input
                    required
                    label="Country code"
                    aria-label="country_code"
                    placeholder={"Enter country code"}
                    name="country_code"
                    className={styles.input}
                    error={!!errors?.country_code}
                    helperText={errors?.country_code?.message}
                    register={register}
                  /> */}
                  <Input
                    required
                    label="Email"
                    aria-label="email"
                    placeholder={"Enter email"}
                    name="email"
                    className={styles.input}
                    error={!!errors?.email}
                    helperText={errors?.email?.message}
                    register={register}
                  />
                  <Input
                    required
                    label="Designation"
                    aria-label="designation"
                    placeholder={"Enter designation"}
                    name="designation"
                    className={styles.input}
                    error={!!errors?.designation}
                    helperText={errors?.designation?.message}
                    register={register}
                  />
                  <Input
                    required
                    label="NIC"
                    aria-label="nic"
                    placeholder={"Enter NIC"}
                    name="nic"
                    className={styles.input}
                    error={!!errors?.nic}
                    helperText={errors?.nic?.message}
                    register={register}
                  />
                  <div className={styles.rowDiv}>
                    {thumbnail !== null && thumbnail.length !== 0 ? (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          gap: "10%",
                        }}
                      >
                        <img
                          src={thumbnail}
                          alt="logo"
                          height={100}
                          width={100}
                          style={{ borderRadius: 10 }}
                        />
                        <div style={{ width: "100%" }}>
                          <DropzoneBox
                            multiple={false}
                            description="Change member image"
                            onDrop={onDropHandleFunc}
                            accept={accept}
                          >
                            + Choose Photo
                          </DropzoneBox>
                        </div>
                      </div>
                    ) : (
                      <DropzoneBox
                        multiple={false}
                        description="Choose member image"
                        onDrop={onDropHandleFunc}
                        accept={accept}
                      >
                        + Choose Photo
                      </DropzoneBox>
                    )}
                  </div>
                </div>
              </div>
            </DialogContent>

            <Modal.Footer>
              <div className={styles.buttonsContainer}>
                <Button
                  type="button"
                  bold
                  size="large"
                  onClick={() => setOpenAddModal(false)}
                  variant="outlined"
                  className={styles.buttonyes}
                >
                  Cancel
                </Button>

                <Button
                  size="large"
                  type="submit"
                  variant="outlined"
                  color="inherit"
                  bold
                  className={styles.closeButton}
                >
                  Submit
                </Button>
              </div>
            </Modal.Footer>
          </form>
        </Modal>
      )}
      {isOpenContactsModal && (
        <Modal
          open={isOpenContactsModal}
          onClose={() => setOpenContactsModal(false)}
        >
          <DialogContent>
            <div className={styles.head}>
              <Typography align="center" variant="h5" className={styles.lable}>
                Are you sure you want to delete this board member?
              </Typography>
            </div>
          </DialogContent>
          <Modal.Footer>
            <div className={styles.buttonsContainer}>
              <Button
                type="button"
                bold
                size="large"
                onClick={() => setOpenContactsModal(false)}
                variant="outlined"
                className={styles.closeButton}
              >
                No
              </Button>

              <Button
                size="large"
                type="submit"
                variant="outlined"
                color="inherit"
                bold
                className={styles.buttonyes}
                onClick={async () => {
                  await handleDelete(userId);
                  setOpenContactsModal(false);
                }}
              >
                Yes
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
      )}
      {isOpenEditModal && (
        <Modal open={isOpenEditModal} onClose={() => setOpenEditModal(false)}>
          <DialogContent>
            <div className={styles.head}>
              <div className={styles.rowDiv}>
                <Typography
                  align="center"
                  variant="h4"
                  className={styles.lable}
                >
                  Edit Board Member
                </Typography>
                <Input
                  required
                  label="Name"
                  aria-label="name"
                  placeholder={"Enter name"}
                  name="name"
                  className={styles.input}
                  error={!!errors?.name}
                  helperText={errors?.name?.message}
                  register={register}
                />
                <Typography
                  align="left"
                  variant="h5"
                  style={{ fontSize: "15px", marginBottom: -10 }}
                >
                  Phone number
                </Typography>
                <PhoneInput
                  country={"lk"}
                  value={mobile}
                  onChange={(phone) => setMobile(phone)}
                  enableSearch={true}
                  inputStyle={{ height: "50px", width: "100%" }}
                  countryCodeEditable={false}
                />
                {/* <Input
                    required
                    label="Phone number"
                    aria-label="mobile"
                    placeholder={"Enter phone number"}
                    name="mobile"
                    className={styles.input}
                    error={!!errors?.mobile}
                    helperText={errors?.mobile?.message}
                    register={register}
                  /> */}
                {/* <Input
                    required
                    label="Country code"
                    aria-label="country_code"
                    placeholder={"Enter country code"}
                    name="country_code"
                    className={styles.input}
                    error={!!errors?.country_code}
                    helperText={errors?.country_code?.message}
                    register={register}
                  /> */}
                <Input
                  required
                  label="Email"
                  aria-label="email"
                  placeholder={"Enter email"}
                  name="email"
                  className={styles.input}
                  error={!!errors?.email}
                  helperText={errors?.email?.message}
                  register={register}
                />
                <Input
                  required
                  label="Designation"
                  aria-label="designation"
                  placeholder={"Enter designation"}
                  name="designation"
                  className={styles.input}
                  error={!!errors?.designation}
                  helperText={errors?.designation?.message}
                  register={register}
                />
                <Input
                  required
                  label="NIC"
                  aria-label="nic"
                  placeholder={"Enter NIC"}
                  name="nic"
                  className={styles.input}
                  error={!!errors?.nic}
                  helperText={errors?.nic?.message}
                  register={register}
                />
                <div className={styles.rowDiv}>
                  {thumbnail !== null && thumbnail.length !== 0 ? (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "10%",
                      }}
                    >
                      <img
                        src={thumbnail}
                        alt="logo"
                        height={100}
                        width={100}
                        style={{ borderRadius: 10 }}
                      />
                      <div style={{ width: "100%" }}>
                        <DropzoneBox
                          multiple={false}
                          description="Change member image"
                          onDrop={onDropHandleFunc}
                          accept={accept}
                        >
                          + Choose Photo
                        </DropzoneBox>
                      </div>
                    </div>
                  ) : (
                    <DropzoneBox
                      multiple={false}
                      description="Choose member image"
                      onDrop={onDropHandleFunc}
                      accept={accept}
                    >
                      + Choose Photo
                    </DropzoneBox>
                  )}
                </div>
              </div>
            </div>
          </DialogContent>

          <Modal.Footer>
            <div className={styles.buttonsContainer}>
              <Button
                type="button"
                bold
                size="large"
                onClick={() => setOpenEditModal(false)}
                variant="outlined"
                className={styles.buttonyes}
              >
                Cancel
              </Button>

              <Button
                size="large"
                type="submit"
                variant="outlined"
                color="inherit"
                bold
                className={styles.closeButton}
                onClick={() => onUpdateBoardRoomMember()}
              >
                Update
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
      )}
    </div>
  );
};
