import { joiResolver } from "@hookform/resolvers/joi";
import Joi from "joi";
import { IntlFormatters } from "react-intl";

import { FormSchema } from "./CreateBoardRoom.types";

const schema = ({
  name,
  description,
  image,
  address,
  whatsAppGroupLink,
}: FormSchema) =>
  Joi.object<FormSchema>({
    name: Joi.string().required().messages(name),
    description: Joi.string().required().messages(description),
    image: Joi.object().unknown(true),
    address: Joi.string().required().messages(address),
    whatsAppGroupLink: Joi.string()
      .optional()
      .allow(null, "")
      .messages(whatsAppGroupLink),
    // dateOfIncoperation: Joi.string().required().messages(dateOfIncoperation),
  });

export const CreateBoardroomSchema = (
  formatMessage: IntlFormatters["formatMessage"]
) =>
  joiResolver(
    schema({
      name: {
        "string.empty": formatMessage({ id: "global.errors.required" }),
        "any.required": formatMessage({ id: "global.errors.required" }),
      },
      description: {
        "string.empty": formatMessage({ id: "global.errors.required" }),
        "any.required": formatMessage({ id: "global.errors.required" }),
      },
      image: {
        "string.empty": formatMessage({ id: "global.errors.required" }),
        "any.required": formatMessage({ id: "global.errors.required" }),
      },
      address: {
        "string.empty": formatMessage({ id: "global.errors.required" }),
        "any.required": formatMessage({ id: "global.errors.required" }),
      },
      whatsAppGroupLink: {
        "string.empty": formatMessage({ id: "global.errors.required" }),
        "any.required": formatMessage({ id: "global.errors.required" }),
      },
    })
  );
