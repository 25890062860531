import { BoardRoomMembers } from "./BoardMembers";
import { useClient } from "../../../../hooks/useClient/useClient";
import { useLocale } from "../../../../hooks/useLocale/useLocale";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { managerBoardMembersModel } from "./BoardMembers.types";
import { CreateBoardMemberSchema } from "./Boardmembers.utils";
import {
  useAddBoardMember,
  useDeleteBoardMemberById,
  useGetBoardMember,
  useUpdateBoardMember,
} from "../../../../api/boardRoom/boardMember/Boardmember";
import { toast, ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { AppRoute } from "../../../../routing/AppRoute.enum";
import { TabTypes } from "../BoardRoomTabsEnum";

export const BoardMembersContainer = ({ id }: { id: string | undefined }) => {
  const client = useClient();
  const { formatMessage } = useLocale();
  const navigate = useNavigate();

  const [thisFile, setThisFile] = useState<any>("");
  const [file, setFile] = useState<File>();
  const [isOpenAddModal, setOpenAddModal] = useState(false);
  const [isOpenEditModal, setOpenEditModal] = useState(false);
  const [mobiles, setMobile] = useState("");
  const [boardRoomMemberId, setBoardRoomMemberId] = useState(0);

  const {
    register,
    handleSubmit,
    watch,
    reset,
    setValue,
    formState: { errors },
  } = useForm<managerBoardMembersModel>({
    resolver: CreateBoardMemberSchema(formatMessage),
    mode: "onBlur",
    reValidateMode: "onBlur",
  });

  const { name, email, designation, nic } = watch();

  const { data: boardRooms, refetch } = useGetBoardMember({
    client,
    id: id as string,
  });

  const { mutate: handleUpdateBoardMember } = useUpdateBoardMember({
    onSuccess: () => {
      toast.success("Board member updated successfully");
      setOpenEditModal(false);
      refetch();
      reset();
    },
    onError: () => {
      toast.error("Failed to update board member");
      refetch();
      reset();
    },
  });

  const { mutate: handleAddBoardMember } = useAddBoardMember({
    onSuccess: () => {
      toast.success("Board member added successfully");
      setOpenAddModal(false);
      refetch();
      reset();
    },
    onError: () => {
      toast.error("Failed to add board member");
      refetch();
      reset();
    },
  });

  const onDropHandleFunction = async (acceptedFiles: any) => {
    localStorage.removeItem("thumbnail1");
    try {
      const acceptedFile: File = acceptedFiles[0];
      setFile(acceptedFile);
      const reader = new FileReader();
      reader.readAsDataURL(acceptedFile);
      reader.addEventListener("load", () => {
        setThisFile(reader.result);
      });
    } catch (error: unknown) {
      throw Error;
    }
  };

  const onSubmit = () => {
    if (file) {
      const boardRoomData = new FormData();
      boardRoomData.append("logo", file as any);
      boardRoomData.append("name", name);
      boardRoomData.append("designation", designation);
      boardRoomData.append("email", email);
      // boardRoomData.append("country_code", country_code);
      boardRoomData.append("mobile", `+${mobiles}`);
      boardRoomData.append("nic", nic);
      // boardRoomData.append("mobile", mobiles);
      handleAddBoardMember({
        client,
        body: boardRoomData,
        id: id as string,
      });
      console.log("submitted", boardRoomData);
    } else {
      console.log("No filessss");
    }
    localStorage.removeItem("thumbnail1");
  };

  const { mutate: deleteBoardMember } = useDeleteBoardMemberById({
    onSuccess: () => {
      toast.success("Board member deleted successfully");
      navigate(AppRoute.boardRoomDManagement.replace(":id", id as string), {
        state: TabTypes.BoardMembers,
      });
      refetch();
      reset();
    },
    onError: () => {
      toast.error("Fail to delete board member");
      refetch();
      reset();
    },
  });

  const handleDelete = async (id: number) => {
    await deleteBoardMember({ client, id });
  };

  const openEditBoardRoomMemberModal = async (id: number) => {
    setBoardRoomMemberId(id);
    setOpenEditModal(true);
    const selectedBoardRoomMembers = boardRooms?.data.find(
      (item) => item.id === id
    );
    setValue("name", `${selectedBoardRoomMembers?.name}`);
    setValue("email", `${selectedBoardRoomMembers?.email}`);
    setValue("designation", `${selectedBoardRoomMembers?.designation}`);
    setMobile(`${selectedBoardRoomMembers?.mobile}`);
    setValue("nic", `${selectedBoardRoomMembers?.nic}`);
    setThisFile(`${selectedBoardRoomMembers?.image}`);
    const blob = await fetch(`${selectedBoardRoomMembers?.image}`).then((r) =>
      r.blob()
    );
    const boardMemberImage = new File(
      [blob],
      `${selectedBoardRoomMembers?.name}`
    );
    setFile(boardMemberImage);
  };

  const onUpdateBoardRoomMember = async () => {
    const updateBoardMemberData = new FormData();
    updateBoardMemberData.append("name", name);
    updateBoardMemberData.append("email", email);
    updateBoardMemberData.append("logo", file as any);
    updateBoardMemberData.append("designation", designation);
    updateBoardMemberData.append("mobile", mobiles);
    updateBoardMemberData.append("nic", nic);

    await handleUpdateBoardMember({
      client,
      body: updateBoardMemberData,
      id: `${boardRoomMemberId}`,
    });
  };

  return (
    <>
      <BoardRoomMembers
        register={register}
        errors={errors}
        onDropHandleFunc={onDropHandleFunction}
        onSubmit={handleSubmit(onSubmit)}
        thumbnail={thisFile}
        handleDelete={handleDelete}
        id={id}
        setOpenAddModal={setOpenAddModal}
        isOpenAddModal={isOpenAddModal}
        mobile={mobiles}
        setMobile={setMobile}
        boardRooms={boardRooms}
        isOpenEditModal={isOpenEditModal}
        setOpenEditModal={setOpenEditModal}
        openEditBoardRoomMemberModal={openEditBoardRoomMemberModal}
        boardRoomMemberId={boardRoomMemberId}
        setBoardRoomMemberId={setBoardRoomMemberId}
        onUpdateBoardRoomMember={onUpdateBoardRoomMember}
      />
      <ToastContainer />
    </>
  );
};
