import { joiResolver } from "@hookform/resolvers/joi";
import Joi from "joi";
import { IntlFormatters } from "react-intl";
import { FormSchema } from "./Tickets.types";

const schema = ({ document_name, description }: FormSchema) =>
  Joi.object<FormSchema>({
    document_name: Joi.string().required().messages(document_name),
    description: Joi.string().required().messages(description),
  });

export const CreateRegistrationDeskSchema = (
  formatMessage: IntlFormatters["formatMessage"]
) =>
  joiResolver(
    schema({
      document_name: {
        "string.empty": formatMessage({ id: "global.errors.required" }),
        "any.required": formatMessage({ id: "global.errors.required" }),
      },
      description: {
        "string.empty": formatMessage({ id: "global.errors.required" }),
        "any.required": formatMessage({ id: "global.errors.required" }),
      },
    })
  );

export const CreateShareHolderResolutionSchema = (
  formatMessage: IntlFormatters["formatMessage"]
) =>
  joiResolver(
    schema({
      document_name: {
        "string.empty": formatMessage({ id: "global.errors.required" }),
        "any.required": formatMessage({ id: "global.errors.required" }),
      },
      description: {
        "string.empty": formatMessage({ id: "global.errors.required" }),
        "any.required": formatMessage({ id: "global.errors.required" }),
      },
    })
  );
