import { useQuery } from "react-query";
import {
  CreateBoardMember,
  AddBoardMemberSuccessResponse,
  GetAllBoardMemberProps,
  DeleteMemberByIdProps,
  deleteMemberByIdResponse,
  UpdateBoardMember,
  UpdateBoardMemberSuccessResponse,
} from "./BoardMember.types";
import { UseMutationOptions, useMutation } from "react-query";

const addBoardMember = ({ client, body, id }: CreateBoardMember) =>
  client
    .post<AddBoardMemberSuccessResponse>(`/api/auth/boardmember/${id}`, body, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then(({ data }) => data);

export const useAddBoardMember = (
  options?:
    | UseMutationOptions<
        AddBoardMemberSuccessResponse,
        unknown,
        Pick<CreateBoardMember, "body">,
        unknown
      >
    | undefined
) => useMutation(addBoardMember, options);

const getBoardMember = ({ client, id }: GetAllBoardMemberProps) =>
  client
    .get<AddBoardMemberSuccessResponse>(`/api/auth/boardmember/${id}`)
    .then(({ data }) => data);

export const useGetBoardMember = ({ client, id }: GetAllBoardMemberProps) =>
  useQuery(["getBoardMember"], () => getBoardMember({ client, id }));

const deleteBoardMemberById = ({ client, id }: DeleteMemberByIdProps) =>
  client
    .delete<deleteMemberByIdResponse>(`/api/auth/boardmember/${id}`)
    .then(({ data }) => data);

export const useDeleteBoardMemberById = (
  options: UseMutationOptions<unknown, unknown, unknown, unknown>
) =>
  useMutation(
    ({ client, id }: DeleteMemberByIdProps) =>
      deleteBoardMemberById({ client, id }),
    options
  );

const updateBoardMember = ({ client, body, id }: UpdateBoardMember) =>
  client
    .post<UpdateBoardMemberSuccessResponse>(
      `/api/auth/boardmemberupdate/${id}`,
      body,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    )
    .then(({ data }) => data);

export const useUpdateBoardMember = (
  options?:
    | UseMutationOptions<
        UpdateBoardMemberSuccessResponse,
        unknown,
        Pick<UpdateBoardMember, "body">,
        unknown
      >
    | undefined
) => useMutation(updateBoardMember, options);
