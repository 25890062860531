import { joiResolver } from "@hookform/resolvers/joi";
import Joi from "joi";
import { IntlFormatters } from "react-intl";
import { FormSchema } from "./RegistrationDesk.types";

const schema = ({ name, description, files, index, resolution_number }: FormSchema) =>
  Joi.object<FormSchema>({
    name: Joi.string().required().messages(name),
    index: Joi.string().optional().messages(index),
    description: Joi.string().required().messages(description),
    files: Joi.object().unknown(true),
    resolution_number: Joi.number().required().messages(resolution_number),
  });

export const CreateRegistrationDeskSchema = (
  formatMessage: IntlFormatters["formatMessage"]
) =>
  joiResolver(
    schema({
      name: {
        "string.empty": formatMessage({ id: "global.errors.required" }),
        "any.required": formatMessage({ id: "global.errors.required" }),
      },
      description: {
        "string.empty": formatMessage({ id: "global.errors.required" }),
        "any.required": formatMessage({ id: "global.errors.required" }),
      },
      files: {
        "string.empty": formatMessage({ id: "global.errors.required" }),
        "any.required": formatMessage({ id: "global.errors.required" }),
      },
      index: {
        "string.empty": formatMessage({ id: "global.errors.required" }),
        "any.required": formatMessage({ id: "global.errors.required" }),
      },
      resolution_number: {
        "string.empty": formatMessage({ id: "global.errors.required" }),
        "any.required": formatMessage({ id: "global.errors.required" }),
      },
    })
  );

export const CreateShareHolderResolutionSchema = (
  formatMessage: IntlFormatters["formatMessage"]
) =>
  joiResolver(
    schema({
      name: {
        "string.empty": formatMessage({ id: "global.errors.required" }),
        "any.required": formatMessage({ id: "global.errors.required" }),
      },
      description: {
        "string.empty": formatMessage({ id: "global.errors.required" }),
        "any.required": formatMessage({ id: "global.errors.required" }),
      },
      files: {
        "string.empty": formatMessage({ id: "global.errors.required" }),
        "any.required": formatMessage({ id: "global.errors.required" }),
      },
      index: {
        "string.empty": formatMessage({ id: "global.errors.required" }),
        "any.required": formatMessage({ id: "global.errors.required" }),
      },
      resolution_number: {
        "string.empty": formatMessage({ id: "global.errors.required" }),
        "any.required": formatMessage({ id: "global.errors.required" }),
      },
    })
  );
