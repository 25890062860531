import { joiResolver } from "@hookform/resolvers/joi";
import Joi from "joi";
import { IntlFormatters } from "react-intl";

import { FormSchema } from "./Correspondents.types";

const schema = ({ name, files, description }: FormSchema) =>
  Joi.object<FormSchema>({
    name: Joi.string().required().messages(name),
    files: Joi.object().unknown(true),
    description: Joi.string().required().messages(description),
  });

export const CreateBoardMemberSchema = (
  formatMessage: IntlFormatters["formatMessage"]
) =>
  joiResolver(
    schema({
      name: {
        "string.empty": formatMessage({ id: "global.errors.required" }),
        "any.required": formatMessage({ id: "global.errors.required" }),
      },
      description: {
        "string.empty": formatMessage({ id: "global.errors.required" }),
        "any.required": formatMessage({ id: "global.errors.required" }),
      },
      files: {
        "string.empty": formatMessage({ id: "global.errors.required" }),
        "any.required": formatMessage({ id: "global.errors.required" }),
      },
    })
  );
