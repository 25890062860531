import { Document } from "./Document";
import { useClient } from "../../hooks/useClient/useClient";
import { useState, useEffect } from "react";
import {
  useAddDocument,
  useDeleteDocumentById,
  useGetDocuments,
} from "../../api/document/Document";
import { toast, ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { AppRoute } from "../../routing/AppRoute.enum";
import { CreateDocumentInputs } from "./Document.types";
import { CreateDocumentSchema } from "./Document.utils";
import { useForm } from "react-hook-form";
import { useLocale } from "../../hooks/useLocale/useLocale";

export const DocumentContainer = () => {
  const client = useClient();
  const [id, setId] = useState("1");
  const navigate = useNavigate();
  const { formatMessage } = useLocale();
  const [isOpenAddModal, setOpenAddModal] = useState(false);

  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm<CreateDocumentInputs>({
    resolver: CreateDocumentSchema(formatMessage),
    mode: "onBlur",
    reValidateMode: "onBlur",
  });

  useEffect(() => {
    const adminId = localStorage.getItem("boardRoomUserId");
    console.log("adminId", adminId);
  }, []);

  const { name, description } = watch();

  const { data: documents, refetch } = useGetDocuments({
    client,
    id,
  });
  console.log("documents", documents);

  const { mutate: deleteDocument } = useDeleteDocumentById({
    onSuccess: () => {
      toast.success("Boardroom deleted successfully");
      reset();
    },
    onError: () => {
      toast.error("Failed to delete boardroom");
    },
  });

  const handleDelete = async (id: number) => {
    await deleteDocument({ client, id });
  };

  const { mutate: handleAddBoardRoom } = useAddDocument({
    onSuccess: () => {
      toast.success("Document created successfully");
      navigate(AppRoute.document);
      reset();
    },
    onError: () => {
      toast.error("Failed to create document");
    },
  });

  const onSubmit = () => {
    const Data = {
      name: name,
      description: description,
    };
    handleAddBoardRoom({
      client,
      body: Data,
    });
  };
  return (
    <>
      <Document
        register={register}
        errors={errors}
        onSubmit={handleSubmit(onSubmit)}
        documents={documents}
        handleDelete={handleDelete}
        setOpenAddModal={setOpenAddModal}
        isOpenAddModal={isOpenAddModal}
        refetch={refetch}
      />
      <ToastContainer autoClose={3000} />
    </>
  );
};
