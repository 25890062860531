import { UseMutationOptions, useMutation, useQuery } from "react-query";

import {
  GetAppUserManagement,
  GetAllUserProps,
  AddDocumentSuccessResponse,
  CreateDocument,
  deleteDocumentByIdResponse,
  DeleteDocumentIdProps,
} from "./Document.types";

const getDocuments = ({ client, id }: GetAllUserProps) =>
  client
    .get<GetAppUserManagement>("/api/auth/documentgenerator/" + id)
    .then(({ data }) => data);

export const useGetDocuments = ({ client, id }: GetAllUserProps) =>
  useQuery(["getDocuments"], () => getDocuments({ client, id }));

const addDocument = ({ client, body }: CreateDocument) =>
  client
    .post<AddDocumentSuccessResponse>(`api/auth/documentgenerator`, body, {})
    .then(({ data }) => data);

export const useAddDocument = (
  options?:
    | UseMutationOptions<
        AddDocumentSuccessResponse,
        unknown,
        Pick<CreateDocument, "body">,
        unknown
      >
    | undefined
) => useMutation(addDocument, options);

const deleteDocumentById = ({ client, id }: DeleteDocumentIdProps) =>
  client
    .delete<deleteDocumentByIdResponse>(`/api/auth/documentgenerator/${id}`)
    .then(({ data }) => data);

export const useDeleteDocumentById = (
  options: UseMutationOptions<unknown, unknown, unknown, unknown>
) =>
  useMutation(
    ({ client, id }: DeleteDocumentIdProps) =>
      deleteDocumentById({ client, id }),
    options
  );
