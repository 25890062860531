import { UseMutationOptions, useMutation, useQuery } from "react-query";

import {
  GetDocumentListSuccessResponse,
  GetRequestCCopyByIDProps,
  GetRequestCCopySuccessResponse,
  GetRequestCopyManagement,
  GetRequestCopyProps,
  deleteRequestCCopyProps,
  deleteRequestCCopySucessResponse,
  editRequestCCopyProps,
  editRequestCCopySucessResponse,
  getDocumentListByBoardRoomIdProps,
  updateBulkStatusProps,
  updateBulkStatusSucessResponse,
  updateTrackingDataProps,
  updateTrackingDataSucessResponse,
  // UpdateAdminStatusProps,
  // UpdateAdminStatusResponseData,
} from "./requestCC.types";
// import { addIsCheckedField } from "./requestCC.utils";
import { UseQueryOptionsType } from "../index.types";

const getRequestCopyData = ({
  client,
  page,
  status,
  search,
}: GetRequestCopyProps) =>
  client
    .get<GetRequestCopyManagement>(
      `/api/auth/requestform?page=${page}?status=${status}&search=${search}`
    )
    .then(({ data }) => data);

export const useGetRequestCopyData = ({
  client,
  page,
  status,
  search,
}: GetRequestCopyProps & {
  options?: UseQueryOptionsType<GetRequestCopyManagement, unknown>;
}) =>
  useQuery(
    ["getRequestCopyData"],
    () => getRequestCopyData({ client, page, status, search }),
    {
      refetchOnWindowFocus: true,
      refetchOnReconnect: true,
      retry: true,
    }
  );

const getRequestCCopyById = ({ client, id }: GetRequestCCopyByIDProps) =>
  client
    .get<GetRequestCCopySuccessResponse>(`/api/auth/requestform/${id}`)
    .then(({ data }) => data);

export const UseGetRequestCCopyById = ({
  client,
  id,
}: GetRequestCCopyByIDProps) =>
  useQuery(["getRequestCCopyById"], () => getRequestCCopyById({ client, id }));

const editRequestCCopy = ({ client, id, body }: editRequestCCopyProps) =>
  client
    .put<editRequestCCopySucessResponse>(
      `/api/auth/updaterequestform/${id}`,
      body,
      {
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
      }
    )
    .then(({ data }) => data);

export const useEditRequestCCopy = (
  options?:
    | UseMutationOptions<
        editRequestCCopySucessResponse,
        unknown,
        Pick<editRequestCCopyProps, "body">,
        unknown
      >
    | undefined
) => useMutation(editRequestCCopy, options);

const updateStatusBulk = ({ client, body }: updateBulkStatusProps) =>
  client
    .put<updateBulkStatusSucessResponse>("/api/auth/requestformbulk", body, {
      headers: { "Content-Type": "application/json " },
    })
    .then(({ data }) => data);

export const useUpdateStatusBulk = (
  options?:
    | UseMutationOptions<
        updateBulkStatusSucessResponse,
        unknown,
        Pick<updateBulkStatusProps, "body">,
        unknown
      >
    | undefined
) => useMutation(updateStatusBulk, options);

const updateTrackingData = ({ client, id, body }: updateTrackingDataProps) =>
  client
    .put<updateTrackingDataSucessResponse>(
      `/api/auth/requestform/${id}`,
      body,
      {
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
      }
    )
    .then(({ data }) => data);

export const useUpdateTrackingData = (
  options?:
    | UseMutationOptions<
        updateTrackingDataSucessResponse,
        unknown,
        Pick<updateTrackingDataProps, "body">,
        unknown
      >
    | undefined
) => useMutation(updateTrackingData, options);

const deleteRequestCCopyById = ({ client, id }: deleteRequestCCopyProps) =>
  client
    .delete<deleteRequestCCopySucessResponse>(`/api/auth/requestform/${id}`)
    .then(({ data }) => data);

export const useDeleteRequestCCopyById = (
  options: UseMutationOptions<unknown, unknown, unknown, unknown>
) =>
  useMutation(
    ({ client, id }: deleteRequestCCopyProps) =>
      deleteRequestCCopyById({ client, id }),
    options
  );

const getDocumentList = ({ client, id }: getDocumentListByBoardRoomIdProps) =>
  client
    .get<GetDocumentListSuccessResponse>(`/api/auth/documentgenerator/${id}`)
    .then(({ data }) => data);

export const useGetDocumentList = ({
  client,
  id,
  options,
}: getDocumentListByBoardRoomIdProps & {
  options?: UseQueryOptionsType<GetDocumentListSuccessResponse, unknown>;
}) =>
  useQuery(["getDocumentList"], () => getDocumentList({ client, id }), {
    ...options,
  });

// const editAdminStatus = ({ client, body, id }: UpdateAdminStatusProps) =>
//   client
//     .put<UpdateAdminStatusResponseData>(`/api/activeUser/${id}`, body)
//     .then(({ data }) => data);

// export const useEditAdminStatus = (
//   options?:
//     | UseMutationOptions<
//         UpdateAdminStatusResponseData,
//         unknown,
//         Pick<UpdateAdminStatusProps, "body">,
//         unknown
//       >
//     | undefined
// ) => useMutation(editAdminStatus, options);
