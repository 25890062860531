import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

import { SIDEBAR_WIDTH, SIDEBAR_WIDTH_EXPANDED } from "../Dashboard.utils";

import { UseStylesProps } from "./Sidebar.types";

const columnStyles = {
  display: "flex",
  flexDirection: "column",
} as const;

const centerStyles = {
  ...columnStyles,
  alignItems: "center",
} as const;

export const useStyles = makeStyles<Theme, UseStylesProps>(
  ({
    breakpoints,
    spacing,
    palette: { gray, primary, doveGray, lightBlue, white, lightGray, darkGray },
    typography: { fontWeightBold, h3 },
  }) => ({
    sidebar: {
      position: "fixed",
      left: 0,
      zIndex: 4,
      background: "#FAF9FC",
      minHeight: "initial",
      transition: "all .3s ease-out",
      // padding: 10,
      [breakpoints.up("lg")]: {
        height: "-webkit-fill-available",
        borderRight: spacing(0.125, "solid", gray.main),
        width: ({ isSidebarExpanded }) =>
          isSidebarExpanded ? SIDEBAR_WIDTH_EXPANDED : SIDEBAR_WIDTH,
      },

      [breakpoints.between("sm", "lg")]: {
        display: ({ isSidebarDisplayed }) =>
          isSidebarDisplayed ? "initial" : "none",
        width: ({ isSidebarExpanded }) =>
          isSidebarExpanded ? SIDEBAR_WIDTH_EXPANDED : SIDEBAR_WIDTH,
        borderRight: spacing(0.125, "solid", gray.main),
      },

      [breakpoints.down("sm")]: {
        width: "100%",
        height: "100%",
        paddingTop: spacing(18.75),
      },
    },
    sidebarContainer: {
      ...columnStyles,

      height: "100%",
      justifyContent: "space-between",

      [breakpoints.down("sm")]: {
        justifyContent: "initial",
      },
      [breakpoints.down("sm")]: {
        marginTop: `${spacing(-10.75)} !important`,
      },
      [breakpoints.down("md")]: {
        marginTop: `${spacing(8.75)} !important`,
      },
    },
    sidebarNavigationContainer: {
      color: doveGray.main,
      [breakpoints.up("lg")]: {
        ...columnStyles,
        // paddingTop: spacing(2),
      },
      [breakpoints.down("sm")]: {
        marginBottom: spacing(19),
      },
    },
    logoSection: {
      padding: 10,
      backgroundColor: "#1D1644",
      display: "flex",
      flexDirection: "row",
      [breakpoints.up("lg")]: {
        ...columnStyles,
        alignItems: "center",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-around",
      },
      [breakpoints.down("lg")]: {
        display: "none",
      },
    },
    header: {
      // fontFamily: "",
      // backgroundColor: "#FFFFFF",
      // marginBottom: spacing(5),
      // fontSize: spacing(10),
      // fontWeight: "bold",
      // alignItems: "center",
      // justifyContent: "center",
      // alignSelf: "center",
      color: "white",
      [breakpoints.only("sm")]: {
        marginBottom: spacing(4),
      },
      [breakpoints.down("lg")]: {
        fontSize: h3.fontSize,
        lineHeight: h3.lineHeight,
      },
    },
    sidebarNavigation: {
      ...centerStyles,
      // overFlow: "scroll !important",

      [breakpoints.only("sm")]: {
        marginTop: spacing(2.75),
      },
      [breakpoints.only("md")]: {
        marginTop: spacing(14.75),
      },

      [breakpoints.down("sm")]: {
        paddingLeft: spacing(3),
        alignItems: "start",
      },
    },
    menuOptionsWrapper: {
      width: "100%",
      [breakpoints.down("sm")]: {
        paddingRight: spacing(13.5),
      },
      padding: 10,
    },
    logoContainer: {
      width: "100%",
      display: "flex",
      height: spacing(8),
      justifyContent: "center",
      paddingLeft: 0,
    },
    logo: {
      width: ({ isSidebarExpanded }) =>
        isSidebarExpanded ? 50 : spacing(9.25),
      height: 50,
      marginBottom: spacing(1),
    },
    divider: {
      height: spacing(0.125),
      background: gray.main,
      width: `calc(100% - ${spacing(2)})`,
      marginBottom: spacing(1),
    },

    expanderContainer: {
      ...centerStyles,
      width: "100%",
      alignItems: "center",
    },
    expander: {
      transform: ({ isSidebarExpanded }) =>
        isSidebarExpanded ? "rotate(180deg)" : "initial",
      marginBottom: spacing(2),
      marginTop: spacing(2),
      cursor: "pointer",

      "& path": {
        fill: gray.dark,
      },
    },
    adminIcon: {
      ...centerStyles,
      justifyContent: "center",
      height: spacing(5),
      width: spacing(5),
      color: primary.main,
      backgroundColor: lightBlue.dark,
      borderRadius: spacing(0.5),
      marginBottom: spacing(3),
    },
    logoutContainer: {
      ...columnStyles,
      color: white.main,
      alignItems: ({ isSidebarExpanded }) =>
        isSidebarExpanded ? "flex-start" : "center",
      paddingBottom: spacing(6),
      justifyContent: "center",
      [breakpoints.down("sm")]: {
        paddingLeft: spacing(3),
        alignItems: "start",
        paddingBottom: spacing(0),
      },
    },
    logoutItem: {
      position: "relative",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      color: `${darkGray.main} !important`,
      textDecoration: "none !important",
      cursor: "pointer",
      padding: ({ isSidebarExpanded }) =>
        isSidebarExpanded ? spacing(0, 2) : spacing(0),

      "&:not(:last-child)": {
        marginBottom: spacing(3),
      },
      "&:hover": {
        textDecoration: "none",
      },
    },
    logoutItemIcon: {
      ...centerStyles,
      justifyContent: "center",
      height: spacing(5),
      width: spacing(5),
      "&:hover": {
        border: ({ isSidebarExpanded }) =>
          !isSidebarExpanded
            ? `${spacing(0.25)} solid ${darkGray.main}`
            : spacing(0),
        borderRadius: "50%",
      },
    },
    logoutItemText: {
      fontWeight: fontWeightBold,
      marginLeft: spacing(2),
    },

    // scrollTest: {
    //   overflowY: "auto",
    //   height: "90vh",
    //   scrollbarWidth: "none",
    //   msOverflowStyle: "none",
    // },
  })
);
