import { toast, ToastContainer } from "react-toastify";
import { useClient } from "../../../hooks/useClient/useClient";
import { useState } from "react";
import { ManageCustomers } from "./ManageCustomer";
import { useActivateAdmin } from "../../../api/customerManagement/customerManagement";
import {
  useAssignBoardRoom,
  useGetBoardRoomsNoPagination,
  useUnAssignBoardRoom,
} from "../../../api/boardRoom/boardRoom";
import LoadingIcon from "../../../assets/icons/loading.gif";
import { boardroom } from "../../../api/customerManagement/customerManagement.types";

export const ManageCustomersContainer = () => {
  const client = useClient();
  const [adminStatus, setCustomerStatus] = useState("0");
  const [id, setId] = useState(0);
  const [boardRoom, setBoardRoom] = useState<string[]>([]);
  const [assignedBoardrooms, setAssignedBoardrooms] = useState<boardroom[]>([]);

  const { data: boardRooms, isLoading } = useGetBoardRoomsNoPagination({
    client,
    page: 0,
    searchValue: "",
  });

  const boardRoomList = (boardRooms?.data || []).map(({ id, name }) => ({
    label: name,
    value: `${id}`,
  }));

  const filteredAssignedBoardRooms = (assignedBoardrooms || []).map(
    ({ boardroom }) => ({
      label: boardroom.name,
      value: `${boardroom.id}`,
    })
  );

  const removedAssignedBoardrooms = boardRoomList.filter(
    (item1) =>
      !filteredAssignedBoardRooms.some((item2) => item1.value === item2.value)
  );

  const { mutate: editAdminStatus } = useActivateAdmin({
    onSuccess: () => {
      toast.success("User activated");
    },
    onError: () => {
      toast.error("Fail to activate user");
    },
  });

  const handleOnSubmit = async () => {
    await editAdminStatus({
      id: id,
      client,
      body: {
        is_active: adminStatus,
      },
    });
  };

  const { mutate: assignBoardRoom } = useAssignBoardRoom({
    onSuccess: () => {
      toast.success("Successfully assigned boardroom");
      setBoardRoom([]);
    },
    onError: () => {
      toast.error("Failed to assign boardroom");
      setBoardRoom([]);
    },
  });

  const handleAssignBoardroom = async () => {
    await assignBoardRoom({
      client,
      body: {
        user_id: id,
        boardroom_id: boardRoom,
      },
    });
  };

  const { mutate: unAssignBoardRoom } = useUnAssignBoardRoom({
    onSuccess: () => {
      toast.success("Successfully unassigned boardroom");
      setBoardRoom([]);
    },
    onError: () => {
      toast.error("Failed to unassign boardroom");
      setBoardRoom([]);
    },
  });

  const handleUnAssignBoardroom = async () => {
    await unAssignBoardRoom({
      client,
      body: {
        user_id: id,
        boardroom_id: boardRoom,
      },
    });
  };

  return (
    <>
      <ManageCustomers
        onSubmit={handleOnSubmit}
        setCustomerStatus={setCustomerStatus}
        setId={setId}
        boardRoomList={boardRoomList}
        handleAssignBoardroom={handleAssignBoardroom}
        handleUnAssignBoardroom={handleUnAssignBoardroom}
        setBoardRoom={setBoardRoom}
        boardRoom={boardRoom}
        setAssignedBoardrooms={setAssignedBoardrooms}
        filteredAssignedBoardRooms={filteredAssignedBoardRooms}
        removedAssignedBoardrooms={removedAssignedBoardrooms}
      />
      <ToastContainer />
      {isLoading && (
        <div
          style={{
            height: "100%",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
            alignItems: "center",
          }}
        >
          <img src={LoadingIcon} alt="my-gif" />
        </div>
      )}
    </>
  );
};
