import { useForm } from "react-hook-form";
import {
  EditRegistrationDeskFormInputs,
  ManageEditBoardResolutionModalContainersProps,
} from "./EditShareHolderResolutionModal.types";
import { EditBoardResolution } from "./EditShareHolderResolutionModal";
// import { useClient } from "../../../../../hooks/useClient/useClient";
import { useEffect, useState } from "react";
import { CreateBoardResolutionSchema } from "../Resolutions.utils";
import { useLocale } from "../../../../../hooks/useLocale/useLocale";
import {
  useEditRegistrationDesk,
  useGetRegistrationDesk,
} from "../../../../../api/boardRoom/registrationDesk/RegistartionDesk";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useClient } from "../../../../../hooks/useClient/useClient";
import { AppRoute } from "../../../../../routing/AppRoute.enum";
import { TabTypes } from "../../BoardRoomTabsEnum";
import {
  useEditBoardResolutions,
  useEditShareholdersResolutions,
} from "../../../../../api/boardRoom/resolutions/Resolutions";

export const EditShareHolderResolutionModalContainer = ({
  getShareHolderResolutionById,
  boardResolutionId,
  onClose,
  resoluRefresh,
  ...props
}: ManageEditBoardResolutionModalContainersProps) => {
  const client = useClient();
  const { formatMessage } = useLocale();
  const navigate = useNavigate();

  const [thisFile, setThisFile] = useState<any>("");
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [file, setFile] = useState<File>();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    reset,
    formState: { errors },
  } = useForm<EditRegistrationDeskFormInputs>({
    resolver: CreateBoardResolutionSchema(formatMessage),
    mode: "onBlur",
    reValidateMode: "onBlur",
  });

  const { name, description, resolution_number } = watch();

  const onDropHandleFunction = async (acceptedFiles: any) => {
    localStorage.removeItem("RegFile");
    try {
      const acceptedFile: File = acceptedFiles[0];
      setFile(acceptedFile);
      const reader = new FileReader();
      reader.readAsDataURL(acceptedFile);
      reader.addEventListener("load", () => {
        setThisFile(reader.result);
        localStorage.setItem("RegFile", reader.result as string);
      });
    } catch (error: unknown) {
      throw Error;
    }
  };

  const docUrlToBase64 = async () => {
    try {
      if (getShareHolderResolutionById?.data?.files) {
        const URL = JSON.parse(
          `${getShareHolderResolutionById?.data.files}`
        )[0];
        const data = await fetch(URL);
        const blob = await data.blob();
        console.log("URL", URL);
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          const file = new File([blob], getShareHolderResolutionById.data.name);
          setFile(file);
          reader.readAsDataURL(blob);
          reader.onloadend = () => {
            const base64data = reader.result;
            resolve(base64data);
            setThisFile(base64data);
          };
          reader.onerror = reject;
        });
      } else {
        // Handle the case where getRegistrationDeskDataById or its properties are undefined
        console.error("Files data is undefined.");
        // Handle or return accordingly
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  useEffect(() => {
    setValue("name", getShareHolderResolutionById?.data.name);
    setValue("description", getShareHolderResolutionById?.data.description);
    docUrlToBase64();
    // @ts-ignore
    setValue("resolution_number", getShareHolderResolutionById?.data.resolution_number);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    getShareHolderResolutionById,
    setValue,
    getShareHolderResolutionById?.data.files,
  ]);

  const { mutate: handleEditBoardShareHolderResolutions } =
    useEditShareholdersResolutions({
      onSuccess: () => {
        setIsLoading(false);
        toast.success("Share holder resolution updated successfully");
        navigate(
          AppRoute.boardRoomDManagement.replace(
            ":id",
            getShareHolderResolutionById?.data.boardroom_id as string
          ),
          {
            state: TabTypes.Resolutions,
          }
        );
        onClose();
        reset();
        resoluRefresh();
        // setInterval(()=>window.location.reload(), 1000)
      },
      onError: () => {
        setIsLoading(false);
        toast.error("Fail to update data");
        navigate(
          AppRoute.boardRoomDManagement.replace(
            ":id",
            getShareHolderResolutionById?.data.boardroom_id as string
          ),
          {
            state: TabTypes.Resolutions,
          }
        );
        reset();
      },
    });

  const handleOnSubmit = () => {
    setButtonDisabled(true);
    setIsLoading(true);
    if (file) {
      const editShareHolderResolutionData = new FormData();
      editShareHolderResolutionData.append("documents[]", file as any);
      editShareHolderResolutionData.append("name", `${name}`);
      editShareHolderResolutionData.append("description", `${description}`);
      editShareHolderResolutionData.append("resolution_number", `${resolution_number}`);

      handleEditBoardShareHolderResolutions({
        client,
        body: editShareHolderResolutionData,
        id: `${getShareHolderResolutionById?.data.id}` as string,
      });
    }
    localStorage.removeItem("RegFile");
  };

  return (
    <EditBoardResolution
      onTrackingModalSubmit={handleSubmit(handleOnSubmit)}
      register={register}
      boardResolutionId={boardResolutionId}
      errors={errors}
      thumbnail={thisFile}
      onDropHandleFunc={onDropHandleFunction}
      getShareHolderResolutionById={getShareHolderResolutionById}
      resoluRefresh={resoluRefresh}
      // getRegistrationDeskDataById={getRegistrationDeskDataById}
      onClose={onClose}
      buttonDisabled={buttonDisabled}
      {...props}
    />
  );
};
