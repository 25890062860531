import { useForm } from "react-hook-form";
import {
  EditCorrespondentFormInputs,
  ManageEditCorrespondentModalContainersProps,
} from "./EditCorrespondentsModal.types";
import { EditCorrespondentModal } from "./EditCorrespondentsModal";
// import { useClient } from "../../../../../hooks/useClient/useClient";
import { useEffect, useState } from "react";
import { CreateBoardMemberSchema } from "../Correspondents.utils";
import { useLocale } from "../../../../../hooks/useLocale/useLocale";
import {
  useEditRegistrationDesk,
  useGetRegistrationDesk,
} from "../../../../../api/boardRoom/registrationDesk/RegistartionDesk";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useClient } from "../../../../../hooks/useClient/useClient";
import { AppRoute } from "../../../../../routing/AppRoute.enum";
import { TabTypes } from "../../BoardRoomTabsEnum";
import { useEditCorrespondent } from "../../../../../api/boardRoom/correspondents/Correspondents";

export const EditCorrespondentModalContainer = ({
  getCorrespondentDataById,
  correspondentId,
  onClose,
  resoluRefresh,
  ...props
}: ManageEditCorrespondentModalContainersProps) => {
  const client = useClient();
  const { formatMessage } = useLocale();
  const navigate = useNavigate();

  const [thisFile, setThisFile] = useState<any>("");
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [file, setFile] = useState<File>();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    reset,
    formState: { errors },
  } = useForm<EditCorrespondentFormInputs>({
    resolver: CreateBoardMemberSchema(formatMessage),
    mode: "onBlur",
    reValidateMode: "onBlur",
  });

  const { name, description } = watch();

  const onDropHandleFunction = async (acceptedFiles: any) => {
    localStorage.removeItem("RegFile");
    try {
      const acceptedFile: File = acceptedFiles[0];
      setFile(acceptedFile);
      const reader = new FileReader();
      reader.readAsDataURL(acceptedFile);
      reader.addEventListener("load", () => {
        setThisFile(reader.result);
        localStorage.setItem("RegFile", reader.result as string);
      });
    } catch (error: unknown) {
      throw Error;
    }
  };

  const docUrlToBase64 = async () => {
    try {
      if (getCorrespondentDataById?.data?.files) {
        const URL = JSON.parse(`${getCorrespondentDataById?.data.files}`)[0];
        const data = await fetch(URL);
        const blob = await data.blob();
        console.log("URL", URL);
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          const file = new File([blob], getCorrespondentDataById.data.name);
          setFile(file);
          reader.readAsDataURL(blob);
          reader.onloadend = () => {
            const base64data = reader.result;
            resolve(base64data);
            setThisFile(base64data);
          };
          reader.onerror = reject;
        });
      } else {
        // Handle the case where getRegistrationDeskDataById or its properties are undefined
        console.error("Files data is undefined.");
        // Handle or return accordingly
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  useEffect(() => {
    setValue("name", getCorrespondentDataById?.data.name);
    setValue("description", getCorrespondentDataById?.data.description);
    docUrlToBase64();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    getCorrespondentDataById,
    setValue,
    getCorrespondentDataById?.data.files,
  ]);

  const { mutate: handleEditCorrespondence } = useEditCorrespondent({
    onSuccess: () => {
      setIsLoading(false);
      toast.success("Board resolution updated successfully");
      navigate(
        AppRoute.boardRoomDManagement.replace(
          ":id",
          getCorrespondentDataById?.data.boardroom_id as string
        ),
        {
          state: TabTypes.Resolutions,
        }
      );
      onClose();
      reset();
      resoluRefresh();
      // setInterval(()=>window.location.reload(), 1000)
    },
    onError: () => {
      setIsLoading(false);
      toast.error("Fail to update data");
      navigate(
        AppRoute.boardRoomDManagement.replace(
          ":id",
          getCorrespondentDataById?.data.boardroom_id as string
        ),
        {
          state: TabTypes.Resolutions,
        }
      );
      reset();
    },
  });

  const handleOnSubmit = () => {
    setButtonDisabled(true);
    setIsLoading(true);
    if (file) {
      const editCorrespondenceData = new FormData();
      editCorrespondenceData.append("documents[]", file as any);
      editCorrespondenceData.append("name", `${name}`);
      editCorrespondenceData.append("description", `${description}`);

      handleEditCorrespondence({
        client,
        body: editCorrespondenceData,
        id: `${getCorrespondentDataById?.data.id}` as string,
      });
    }
    localStorage.removeItem("RegFile");
  };

  return (
    <EditCorrespondentModal
      onTrackingModalSubmit={handleSubmit(handleOnSubmit)}
      register={register}
      correspondentId={correspondentId}
      errors={errors}
      thumbnail={thisFile}
      onDropHandleFunc={onDropHandleFunction}
      getCorrespondentDataById={getCorrespondentDataById}
      resoluRefresh={resoluRefresh}
      // getRegistrationDeskDataById={getRegistrationDeskDataById}
      onClose={onClose}
      buttonDisabled={buttonDisabled}
      {...props}
    />
  );
};
