import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

import { UseStylesProps } from "./MenuOption.types";

const columnStyles = {
  display: "flex",
  flexDirection: "column",
} as const;

const centerStyles = {
  ...columnStyles,
  alignItems: "center",
} as const;

const navigationItemAfter = {
  // position: "absolute",
  content: "''",
  height: "100%",
  top: 0,
  right: 0,
} as const;

export const useStyles = makeStyles<Theme, UseStylesProps>(
  ({
    spacing,
    palette: { gray, jellyBean, white, black, primary, borderGray, darkGray },
    typography: { fontWeightBold },
  }) => ({
    navigationItem: {
      position: "relative",
      display: "flex",
      justifyContent: "left",
      color: `${darkGray.main} !important`,
      cursor: "pointer",
      margin: 5,

      // padding: ({ isSidebarExpanded }) =>
      //   isSidebarExpanded ? spacing(0, 0) : 0,

      // "&:not(:last-child)": {
      //   marginBottom: spacing(1),
      // },
    },
    navigationItemInnerWrapper: {
      width: "100%",
      display: "flex",
      justifyContent: ({ isSidebarExpanded }) =>
        isSidebarExpanded ? "flex-start" : "center",
      borderRadius: spacing(0.5),
      alignItems: "center",
      margin: 5,
    },
    navigationText: {
      ...centerStyles,
      fontStyle: "inter",
      color: "#344054",
      justifyContent: "center",
      fontWeight: "600",
      marginLeft: `${spacing(1)} !important`,
      "&:hover": {
        color: black.main,
      },
      // backgroundColor: "red",
      alignItems: "center",
      padding: 8,
    },
    navigationIcon: {
      ...centerStyles,
      justifyContent: "center",
      // height: spacing(5),
      width: spacing(5),
      borderRadius: spacing(0.5),
    },
    navigationActiveItem: {
      color: white.dark,
      backgroundColor: "#E5E4F5",
      borderRadius: 5,
      "& path:first-child": {
        fill: primary.main,
      },
      "& path:nth-child(2)": {
        fill: jellyBean.main,
      },
      "&:hover": {
        "& .navigationIcon": {
          backgroundColor: ({ isSidebarExpanded }) =>
            !isSidebarExpanded && "#E5E4F5",
        },

        "& .navigationItemInnerWrapper": {
          backgroundColor: ({ isSidebarExpanded }) =>
            isSidebarExpanded && "#E5E4F5",
        },

        "&::after": {
          ...navigationItemAfter,
          width: spacing(0.5),
          backgroundColor: borderGray.contrastText,
          display: ({ isSidebarExpanded }) => isSidebarExpanded && "none",
        },
      },
    },
    navigationInactiveItem: {
      "&:hover": {
        "& .navigationIcon": {
          backgroundColor: ({ isSidebarExpanded }) =>
            !isSidebarExpanded && "#F2F0FC",
        },

        "& .navigationItemInnerWrapper": {
          backgroundColor: ({ isSidebarExpanded }) =>
            isSidebarExpanded && "#F2F0FC",
        },

        "&::after": {
          ...navigationItemAfter,
          width: spacing(0.5),
          backgroundColor: borderGray.contrastText,
          display: ({ isSidebarExpanded }) => isSidebarExpanded && "none",
        },
      },
    },
  })
);
