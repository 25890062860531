import {
  Button,
  DialogContent,
  Input,
  Typography,
} from "../../../../../ui/atoms";
import { useStyles } from "./EditCorrespondentsModal.styles";
import { ManageRequestModalProps } from "./EditCorrespondentsModal.types";
import { Modal } from "../../../../../ui/organisms/modal/Modal";
import { DropzoneBox } from "../../../../../ui/atoms/dropZoneBox/DropZoneBox";
import { PDFViewer } from "react-view-pdf";

export const EditCorrespondentModal = ({
  register,
  isOpen,
  onClose,
  onTrackingModalSubmit,
  errors,
  thumbnail,
  onDropHandleFunc,
  buttonDisabled,
  getCorrespondentDataById,
}: // getRegistrationDeskDataById,
ManageRequestModalProps) => {
  const styles = useStyles();
  const accept = {
    "application/pdf": [],
  };
  return (
    <>
      <Modal open={isOpen} onClose={() => onClose()}>
        <form
          noValidate
          onSubmit={onTrackingModalSubmit}
          className={styles.cardForm}
        >
          <DialogContent>
            <div className={styles.head}>
              <div className={styles.rowDiv}>
                <Typography
                  align="center"
                  variant="h4"
                  className={styles.lable}
                >
                  Edit Correspondent
                </Typography>
                <Input
                  required
                  label="Name"
                  aria-label="name"
                  placeholder={"Enter name"}
                  name="name"
                  className={styles.input}
                  error={!!errors?.name}
                  helperText={errors?.name?.message}
                  register={register}
                />
                <Input
                  required
                  label="Description"
                  aria-label="description"
                  placeholder={"Enter Description"}
                  name="description"
                  className={styles.input}
                  error={!!errors?.description}
                  helperText={errors?.description?.message}
                  register={register}
                />
                <div className={styles.rowDiv}>
                  {thumbnail !== null && thumbnail.length !== 0 ? (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "5%",
                        width: "100%",
                      }}
                    >
                      <PDFViewer url={thumbnail} />
                      <div style={{ width: "28%" }}>
                        <DropzoneBox
                          multiple={false}
                          description="Choose document"
                          onDrop={onDropHandleFunc}
                          accept={accept}
                        >
                          + Choose Document
                        </DropzoneBox>
                      </div>
                    </div>
                  ) : (
                    <DropzoneBox
                      multiple={false}
                      description="Choose document"
                      onDrop={onDropHandleFunc}
                      accept={accept}
                    >
                      + Choose Document
                    </DropzoneBox>
                  )}
                </div>
              </div>
            </div>
          </DialogContent>
          <Modal.Footer>
            <div className={styles.buttonsContainer}>
              <Button
                type="button"
                bold
                size="large"
                onClick={() => onClose()}
                variant="outlined"
                className={styles.buttonyes}
              >
                Cancel
              </Button>

              <Button
                disabled={buttonDisabled}
                size="large"
                type="submit"
                variant="outlined"
                color="inherit"
                bold
                className={styles.closeButton}
              >
                Submit
              </Button>
            </div>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};
