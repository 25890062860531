import { toast, ToastContainer } from "react-toastify";
import { useClient } from "../../../../hooks/useClient/useClient";
import { RequestCCSettings } from "./RequestCCSettings";
import {
  useEditReqCCSetting,
  useEditSetting,
  useGetRequestCCSettingData,
  useGetSetting,
} from "../../../../api/settings/settings";
import { updateSettingInputs } from "./RequestCCSettings.types";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { AppRoute } from "../../../../routing/AppRoute.enum";
import { useEffect } from "react";

export const RequestCCSettingsContainer = () => {
  const client = useClient();
  const navigate = useNavigate();

  const { data: reqSettingData } = useGetRequestCCSettingData({
    client,
  });

  console.log("reqSettingData", reqSettingData?.data);

  const { register, handleSubmit, setValue, watch } =
    useForm<updateSettingInputs>({
      // mode: "onBlur",
      // reValidateMode: "onBlur",
    });

  const {
    certified_by_co_amount,
    certified_by_co_title,
    certified_by_roc_amount,
    certified_by_roc_title,
    certified_by_co_id,
    certified_by_roc_id,
  } = watch();

  // console.log("title", title);

  const { mutate: editReqSetting } = useEditReqCCSetting({
    onSuccess: () => {
      toast.success("Setting updated successfully");
      navigate(AppRoute.settings);
    },
    onError: () => {
      toast.error("Failed to update setting");
    },
  });

  useEffect(() => {
    if (reqSettingData?.data) {
      reqSettingData?.data.map((data) => {
        if (data.id === 1) {
          setValue("certified_by_co_id", data.id);
          setValue("certified_by_co_title", data.title);
          setValue("certified_by_co_amount", data.amount);
        } else if (data.id === 2) {
          setValue("certified_by_roc_id", data.id);
          setValue("certified_by_roc_title", data.title);
          setValue("certified_by_roc_amount", data.amount);
        }
      });
    }
  }, [reqSettingData, setValue]);

  // const { mutate: editAdminStatus } = useEditAdminStatus({
  //   onSuccess: () => {
  //     toast.success("Admin status updated successfully");
  //   },
  //   onError: () => {
  //     toast.error("Failed to update admin status");
  //   },
  // });

  const handleOnSubmit = () => {
    const data = {
      data: [
        {
          id: certified_by_co_id,
          title: certified_by_co_title,
          amount: certified_by_co_amount,
        },
        {
          id: certified_by_roc_id,
          title: certified_by_roc_title,
          amount: certified_by_roc_amount,
        },
      ],
    };
    editReqSetting({
      client,
      body: data,
    });
  };

  return (
    <>
      <RequestCCSettings
        onSubmit={handleSubmit(handleOnSubmit)}
        register={register}
        reqCCSettingData={reqSettingData?.data}
      />
      <ToastContainer />
    </>
  );
};
