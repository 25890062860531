import { joiResolver } from "@hookform/resolvers/joi";
import Joi from "joi";
import { IntlFormatters } from "react-intl";

import { FormSchema } from "./BoardMembers.types";

const schema = ({
  name,
  email,
  logo,
  designation,
  // country_code,
  mobile,
  nic,
}: FormSchema) =>
  Joi.object<FormSchema>({
    name: Joi.string().required().messages(name),
    email: Joi.string().required().messages(email),
    logo: Joi.object().unknown(true),
    designation: Joi.string().required().messages(designation),
    // country_code: Joi.string().messages(country_code),
    mobile: Joi.string().messages(mobile),
    nic: Joi.string().required().messages(nic),
  });

export const CreateBoardMemberSchema = (
  formatMessage: IntlFormatters["formatMessage"]
) =>
  joiResolver(
    schema({
      name: {
        "string.empty": formatMessage({ id: "global.errors.required" }),
        "any.required": formatMessage({ id: "global.errors.required" }),
      },
      email: {
        "string.empty": formatMessage({ id: "global.errors.required" }),
        "any.required": formatMessage({ id: "global.errors.required" }),
      },
      logo: {
        "string.empty": formatMessage({ id: "global.errors.required" }),
        "any.required": formatMessage({ id: "global.errors.required" }),
      },
      designation: {
        "string.empty": formatMessage({ id: "global.errors.required" }),
        "any.required": formatMessage({ id: "global.errors.required" }),
      },
      // country_code: {
      //   "string.empty": formatMessage({ id: "global.errors.required" }),
      //   "any.required": formatMessage({ id: "global.errors.required" }),
      // },
      mobile: {
        "string.empty": formatMessage({ id: "global.errors.required" }),
        "any.required": formatMessage({ id: "global.errors.required" }),
      },
      nic: {
        "string.empty": formatMessage({ id: "global.errors.required" }),
        "any.required": formatMessage({ id: "global.errors.required" }),
      },
    })
  );
