import { Route, Routes } from "react-router-dom";
import { AppRoute } from "./AppRoute.enum";
import { ReactNode } from "react";
import { ForgotPassword } from "../app/forgotPassword/ForgotPassword";
import { DashboardLayout } from "../app/layout/Dashboard/Dashboard";
import { CreateUser } from "../app/userManagement/createUser/CreateUser";
import { CreateCustomer } from "../app/customerManagement/createCustomer/CreateCustomer";
import { LoginContainer } from "../app/login/LoginContainer";
import { CreateBoardRoomContainer } from "../app/boardRoom/createBoardRoom/CreateBoardRoomContainer";
import { ManageUsersContainer } from "../app/userManagement/manageUser/ManageUsersContainer";
import { ManageCustomersContainer } from "../app/customerManagement/manageCustomer/ManageCustomersContainer";
import { ManageBoardRoomContainer } from "../app/boardRoom/manageBoardRoom/ManageBoardRoomContainer";
import { BoardRoomDetails } from "../app/boardRoom/boardRoomDetails/BoradRoomDetails";
import { DashboardContainer } from "../app/dashboard/DashboardContainer";
import { DocumentContainer } from "../app/document/DocumentContainer";
import { EditBoardRoomContainer } from "../app/boardRoom/editBoardRoom/EditBoardRoomContainer";
import { ManageRequestCCContainer } from "../app/requestCC/ManageRequestCC/RequestCCContainer";
import { SettingsContainer } from "../app/settings/ManageSettings/SettingsContainer";
import { PushNotificationContainer } from "../app/pushNotification/PushNotificationContainer";
import { Navigate } from "react-router-dom";

interface ProtectedRouteProps {
  children: ReactNode;
}

const ProtectedRoute = ({ children }: ProtectedRouteProps) => {
  const accessTokenExists = !!localStorage.getItem("boardRoomUserId");

  if (!accessTokenExists) {
    return <Navigate to={AppRoute.login} replace />;
  } else return <>{children}</>;
};

const ProtectedRouteLayout = ({ children }: ProtectedRouteProps) => (
  <ProtectedRoute>
    <DashboardLayout>{children}</DashboardLayout>
  </ProtectedRoute>
);

export const AppRoutes = () => {
  return (
    <Routes>
      <Route path={AppRoute.login} element={<LoginContainer />} />
      <Route path={AppRoute.forgotPassword} element={<ForgotPassword />} />
      <Route
        path={AppRoute.dashboard}
        element={
          <ProtectedRouteLayout>
            <DashboardContainer />
          </ProtectedRouteLayout>
        }
      />
      <Route
        path={AppRoute.createBoardRoom}
        element={
          <ProtectedRouteLayout>
            <CreateBoardRoomContainer />
          </ProtectedRouteLayout>
        }
      />
      <Route
        path={AppRoute.manageBoardRoom}
        element={
          <ProtectedRouteLayout>
            <ManageBoardRoomContainer />
          </ProtectedRouteLayout>
        }
      />
      <Route
        path={AppRoute.createUser}
        element={
          <ProtectedRouteLayout>
            <CreateUser />
          </ProtectedRouteLayout>
        }
      />
      <Route
        path={AppRoute.manageUser}
        element={
          <ProtectedRouteLayout>
            <ManageUsersContainer />
          </ProtectedRouteLayout>
        }
      />
      <Route
        path={AppRoute.createCustomers}
        element={
          <ProtectedRouteLayout>
            <CreateCustomer />
          </ProtectedRouteLayout>
        }
      />
      <Route
        path={AppRoute.manageCustomers}
        element={
          <ProtectedRouteLayout>
            <ManageCustomersContainer />
          </ProtectedRouteLayout>
        }
      />
      <Route
        path={AppRoute.boardRoomDManagement}
        element={
          <ProtectedRouteLayout>
            <BoardRoomDetails />
          </ProtectedRouteLayout>
        }
      />
      <Route
        path={AppRoute.document}
        element={
          <ProtectedRouteLayout>
            <DocumentContainer />
          </ProtectedRouteLayout>
        }
      />
      <Route
        path={AppRoute.editBoardRoom}
        element={
          <ProtectedRouteLayout>
            <EditBoardRoomContainer />
          </ProtectedRouteLayout>
        }
      />
      <Route
        path={AppRoute.reuestCC}
        element={
          <ProtectedRouteLayout>
            <ManageRequestCCContainer />
          </ProtectedRouteLayout>
        }
      />
      <Route
        path={AppRoute.settings}
        element={
          <ProtectedRouteLayout>
            <SettingsContainer />
          </ProtectedRouteLayout>
        }
      />
      <Route
        path={AppRoute.pushNotification}
        element={
          <ProtectedRouteLayout>
            <PushNotificationContainer />
          </ProtectedRouteLayout>
        }
      />
      <Route path="*" element={<LoginContainer />} />
    </Routes>
  );
};
