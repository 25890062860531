import { useMemo, useState } from "react";
import { Column } from "react-table";
import { useStyles } from "./RequestCC.styles";
import { Table } from "../../../ui/organisms/table/Table";
import {
  Button,
  DialogContent,
  Heading,
  Input,
  Typography,
} from "../../../ui/atoms";
import { Modal } from "../../../ui/organisms/modal/Modal";
import { ManageRequestCCopyProps, requestCopyAllData } from "./RequestCC.types";
import {
  Box,
  Chip,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";

import ShareLocationIcon from "@mui/icons-material/ShareLocation";
import SaveAsIcon from "@mui/icons-material/SaveAs";
import DeleteIcon from "@mui/icons-material/Delete";
import PhoneInput from "react-phone-input-2";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { ManageRequestModalContainer } from "../ManageRequestModal/ManageRequestModalContainer";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { colors } from "../../../constants/colors";
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

export const RequestCC = ({
  onSubmit,
  showEditModal,
  register,
  requestCopy,
  mobile,
  status,
  setMobileNo,
  setStatus,
  handleIndividualChecked,
  handleOnSubmits,
  setSelectedStatuss,
  selectedStatus,
  OpenTrackingURLModal,
  setOpenTrackingURLModal,
  requestCCopyId,
  page,
  setPage,
  nonSelectionRowModalOpen,
  setNonSelectionRowModalOpen,
  nonSelectionStatusModalOpen,
  setNonSelectionStatusModalOpen,
  refetch,
  handleStatusChangeAPICall,
  deleteRequestCCopyById,
  openDeleteModal,
  setOpenDeleteModal,
  filterByStatus,
  setFilterByStatus,
  filterByTrackingId,
  setFilterByTrackingId,
  checkIsValidURL,
  setWrongURLModalOpen,
  wrongURLModalOpen,
  boardRoomId,
  setBoardRoomId,
  handleReset,
  documentList,
  documentName,
  handleDocumentSelectChange,
  removeSelectedDocument,
}: ManageRequestCCopyProps) => {
  const styles = useStyles();
  const [isOpenEditModal, setIsOpenEditModal] = useState(false);
  // const [isOpenTrackingURLModal, setIsOpenTrackingURLModal] = useState(false);
  const [reqId, setReqId] = useState(0);

  const columns: Column<requestCopyAllData>[] = useMemo(
    () => [
      {
        id: "check",
        Header: <input type="checkbox" style={{ marginLeft: "12px" }} />,
        accessor: "id",
        Cell: ({ value }) => {
          return (
            <div className={styles.truncatedText}>
              <input
                type="checkbox"
                value={value}
                key={value}
                onClick={(event) => {
                  handleIndividualChecked(event);
                  setReqId(value);
                }}
              />
            </div>
          );
        },
      },
      {
        id: "req_id",
        Header: "Req ID",
        accessor: "id",
        Cell: ({ value }) => (
          <div className={styles.truncatedText}>{value}</div>
        ),
      },
      {
        id: "boardroom",
        Header: "BoardRoom",
        accessor: "boardroom",
        Cell: ({ value }) => (
          <div className={styles.truncatedText}>
            {!!value ? value.name : "-"}
          </div>
        ),
      },
      {
        id: "document_name",
        Header: "Req CC",
        accessor: "document_name",
        Cell: ({
          row: {
            original: { document_name, certified_copy_type },
          },
        }) => (
          <Tooltip
            title={
              <>
                {JSON.parse(JSON.parse(`${document_name}`))
                  .map((obj: { name: any }) => obj.name)
                  .map((item: any) => (
                    <p key={item}>{item}</p>
                  ))}
                <p>{certified_copy_type}</p> {/* No curly braces here */}
              </>
            }
          >
            <div className={styles.truncatedText}>
              {
                JSON.parse(JSON.parse(`${document_name}`)).map(
                  (obj: { name: any }) => obj.name
                )[0]
              }
            </div>
          </Tooltip>
        ),
      },
      {
        id: "name",
        Header: "Req Name",
        accessor: "name",
        Cell: ({ value }) => (
          <div className={styles.truncatedText}>
            {value !== null ? value : "-"}
          </div>
        ),
      },
      {
        id: "delivery_address",
        Header: "Address",
        accessor: "delivery_address",
        Cell: ({ value }) => (
          <div className={styles.truncatedText}>{value}</div>
        ),
      },
      {
        id: "mobile",
        Header: "Contact #",
        accessor: "mobile",
        Cell: ({ value }) => (
          <div className={styles.truncatedText}>{value}</div>
        ),
      },
      {
        id: "district",
        Header: "District",
        accessor: "district",
        Cell: ({ value }) => (
          <div className={styles.truncatedText}>
            {value !== "null" ? value : "-"}
          </div>
        ),
      },
      {
        id: "status",
        Header: "Status",
        accessor: "status",
        Cell: ({ value }) => (
          <div className={styles.truncatedText}>
            {value === "submitted" ? (
              <Button style={{ background: "#ff9800", color: "#ffffff" }}>
                {value}
              </Button>
            ) : value === "dispatched" ? (
              <Button style={{ background: "#2196f3", color: "#ffffff" }}>
                {value}
              </Button>
            ) : value === "delivered" ? (
              <Button style={{ background: "#4caf50", color: "#ffffff" }}>
                {value}
              </Button>
            ) : value === "cancelled" ? (
              <Button style={{ background: "#f44336", color: "#ffffff" }}>
                {value}
              </Button>
            ) : (
              "-"
            )}
          </div>
        ),
      },
      {
        id: "id",
        Header: "Action",
        accessor: "id",
        Cell: ({
          row: {
            original: { id, delivery_address_link, boardroom_id },
          },
        }) => (
          <div className={styles.truncatedText}>
            <div className={styles.actionButtons}>
              <Tooltip title="Delivery Link">
                <div onClick={() => checkIsValidURL(delivery_address_link)}>
                  <ShareLocationIcon />
                </div>
              </Tooltip>
              &nbsp;
              <Tooltip title="Edit">
                <div
                  onClick={() => {
                    showEditModal(id);
                    setIsOpenEditModal(true);
                    setReqId(id);
                    setBoardRoomId(boardroom_id);
                  }}
                >
                  <SaveAsIcon />
                </div>
              </Tooltip>
              &nbsp;
              <Tooltip title="Delete">
                <div
                  onClick={() => {
                    setOpenDeleteModal(true);
                    setReqId(id);
                  }}
                >
                  <DeleteIcon />
                </div>
              </Tooltip>
              &nbsp;
            </div>
          </div>
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [requestCopy]
  );

  return (
    <div className={styles.root}>
      <Heading>
        <h1 style={{ marginBottom: 0 }}>Request Certified Copy</h1>
      </Heading>

      <div className={styles.mainDiv}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: 15,
            width: "100%",
            // backgroundColor: "green",
          }}
        >
          <Button
            type="button"
            size="large"
            variant="outlined"
            bold
            style={{
              color: colors.primary,
              paddingLeft: 30,
              paddingRight: 30,
            }}
            onClick={() => handleOnSubmits()}
          >
            Change Status
          </Button>

          <div>
            <div
              style={{
                position: "absolute",
                top: 90,
              }}
            >
              <InputLabel style={{ color: "black" }}>Select Status</InputLabel>
            </div>
            <Select
              style={{ width: "250px" }}
              placeholder="Select Status"
              value={selectedStatus}
              onChange={(event) => setSelectedStatuss(event.target.value)}
            >
              <MenuItem key={1} value="submitted">
                <ListItemText primary="Submitted" />
              </MenuItem>
              <MenuItem key={2} value="dispatched">
                <ListItemText primary="Dispatched" />
              </MenuItem>
              <MenuItem key={3} value="delivered">
                <ListItemText primary="Delivered" />
              </MenuItem>
              <MenuItem key={4} value="cancelled">
                <ListItemText primary="Cancelled" />
              </MenuItem>
            </Select>
          </div>

          <div>
            <TextField
              placeholder="Search By Name, Mobile, Email"
              name="search"
              className={styles.input}
              onChange={(event) => {
                setFilterByTrackingId(event.target.value);
              }}
              value={filterByTrackingId}
              InputProps={{
                style: { borderColor: "red", borderWidth: 2 },
              }}
            />
          </div>

          <div>
            <div
              style={{
                position: "absolute",
                top: 90,
              }}
            >
              <InputLabel style={{ color: "black" }}>
                Filter By Status
              </InputLabel>
            </div>
            <Select
              style={{ width: "250px" }}
              label="Age"
              onChange={(event: any) => {
                setFilterByStatus(event.target.value);
              }}
              value={filterByStatus}
            >
              <MenuItem key={1} value={"submitted"}>
                <ListItemText primary="Submitted" />
              </MenuItem>
              <MenuItem key={2} value={"dispatched"}>
                <ListItemText primary="Dispatched" />
              </MenuItem>
              <MenuItem key={3} value={"delivered"}>
                <ListItemText primary="Delivered" />
              </MenuItem>
              <MenuItem key={4} value={"cancelled"}>
                <ListItemText primary="Cancelled" />
              </MenuItem>
            </Select>
          </div>
          <Button
            style={{
              backgroundColor: colors.primary,
              color: "white",
              paddingLeft: 30,
              paddingRight: 30,
            }}
            onClick={() => handleReset()}
          >
            Reset
          </Button>
        </div>
        {requestCopy?.data && requestCopy?.data?.length > 0 ? (
          <Table
            columns={columns}
            data={requestCopy?.data || []}
            className={styles.table}
          />
        ) : (
          <Typography align="center">No Data Available</Typography>
        )}
        {page > 0 && (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <Button
              type="button"
              bold
              size="large"
              variant="text"
              style={{ color: "#fff !important" }}
            >
              Page {page}
            </Button>
          </div>
        )}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            width: "100%",
          }}
        >
          {page > 1 && (
            <Button
              type="button"
              bold
              size="large"
              onClick={async () => {
                await setPage(page - 1);
                // await setSearchValue("");
                refetch();
              }}
              variant="text"
              className={styles.paginationButton}
            >
              <KeyboardArrowLeftIcon color="success" />
              Previous
            </Button>
          )}

          {requestCopy?.last_page && page < requestCopy.last_page && (
            <Button
              type="button"
              bold
              size="large"
              onClick={async () => {
                await setPage(page + 1);
                // await setSearchValue("");
                refetch();
              }}
              variant="text"
              className={styles.paginationButton}
            >
              Next
              <KeyboardArrowRightIcon color="success" />
            </Button>
          )}
        </div>
      </div>

      {isOpenEditModal && (
        <div className={styles.mainDiv}>
          <Modal
            open={isOpenEditModal}
            onClose={() => setIsOpenEditModal(false)}
            // style={{width: "1000px"}}
          >
            <DialogContent>
              <div className={styles.head}>
                <Typography
                  align="center"
                  variant="h5"
                  className={styles.lable}
                >
                  Req ID # {`${reqId}`} Edit Details.
                </Typography>
              </div>
              {/* <form noValidate onSubmit={onSubmit}> */}
              <div className={styles.editFieldsMain}>
                <div className={styles.rowFields}>
                  <div>
                    <InputLabel>Change Req CC</InputLabel>
                  </div>
                  <div>
                    <Select
                      style={{ width: "300px" }}
                      label="Age"
                      className={styles.statusFilterSelect}
                      value={documentName}
                      onChange={(event) => handleDocumentSelectChange(event)}
                      name="documentName"
                      multiple
                      renderValue={() => (
                        <Box
                          sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}
                        >
                          {documentName.map((value) => (
                            <>
                              <Chip
                                key={value}
                                label={value}
                                onDelete={() => console.log("first")}
                              />
                            </>
                          ))}
                        </Box>
                      )}
                      MenuProps={{
                        PaperProps: {
                          style: {
                            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                            width: 250,
                          },
                        },
                      }}
                    >
                      {documentList?.data.map((item) => (
                        <MenuItem key={item.id} value={item.name}>
                          <ListItemText primary={item.name} />
                        </MenuItem>
                      ))}
                    </Select>
                  </div>
                </div>

                <div className={styles.rowFields}>
                  <div>
                    <InputLabel>Receiver Name</InputLabel>
                  </div>
                  <div>
                    <Input
                      name="name"
                      placeholder="Receiver Name"
                      style={{ marginLeft: "5px", width: "305px" }}
                      register={register}
                      // defaultValue={}
                    />
                  </div>
                </div>

                <div className={styles.rowFields}>
                  <div>
                    <InputLabel>Mobile Number</InputLabel>
                  </div>
                  <div style={{ marginTop: "10px" }}>
                    <PhoneInput
                      country={"lk"}
                      value={mobile}
                      onChange={(phone) => setMobileNo(phone)}
                      enableSearch={true}
                      inputStyle={{ height: "50px", width: "310px" }}
                      countryCodeEditable={false}
                    />
                  </div>
                </div>
                <div className={styles.rowFields}>
                  <div>
                    <InputLabel>Address</InputLabel>
                  </div>
                  <div>
                    <Input
                      style={{ margin: "10px 10px 10px 52px", width: "310px" }}
                      name="delivery_address"
                      register={register}
                      // register={}
                      // defaultValue={}
                    />
                  </div>
                </div>
                <div className={styles.rowFields}>
                  <div>
                    <InputLabel>Address Link</InputLabel>
                  </div>
                  <div>
                    <Input
                      style={{ margin: "0px 10px 10px 18px", width: "310px" }}
                      name="delivery_address_link"
                      register={register}
                      // register={}
                      // defaultValue={}
                    />
                  </div>
                </div>
                <div className={styles.rowFields}>
                  <div>
                    <InputLabel>Email</InputLabel>
                  </div>
                  <div>
                    <Input
                      style={{ margin: "0px 10px 10px 70px", width: "310px" }}
                      name="email"
                      register={register}
                      // register={}
                      // defaultValue={}
                    />
                  </div>
                </div>
              </div>
              <div className={styles.buttonsContainer}>
                <Button
                  type="button"
                  bold
                  size="large"
                  onClick={() => setIsOpenEditModal(false)}
                  variant="contained"
                  className={styles.closeButton}
                >
                  Cancel
                </Button>

                <Button
                  size="large"
                  type="submit"
                  variant="contained"
                  bold
                  className={styles.buttonyes}
                  onClick={() => onSubmit()}
                >
                  Update
                </Button>
              </div>
              {/* </form> */}
            </DialogContent>
          </Modal>
        </div>
      )}

      {nonSelectionRowModalOpen && (
        <div className={styles.mainDiv}>
          <Modal
            open={nonSelectionRowModalOpen}
            onClose={() => setNonSelectionRowModalOpen(false)}
            // style={{width: "1000px"}}
          >
            <DialogContent>
              <div className={styles.head}>
                <Typography
                  align="center"
                  variant="h5"
                  className={styles.lable}
                >
                  Please Select Atleast one row !
                </Typography>
              </div>

              <div className={styles.buttonsContainer}>
                <Button
                  type="button"
                  bold
                  size="large"
                  onClick={() => setNonSelectionRowModalOpen(false)}
                  variant="contained"
                  className={styles.closeButton}
                >
                  OK
                </Button>
              </div>
            </DialogContent>
          </Modal>
        </div>
      )}

      {nonSelectionStatusModalOpen && (
        <div className={styles.mainDiv}>
          <Modal
            open={nonSelectionStatusModalOpen}
            onClose={() => setNonSelectionStatusModalOpen(false)}
            // style={{width: "1000px"}}
          >
            <DialogContent>
              <div className={styles.head}>
                <Typography
                  align="center"
                  variant="h5"
                  className={styles.lable}
                >
                  Please Select Status !
                </Typography>
              </div>

              <div className={styles.buttonsContainer}>
                <Button
                  type="button"
                  bold
                  size="large"
                  onClick={() => setNonSelectionStatusModalOpen(false)}
                  variant="contained"
                  className={styles.closeButton}
                >
                  OK
                </Button>
              </div>
            </DialogContent>
          </Modal>
        </div>
      )}
      {openDeleteModal && (
        <div className={styles.mainDiv}>
          <Modal
            open={openDeleteModal}
            onClose={() => setOpenDeleteModal(false)}
            // style={{width: "1000px"}}
          >
            <DialogContent>
              <div className={styles.head}>
                <Typography
                  align="center"
                  variant="h5"
                  className={styles.lable}
                >
                  Are You Sure You Want TO Delete This Request Certifield Copy !
                </Typography>
              </div>

              <div className={styles.buttonsContainer}>
                <Button
                  type="button"
                  bold
                  size="large"
                  onClick={() => {
                    deleteRequestCCopyById(String(reqId));
                    refetch();
                  }}
                  variant="contained"
                  className={styles.closeButton}
                >
                  Yes
                </Button>
                <Button
                  type="button"
                  bold
                  size="large"
                  onClick={() => setOpenDeleteModal(false)}
                  variant="contained"
                  style={{ background: "red", color: "white" }}
                  // className={styles.closeButton}
                >
                  No
                </Button>
              </div>
            </DialogContent>
          </Modal>
        </div>
      )}
      {wrongURLModalOpen && (
        <div className={styles.mainDiv}>
          <Modal
            open={wrongURLModalOpen}
            onClose={() => setWrongURLModalOpen(false)}
            // style={{width: "1000px"}}
          >
            <DialogContent>
              <div className={styles.head}>
                <Typography
                  align="center"
                  variant="h5"
                  className={styles.lable}
                >
                  Delivery address link was not valid
                </Typography>
              </div>

              <div className={styles.buttonsContainer}>
                <Button
                  type="button"
                  bold
                  size="large"
                  onClick={() => setWrongURLModalOpen(false)}
                  variant="contained"
                  className={styles.closeButton}
                >
                  OK
                </Button>
              </div>
            </DialogContent>
          </Modal>
        </div>
      )}
      {OpenTrackingURLModal && (
        <ManageRequestModalContainer
          isOpen={OpenTrackingURLModal}
          onClose={() => setOpenTrackingURLModal(false)}
          requestCCopyId={String(reqId)}
          handleStatusChangeAPICall={handleStatusChangeAPICall}
        />
      )}
    </div>
  );
};
