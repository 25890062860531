import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

import { StylesProps } from "./Select.types";

export const useStyles = makeStyles<Theme, StylesProps>(
  ({ palette, spacing }) => ({
    wrapper: {
      display: "flex",
      width: "100%",
      flexDirection: "column",
      "& .react-select__menu": {
        zIndex: 3,
      },
      "& .react-select__placeholder": {
        color: palette.gray.light,
      },
      "& .react-select__control": {
        minHeight: spacing(6),
        borderWidth: "2px !important",
        borderRadius: `${spacing(0.75)} !important`,
        borderColor: ({ error, success }) =>
          `${
            error
              ? palette.error.main
              : success
              ? palette.success.main
              : palette.gray.main
          }`,

        "&:hover": {
          borderColor: ({ error, success }) =>
            `${
              error
                ? palette.error.main
                : success
                ? palette.success.main
                : palette.gray.main
            }`,
        },

        "&--is-focused": {
          borderColor: ({ error, success }) =>
            `${
              error
                ? palette.error.main
                : success
                ? palette.success.main
                : palette.focus.main
            } !important`,
          boxShadow: "none !important",
        },
      },
      "& .react-select__value-container": {
        padding: `${spacing(1.25, 1.75)} !important`,
        lineHeight: spacing(3),
        minHeight: spacing(5.5),
        fontSize: spacing(2.25),
        "&--is-multi": {
          "&.react-select__value-container--has-value": {
            paddingBottom: `${spacing(0.875)} !important`,
          },
        },
        "& > div": {
          padding: spacing(0),
          margin: spacing(0),
        },
        "& .react-select__multi-value": {
          color: palette.primary.contrastText,
          backgroundColor: palette.primary.main,
          margin: spacing(0, 0.375, 0.375, 0),
          "&__label": {
            color: `${palette.primary.contrastText} !important`,
          },
        },
      },
      "& .react-select__indicator-separator": {
        display: "none",
      },
      "& .react-select__dropdown-indicator > svg": {
        transition: "all .3s ease-out",
        color: palette.primary.main,
        width: spacing(2.25),
        height: spacing(2.25),
        marginRight: spacing(2),
      },
      "& .react-select__control--menu-is-open .react-select__dropdown-indicator > svg":
        {
          transform: "rotate(180deg)",
        },
      "& .react-select__option": {
        fontSize: `${spacing(2)} !important`,
        lineHeight: spacing(3),
        padding: `${spacing(1, 2.25)} !important`,
        "&--is-focused": {
          color: `${palette.primary.contrastText} !important`,
          backgroundColor: `${palette.primary.main} !important`,
          minWidth: "100%",
        },
        "&--is-selected": {
          color: palette.primary.contrastText,
          backgroundColor: `${palette.primary.main} !important`,
          minWidth: "100%",
        },
      },
      "& .react-select__option--is-selected": {
        pointerEvents: "none",
      },
    },
    label: {
      display: "block",
      fontSize: spacing(2),
      lineHeight: spacing(3),
      marginBottom: spacing(1),
      color: `${palette.primary.contrastText} !important`,
    },
  })
);
