import { Input, Button, Typography } from "../../../../ui/atoms";
import { useStyles } from "./Resolution.styles";
import { DropzoneBox } from "../../../../ui/atoms/dropZoneBox/DropZoneBox";
import { ResolutionProps } from "./Resolution.types";
import { Column } from "react-table";
import { useMemo } from "react";
import { useState } from "react";
import { Table } from "../../../../ui/organisms/table/Table";
import { Modal } from "../../../../ui/organisms/modal/Modal";
import { DialogContent, Tooltip } from "@mui/material";
import { SuccessData } from "../../../../api/boardRoom/resolutions/Resolutions.types";
import { PDFViewer } from "react-view-pdf";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { EditBoardResolutionModalContainer } from "./editBoardResolutionModal/EditBoardResolutionModalContainer";
import EditIcon from "@mui/icons-material/Edit";
import { EditShareHolderResolutionModalContainer } from "./editShareHolderResolutionModal/EditShareHolderResolutionModalContainer";

export const Resolution = ({
  id,
  handleDeleteBoardResolution,
  handleDeleteShareHolderResolution,
  register,
  onBoardSubmit,
  errors,
  onDropBoardHandleFunction,
  resolutions,
  setOpenAddBusinessModal,
  isOpenAddBusinessModal,
  resoluRefresh,
  onDropShareHandleFunction,
  onShareSubmit,
  shareholders,
  setOpenAddShareModal,
  isOpenAddShareModal,
  refetch,
  shareErrors,
  shareRegister,
  thumbnail,
  isOpenEditBoardResolutionModal,
  setIsOpenEditBoardResolutionModal,
  isOpenEditShareHolderResolutionModal,
  setIsOpenEditShareHolderResolutionModal,
  setShareHolderResolutionId,
  shareHolderResolutionId,
  boardResolutionId,
  setBoardResolutionId,
  getBoardResolutionById,
  getShareHolderResolutionById,
  disableBoardResolutionSubmitButton,
  disableShreHolderSubmitButton,
}: ResolutionProps) => {
  const styles = useStyles();
  const [isOpenBusinessModal, setOpenBusinessModal] = useState(false);
  const [isOpenShareModal, setOpenShareModal] = useState(false);
  const [isOpenPDFModal, setOpenPDFModal] = useState(false);
  const [file, setFile] = useState("");
  const [userId, setUserId] = useState(0);
  const [userId1, setUserId1] = useState(0);
  const [Id, setId] = useState("");
  const [shareHolderResolutionID, setShareHolderResolutionID] = useState("");
  const accept = {
    "application/pdf": [],
    // "image/jpeg": [".jpg", ".jpeg"],
    // "image/png": [".png"],
  };

  const openInNewTab = (url: string) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  const columns: Column<SuccessData>[] = useMemo(
    () => [
      {
        id: "name",
        Header: "Name",
        accessor: "name",
        Cell: ({ value }) => (
          <div className={styles.truncatedText}>{value}</div>
        ),
      },
      {
        id: "description",
        Header: "Description",
        accessor: "description",
        Cell: ({ value }) => (
          <div className={styles.truncatedText}>{value}</div>
        ),
      },
      {
        id: "id",
        Header: "Actions",
        accessor: "id",
        Cell: ({
          row: {
            original: { files, id },
          },
        }) => (
          <div className={styles.truncatedText}>
            <div className={styles.actionButtons}>
              <Button
                className={styles.buttonView}
                variant="text"
                onClick={() => {
                  const data = JSON.parse(files as unknown as string);
                  // setOpenPDFModal(true);
                  setFile(data[0]);
                  openInNewTab(data[0]);
                }}
              >
                <Tooltip title="View Document">
                  <PictureAsPdfIcon />
                </Tooltip>
              </Button>
              <Button
                className={styles.buttonView}
                variant="text"
                onClick={async () => {
                  setShareHolderResolutionId(`${id}`);
                  setShareHolderResolutionID(`${id}`);
                  await setIsOpenEditShareHolderResolutionModal(true);
                }}
              >
                <Tooltip title="Edit">
                  <EditIcon />
                </Tooltip>
              </Button>
              <Button
                className={styles.buttonView2}
                variant="text"
                onClick={async () => {
                  await setUserId1(id);
                  await setOpenShareModal(true);
                }}
              >
                <Tooltip title="Delete">
                  <DeleteOutlineIcon />
                </Tooltip>
              </Button>
            </div>
          </div>
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [resolutions?.data]
  );

  const columns2: Column<SuccessData>[] = useMemo(
    () => [
      {
        id: "name",
        Header: "Name",
        accessor: "name",
        Cell: ({ value }) => (
          <div className={styles.truncatedText}>{value}</div>
        ),
      },
      {
        id: "description",
        Header: "Description",
        accessor: "description",
        Cell: ({ value }) => (
          <div className={styles.truncatedText}>{value}</div>
        ),
      },
      {
        id: "id",
        Header: "Actions",
        accessor: "id",
        Cell: ({
          row: {
            original: { files, id },
          },
        }) => (
          <div className={styles.truncatedText}>
            <div className={styles.actionButtons}>
              <Button
                className={styles.buttonView}
                variant="text"
                onClick={() => {
                  const data = JSON.parse(files as unknown as string);
                  // setOpenPDFModal(true);
                  setFile(data[0]);
                  openInNewTab(data[0]);
                }}
              >
                <Tooltip title="View Document">
                  <PictureAsPdfIcon />
                </Tooltip>
              </Button>
              <Button
                className={styles.buttonView}
                variant="text"
                onClick={async () => {
                  setId(`${id}`);
                  setBoardResolutionId(() => `${id}`);
                  await setIsOpenEditBoardResolutionModal(true);
                }}
              >
                <Tooltip title="Edit">
                  <EditIcon />
                </Tooltip>
              </Button>
              <Button
                className={styles.buttonView2}
                variant="text"
                onClick={async () => {
                  await setUserId(id);
                  await setOpenBusinessModal(true);
                }}
              >
                <Tooltip title="Delete">
                  <DeleteOutlineIcon />
                </Tooltip>
              </Button>
            </div>
          </div>
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [shareholders?.data]
  );
  return (
    <div className={styles.root}>
      <>
        <div className={styles.mainDiv}>
          <Typography align="center" variant="h5" className={styles.lable}>
            Board Resolution
          </Typography>
          <div className={styles.buttonDiv}>
            <Button
              type="button"
              size="large"
              variant="outlined"
              bold
              className={styles.submit}
              onClick={() => setOpenAddBusinessModal(true)}
            >
              Add Document
            </Button>
          </div>
          {resolutions?.data && resolutions?.data?.length > 0 ? (
            <Table
              columns={columns2}
              data={resolutions?.data || []}
              className={styles.table}
            // onRowClick={(row) => {
            //   const data = JSON.parse(row.files as unknown as string);
            //   setOpenPDFModal(true);
            //   setFile(data[0]);
            //   // console.log("row", row);
            // }}
            />
          ) : (
            <></>
          )}
          {isOpenAddBusinessModal && (
            <Modal
              open={isOpenAddBusinessModal}
              onClose={() => setOpenAddBusinessModal(false)}
            >
              <form
                noValidate
                onSubmit={onBoardSubmit}
                className={styles.cardForm}
              >
                <DialogContent>
                  <div className={styles.head}>
                    <div className={styles.rowDiv}>
                      <Typography
                        align="center"
                        variant="h4"
                        className={styles.lable}
                      >
                        Add Board Resolution
                      </Typography>
                      <Input
                        required
                        label="Resolution Date"
                        aria-label="name"
                        placeholder={"Enter Resolution Date"}
                        name="name"
                        className={styles.input}
                        error={!!errors?.name}
                        helperText={errors?.name?.message}
                        register={register}
                      />
                      <Input
                        required
                        label="Description"
                        aria-label="description"
                        placeholder={"Enter Description"}
                        name="description"
                        className={styles.input}
                        error={!!errors?.description}
                        helperText={errors?.description?.message}
                        register={register}
                      />
                      <Input
                        required
                        label="Resolution Number"
                        aria-label="resolution_number"
                        placeholder={"Enter Resolution Number"}
                        name="resolution_number"
                        className={styles.input}
                        error={!!errors?.resolution_number}
                        helperText={errors?.resolution_number?.message}
                        register={register}
                      />
                      <div className={styles.rowDiv}>
                        {thumbnail !== null && thumbnail.length !== 0 ? (
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              gap: "5%",
                              width: "100%",
                            }}
                          >
                            <PDFViewer url={thumbnail} />
                            <div style={{ width: "28%" }}>
                              <DropzoneBox
                                multiple={false}
                                description="Choose document"
                                onDrop={onDropBoardHandleFunction}
                                accept={accept}
                              >
                                + Choose Document
                              </DropzoneBox>
                            </div>
                          </div>
                        ) : (
                          <DropzoneBox
                            multiple={false}
                            description="Choose document"
                            onDrop={onDropBoardHandleFunction}
                            accept={accept}
                          >
                            + Choose Document
                          </DropzoneBox>
                        )}
                      </div>
                    </div>
                  </div>
                </DialogContent>
                <Modal.Footer>
                  <div className={styles.buttonsContainer}>
                    <Button
                      type="button"
                      bold
                      size="large"
                      onClick={() => setOpenAddBusinessModal(false)}
                      variant="outlined"
                      className={styles.buttonyes}
                    >
                      Cancel
                    </Button>

                    <Button
                      disabled={disableBoardResolutionSubmitButton}
                      size="large"
                      type="submit"
                      variant="outlined"
                      color="inherit"
                      bold
                      className={styles.closeButton}
                    >
                      Submit
                    </Button>
                  </div>
                </Modal.Footer>
              </form>
            </Modal>
          )}
          {isOpenBusinessModal && (
            <Modal
              open={isOpenBusinessModal}
              onClose={() => setOpenBusinessModal(false)}
            >
              <DialogContent>
                <div className={styles.head}>
                  <Typography
                    align="center"
                    variant="h5"
                    className={styles.lable}
                  >
                    Are you sure you want to delete this board resolution?
                  </Typography>
                </div>
              </DialogContent>
              <Modal.Footer>
                <div className={styles.buttonsContainer}>
                  <Button
                    type="button"
                    bold
                    size="large"
                    onClick={() => setOpenBusinessModal(false)}
                    variant="outlined"
                    className={styles.closeButton}
                  >
                    No
                  </Button>

                  <Button
                    size="large"
                    type="button"
                    variant="outlined"
                    color="inherit"
                    bold
                    className={styles.buttonyes}
                    onClick={async () => {
                      await handleDeleteBoardResolution(userId);
                      await refetch();
                      setOpenBusinessModal(false);
                    }}
                  >
                    Yes
                  </Button>
                </div>
              </Modal.Footer>
            </Modal>
          )}
          {isOpenPDFModal && (
            <Modal
              open={isOpenPDFModal}
              onClose={() => setOpenPDFModal(false)}
              style={{ width: "100%", height: "90%" }}
            >
              <DialogContent>
                <div className={styles.head}>
                  {file?.includes("pdf") ? (
                    <PDFViewer url={file} />
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        alignSelf: "center",
                        justifyContent: "center",
                        width: "100%",
                      }}
                    >
                      <img src={file} width="500" height="500" alt="img" />
                    </div>
                  )}
                </div>
              </DialogContent>
              <Modal.Footer>
                <div className={styles.buttonsContainer}>
                  <Button
                    type="button"
                    bold
                    size="large"
                    onClick={() => setOpenPDFModal(false)}
                    variant="outlined"
                    className={styles.closeButton}
                  >
                    Ok
                  </Button>
                </div>
              </Modal.Footer>
            </Modal>
          )}
        </div>
        <div
          style={{ height: "100%", width: 5, backgroundColor: "black" }}
        ></div>
        <div className={styles.mainDiv2}>
          <Typography align="center" variant="h5" className={styles.lable}>
            Shareholders Resolution
          </Typography>

          <div className={styles.buttonDiv}>
            <Button
              type="button"
              size="large"
              variant="outlined"
              bold
              className={styles.submit}
              onClick={() => setOpenAddShareModal(true)}
            >
              Add Document
            </Button>
          </div>
          {shareholders?.data && shareholders?.data.length > 0 ? (
            <Table
              columns={columns}
              data={shareholders?.data || []}
              className={styles.table}
            />
          ) : (
            <></>
          )}
          {isOpenAddShareModal && (
            <Modal
              open={isOpenAddShareModal}
              onClose={() => setOpenAddShareModal(false)}
            >
              <form
                noValidate
                onSubmit={onShareSubmit}
                className={styles.cardForm}
              >
                <DialogContent>
                  <div className={styles.head}>
                    <div className={styles.rowDiv}>
                      <Typography
                        align="center"
                        variant="h4"
                        className={styles.lable}
                      >
                        Add Shareholder Resolution
                      </Typography>
                      <Input
                        required
                        label="Resolution Date"
                        aria-label="Resolution Date"
                        placeholder={"Enter Resolution Date"}
                        name="name"
                        className={styles.input}
                        error={!!shareErrors?.name}
                        helperText={shareErrors?.name?.message}
                        register={shareRegister}
                      />
                      <Input
                        required
                        label="Description"
                        aria-label="description"
                        placeholder={"Enter Description"}
                        name="description"
                        className={styles.input}
                        error={!!shareErrors?.description}
                        helperText={shareErrors?.description?.message}
                        register={shareRegister}
                      />
                      <Input
                        required
                        label="Resolution Number"
                        aria-label="resolution_number"
                        placeholder={"Enter Resolution Number"}
                        name="resolution_number"
                        className={styles.input}
                        error={!!shareErrors?.resolution_number}
                        helperText={shareErrors?.resolution_number?.message}
                        register={shareRegister}
                      />
                      <div className={styles.rowDiv}>
                        {thumbnail !== null && thumbnail.length !== 0 ? (
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              gap: "5%",
                              width: "100%",
                            }}
                          >
                            <PDFViewer url={thumbnail} />
                            <div style={{ width: "28%" }}>
                              <DropzoneBox
                                multiple={false}
                                description="Choose document"
                                onDrop={onDropShareHandleFunction}
                                accept={accept}
                              >
                                + Choose Document
                              </DropzoneBox>
                            </div>
                          </div>
                        ) : (
                          <DropzoneBox
                            multiple={false}
                            description="Choose document"
                            onDrop={onDropShareHandleFunction}
                            accept={accept}
                          >
                            + Choose Document
                          </DropzoneBox>
                        )}
                      </div>
                    </div>
                  </div>
                </DialogContent>
                <Modal.Footer>
                  <div className={styles.buttonsContainer}>
                    <Button
                      type="button"
                      bold
                      size="large"
                      onClick={() => setOpenAddShareModal(false)}
                      variant="outlined"
                      className={styles.buttonyes}
                    >
                      Cancel
                    </Button>

                    <Button
                      disabled={disableShreHolderSubmitButton}
                      size="large"
                      type="submit"
                      variant="outlined"
                      color="inherit"
                      bold
                      className={styles.closeButton}
                    >
                      Submit
                    </Button>
                  </div>
                </Modal.Footer>
              </form>
            </Modal>
          )}
          {isOpenShareModal && (
            <Modal
              open={isOpenShareModal}
              onClose={() => setOpenShareModal(false)}
            >
              <DialogContent>
                <div className={styles.head}>
                  <Typography
                    align="center"
                    variant="h5"
                    className={styles.lable}
                  >
                    Are you sure you want to delete this shareholder resolution?
                  </Typography>
                </div>
              </DialogContent>
              <Modal.Footer>
                <div className={styles.buttonsContainer}>
                  <Button
                    type="button"
                    bold
                    size="large"
                    onClick={() => setOpenShareModal(false)}
                    variant="outlined"
                    className={styles.closeButton}
                  >
                    No
                  </Button>

                  <Button
                    size="large"
                    type="button"
                    variant="outlined"
                    color="inherit"
                    bold
                    className={styles.buttonyes}
                    onClick={async () => {
                      await handleDeleteShareHolderResolution(userId1);
                      await resoluRefresh();
                      setOpenShareModal(false);
                    }}
                  >
                    Yes
                  </Button>
                </div>
              </Modal.Footer>
            </Modal>
          )}
        </div>
        {isOpenEditBoardResolutionModal && (
          <EditBoardResolutionModalContainer
            isOpen={isOpenEditBoardResolutionModal}
            onClose={() => setIsOpenEditBoardResolutionModal(false)}
            boardResolutionId={Id}
            getBoardResolutionById={getBoardResolutionById}
            resoluRefresh={resoluRefresh}
          />
        )}
        {isOpenEditShareHolderResolutionModal && (
          <EditShareHolderResolutionModalContainer
            isOpen={isOpenEditShareHolderResolutionModal}
            onClose={() => setIsOpenEditShareHolderResolutionModal(false)}
            boardResolutionId={shareHolderResolutionID}
            getShareHolderResolutionById={getShareHolderResolutionById}
            resoluRefresh={refetch}
          />
        )}
      </>
    </div>
  );
};
