import { useQuery } from "react-query";
import { UseMutationOptions, useMutation } from "react-query";
import {
  CreateResolution,
  AddResolutionSuccessResponse,
  GetAllResolutionProps,
  DeleteMemberByIdProps,
  deleteMemberByIdResponse,
  GetResolutionByIdProps,
  GetResolutionByIdSuccessResponse,
  GetShareHolderResolutionByIdProps,
  GetShareHolderResolutionByIdSuccessResponse,
} from "./Resolutions.types";
import { UseQueryOptionsType } from "../../index.types";

const addResolutions = ({ client, body, id }: CreateResolution) =>
  client
    .post<AddResolutionSuccessResponse>(`api/auth/resolutions/${id}`, body, {
      headers: { "Content-Type": "multipart/form-data" },
    })
    .then(({ data }) => data);

export const useAddResolutions = (
  options?:
    | UseMutationOptions<
        AddResolutionSuccessResponse,
        unknown,
        Pick<CreateResolution, "body">,
        unknown
      >
    | undefined
) => useMutation(addResolutions, options);

const getResolutions = ({ client, id }: GetAllResolutionProps) =>
  client
    .get<AddResolutionSuccessResponse>(`api/auth/resolutions/${id}`)
    .then(({ data }) => data);

export const useGetResolutions = ({ client, id }: GetAllResolutionProps) =>
  useQuery(["getResolutions"], () => getResolutions({ client, id }));

//
const addShareholdersResolutions = ({ client, body, id }: CreateResolution) =>
  client
    .post<AddResolutionSuccessResponse>(
      `api/auth/shareholderresolution/${id}`,
      body,
      {
        headers: { "Content-Type": "multipart/form-data" },
      }
    )
    .then(({ data }) => data);

export const useAddShareholdersResolutions = (
  options?:
    | UseMutationOptions<
        AddResolutionSuccessResponse,
        unknown,
        Pick<CreateResolution, "body">,
        unknown
      >
    | undefined
) => useMutation(addShareholdersResolutions, options);

const getShareholdersResolutions = ({ client, id }: GetAllResolutionProps) =>
  client
    .get<AddResolutionSuccessResponse>(`api/auth/shareholderresolution/${id}`)
    .then(({ data }) => data);

export const useGetshareholdersResolutions = ({
  client,
  id,
}: GetAllResolutionProps) =>
  useQuery(["getShareholdersResolutions"], () =>
    getShareholdersResolutions({ client, id })
  );

const deleteResolutionById = ({ client, id }: DeleteMemberByIdProps) =>
  client
    .delete<deleteMemberByIdResponse>(`/api/auth/resolutions/${id}`)
    .then(({ data }) => data);

export const useDeleteResolutionById = (
  options: UseMutationOptions<unknown, unknown, unknown, unknown>
) =>
  useMutation(
    ({ client, id }: DeleteMemberByIdProps) =>
      deleteResolutionById({ client, id }),
    options
  );

const deleteShareholderResolutionById = ({
  client,
  id,
}: DeleteMemberByIdProps) =>
  client
    .delete<deleteMemberByIdResponse>(`/api/auth/shareholderresolution/${id}`)
    .then(({ data }) => data);

export const useDeleteShareHolderResolutionById = (
  options: UseMutationOptions<unknown, unknown, unknown, unknown>
) =>
  useMutation(
    ({ client, id }: DeleteMemberByIdProps) =>
      deleteShareholderResolutionById({ client, id }),
    options
  );

const getResolutionsById = ({ client, id }: GetResolutionByIdProps) =>
  client
    .get<GetResolutionByIdSuccessResponse>(`/api/auth/getresolutions/${id}`)
    .then(({ data }) => data);

export const useGetResolutionsById = ({
  client,
  id,
  options,
}: GetResolutionByIdProps & {
  options?: UseQueryOptionsType<GetResolutionByIdSuccessResponse, unknown>;
}) =>
  useQuery(["getResolutionsById"], () => getResolutionsById({ client, id }), {
    ...options,
  });

const editBoardResolutions = ({ client, body, id }: CreateResolution) =>
  client
    .post<AddResolutionSuccessResponse>(
      `api/auth/updateresolutions/${id}`,
      body,
      {
        headers: { "Content-Type": "multipart/form-data" },
      }
    )
    .then(({ data }) => data);

export const useEditBoardResolutions = (
  options?:
    | UseMutationOptions<
        AddResolutionSuccessResponse,
        unknown,
        Pick<CreateResolution, "body">,
        unknown
      >
    | undefined
) => useMutation(editBoardResolutions, options);

const getShareHolderResolutionsById = ({
  client,
  id,
}: GetShareHolderResolutionByIdProps) =>
  client
    .get<GetShareHolderResolutionByIdSuccessResponse>(
      `/api/auth/getshareholderresolution/${id}`
    )
    .then(({ data }) => data);

export const useGetShareHolderResolutionsById = ({
  client,
  id,
  options,
}: GetShareHolderResolutionByIdProps & {
  options?: UseQueryOptionsType<
    GetShareHolderResolutionByIdSuccessResponse,
    unknown
  >;
}) =>
  useQuery(
    ["getShareHolderResolutionsById"],
    () => getShareHolderResolutionsById({ client, id }),
    {
      ...options,
    }
  );

const editShareholdersResolutions = ({ client, body, id }: CreateResolution) =>
  client
    .post<AddResolutionSuccessResponse>(
      `api/auth/updateshareholderresolution/${id}`,
      body,
      {
        headers: { "Content-Type": "multipart/form-data" },
      }
    )
    .then(({ data }) => data);

export const useEditShareholdersResolutions = (
  options?:
    | UseMutationOptions<
        AddResolutionSuccessResponse,
        unknown,
        Pick<CreateResolution, "body">,
        unknown
      >
    | undefined
) => useMutation(editShareholdersResolutions, options);
