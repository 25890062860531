import { toast, ToastContainer } from "react-toastify";
import { useClient } from "../../../hooks/useClient/useClient";
import { useEffect, useState } from "react";
import { RequestCC } from "./RequestCC";
import {
  useDeleteRequestCCopyById,
  useEditRequestCCopy,
  useGetDocumentList,
  useGetRequestCopyData,
  useUpdateStatusBulk,
} from "../../../api/requestCC/requestCC";
import { editRequestCopy, updateTrackingModalBody } from "./RequestCC.types";
import { useForm } from "react-hook-form";
// import { useLocale } from "../../../hooks/useLocale/useLocale";
// import { CreateBoardroomSchema } from "../RequestCC.utils";

export const ManageRequestCCContainer = () => {
  const client = useClient();
  // const { formatMessage } = useLocale();
  const [mobileNo, setMobileNo] = useState("");
  const [status, setStatus] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [id, setId] = useState("");
  const [openTrackingURLModal, setOpenTrackingURLModal] = useState(false);
  const [nonSelectionRowModalOpen, setNonSelectionRowModalOpen] =
    useState(false);
  const [nonSelectionStatusModalOpen, setNonSelectionStatusModalOpen] =
    useState(false);
  const [selectedId, setSelectedId] = useState<number[]>([]);
  const [page, setPage] = useState(1);
  const [trackingId, setTrackingId] = useState("");
  const [district, setDistrict] = useState("");
  const [filterByStatus, setFilterByStatus] = useState("");
  const [filterByTrackingId, setFilterByTrackingId] = useState("");
  const [boardRoomId, setBoardRoomId] = useState("");
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  // const [trackingURL, setTrackingURL] = useState("");
  const [wrongURLModalOpen, setWrongURLModalOpen] = useState(false);
  const [documentName, setDocumentName] = useState<string[]>([]);
  // const [showDocumentName, setShowDocumentName] = useState("");

  const { register, setValue, watch } = useForm<editRequestCopy>({
    // mode: "onBlur",
    // reValidateMode: "onBlur",
  });

  const {
    register: trackingRegister,
    // formState: { errors },
  } = useForm<updateTrackingModalBody>({
    // resolver: CreateBoardroomSchema(formatMessage),
    // mode: "onBlur",
    // reValidateMode: "onBlur",
  });

  const { data: requestCopyData, refetch } = useGetRequestCopyData({
    client,
    page,
    status: filterByStatus,
    search: filterByTrackingId,
  });

  const { data: documentList } = useGetDocumentList({
    client,
    id: boardRoomId,
    options: {
      enabled: !!boardRoomId,
    },
  });

  // const { data: documentList, isSuccess } = useGetDocumentList({
  //   client,
  //   id: boardRoomId
  // });

  useEffect(() => {
    refetch();
  }, [filterByStatus, filterByTrackingId, refetch]);

  const { delivery_address, delivery_address_link, name, email } = watch();

  const { mutate: handleUpdateCCopy } = useEditRequestCCopy({
    onSuccess: () => {
      toast.success("Request Certifield Copy updated successfully");
      setInterval(() => window.location.reload(), 1000);
    },
    onError: () => {
      toast.error("Failed to update Request Certifield Copy");
    },
  });
  const { mutate: handleUpdateBulkStatus } = useUpdateStatusBulk({
    onSuccess: () => {
      toast.success("Status updated successfully");
      setInterval(() => window.location.reload(), 1000);
    },
    onError: () => {
      toast.error("Failed to update Status");
    },
  });

  const { mutate: handleDeleteCCopy } = useDeleteRequestCCopyById({
    onSuccess: () => {
      toast.success("Request Certifield Copy deleted successfully");
      setInterval(() => window.location.reload(), 3000);
    },
    onError: () => {
      toast.error("Failed to delete Request Certifield Copy");
    },
  });

  const handleIndividualChecked = (event: any) => {
    if (event.target.checked) {
      setSelectedId((prev) => [Number(event.target.value), ...prev]);
    } else {
      setSelectedId((prev) =>
        prev.filter((data) => data !== Number(event.target.value))
      );
    }
  };

  const handleSubmit = async () => {
    if (selectedId.length === 0) {
      setNonSelectionRowModalOpen(true);
    } else if (selectedStatus === "") {
      setNonSelectionStatusModalOpen(true);
    } else {
      if (selectedStatus === "dispatched") {
        setOpenTrackingURLModal(true);
      } else {
        await handleUpdateBulkStatus({
          client,
          body: {
            status: selectedStatus,
            id: selectedId,
          },
        });
      }
    }
  };
  const handleStatusChangeAPICall = async () => {
    await handleUpdateBulkStatus({
      client,
      body: {
        status: selectedStatus,
        id: selectedId,
      },
    });
  };

  const ShowEditModal = async (requestCCId: any) => {
    const selectedRequestCCopyData = requestCopyData?.data.data.find(
      (data) => data.id === requestCCId
    );
    // setValue('status', selectedRequestCCopyData?.status);
    setValue("name", selectedRequestCCopyData?.name);
    setValue("delivery_address", selectedRequestCCopyData?.delivery_address);
    setValue(
      "delivery_address_link",
      selectedRequestCCopyData?.delivery_address_link
    );
    setValue("email", selectedRequestCCopyData?.email);
    setMobileNo(`${selectedRequestCCopyData?.mobile}`);
    setStatus(`${selectedRequestCCopyData?.status}`);
    setId(`${requestCCId}`);
    setTrackingId(`${selectedRequestCCopyData?.tracking_id}`);
    setDistrict(`${selectedRequestCCopyData?.district}`);
    setBoardRoomId(() => `${selectedRequestCCopyData?.boardroom_id}`);
    console.log(
      "selectedRequestCCopyData?.document_name",
      selectedRequestCCopyData?.document_name
    );
    const objectArray = JSON.parse(
      JSON.parse(`${selectedRequestCCopyData?.document_name}`)
    );
    const stringArray = objectArray.map((obj: { name: any }) => obj.name);
    console.log(stringArray);
    setDocumentName(stringArray);
  };

  // console.log("documentList", documentList)

  const handleOnSubmit = () => {
    const documentNameObjectArray = documentName.map((item) => {
      return {
        name: item,
      };
    });
    console.log("documentNameObjectArray", documentNameObjectArray);
    const RequstCCopy = new URLSearchParams();
    RequstCCopy.append("name", `${name}`);
    RequstCCopy.append("mobile", `${mobileNo}`);
    RequstCCopy.append("email", `${email}`);
    RequstCCopy.append("district", `${district}`);
    RequstCCopy.append("delivery_address", `${delivery_address}`);
    RequstCCopy.append("tracking_id", `${trackingId}`);
    RequstCCopy.append("tracking_url", `${delivery_address_link}`);
    RequstCCopy.append("document_name", `${documentName}`);

    handleUpdateCCopy({
      client,
      id: id,
      body: RequstCCopy,
    });
  };

  const onTrackingModalSubmit = async () => {
    console.log("first");
  };

  const deleteRequestCCopyById = (id: string) => {
    handleDeleteCCopy({
      client,
      id: id,
    });
    // await window.location.reload();
  };

  const checkIsValidURL = (trackingURL: string) => {
    // eslint-disable-next-line no-useless-escape, no-control-regex
    const URLPattern = new RegExp(
      "^((ft|htt)ps?:\\/\\/)?" + // protocol
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name and extension
        "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
        "(\\:\\d+)?" + // port
        "(\\/[-a-z\\d%@_.~+&:]*)*" + // path
        "(\\?[;&a-z\\d%@_.,~+&:=-]*)?" + // query string
        "(\\#[-a-z\\d_]*)?$",
      "i"
    ); // fragment locator
    console.log("isURL", URLPattern.test(trackingURL));
    console.log("trackingURL", trackingURL);
    if (URLPattern.test(trackingURL)) {
      window.open(trackingURL);
    } else {
      setWrongURLModalOpen(true);
    }
  };

  const handleReset = () => {
    setFilterByTrackingId("");
    setFilterByStatus("");
  };

  const handleDocumentSelectChange = (event: any) => {
    const {
      target: { value },
    } = event;
    // setDocumentName((prev)=>[value, ...prev])
    console.log("event", value);
    setDocumentName(typeof value === "string" ? value.split(",") : value);
    // setShowDocumentName(value)
  };

  const removeSelectedDocument = (docName: string) => {
    const removeDocument = documentName.filter((doc) => doc !== docName);
    setDocumentName(removeDocument);
  };

  return (
    <>
      <RequestCC
        showEditModal={ShowEditModal}
        onSubmit={handleOnSubmit}
        setMobileNo={setMobileNo}
        setStatus={setStatus}
        mobile={mobileNo}
        status={status}
        requestCopy={requestCopyData?.data}
        register={register}
        handleIndividualChecked={handleIndividualChecked}
        handleOnSubmits={() => handleSubmit()}
        setSelectedStatuss={setSelectedStatus}
        selectedStatus={selectedStatus}
        OpenTrackingURLModal={openTrackingURLModal}
        setOpenTrackingURLModal={setOpenTrackingURLModal}
        onTrackingModalSubmit={onTrackingModalSubmit}
        trackingRegister={trackingRegister}
        requestCCopyId={id}
        page={page}
        setPage={setPage}
        nonSelectionRowModalOpen={nonSelectionRowModalOpen}
        setNonSelectionRowModalOpen={setNonSelectionRowModalOpen}
        setNonSelectionStatusModalOpen={setNonSelectionStatusModalOpen}
        nonSelectionStatusModalOpen={nonSelectionStatusModalOpen}
        refetch={refetch}
        handleStatusChangeAPICall={handleStatusChangeAPICall}
        deleteRequestCCopyById={deleteRequestCCopyById}
        openDeleteModal={openDeleteModal}
        setOpenDeleteModal={setOpenDeleteModal}
        filterByStatus={filterByStatus}
        filterByTrackingId={filterByTrackingId}
        setFilterByStatus={setFilterByStatus}
        setFilterByTrackingId={setFilterByTrackingId}
        checkIsValidURL={checkIsValidURL}
        setWrongURLModalOpen={setWrongURLModalOpen}
        wrongURLModalOpen={wrongURLModalOpen}
        boardRoomId={boardRoomId}
        setBoardRoomId={setBoardRoomId}
        handleReset={handleReset}
        documentList={documentList}
        documentName={documentName}
        setDocumentName={setDocumentName}
        handleDocumentSelectChange={handleDocumentSelectChange}
        removeSelectedDocument={removeSelectedDocument}
        // errors={errors}
      />
      <ToastContainer />
    </>
  );
};
