import { Card, Input, Typography, Button, Heading } from "../../../ui/atoms";
import { useStyles } from "./EditBoardRoom.styles";
import { useNavigate } from "react-router-dom";
import { AppRoute } from "../../../routing/AppRoute.enum";
import { DropzoneBox } from "../../../ui/atoms/dropZoneBox/DropZoneBox";
import { CreateBoardRoomProps } from "./EditBoardRoom.types";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "react-datepicker/dist/react-datepicker-cssmodules.css";
import { InputLabel } from "@mui/material";
import { colors } from "../../../constants/colors";

export const CreateBoardRoom = ({
  register,
  onSubmit,
  errors,
  onDropHandleFunc,
  thumbnail,
  boardRooms,
  refetch,
  dateOfInCoperation,
  setDateOfInCoperation,
}: CreateBoardRoomProps) => {
  const styles = useStyles();
  const navigate = useNavigate();
  const accept = {
    "image/jpeg": [],
    "image/png": [],
  };

  console.log("boardRooms", boardRooms.image);

  return (
    <div className={styles.root}>
      <Heading>
        <h1 style={{ marginBottom: 0 }}>Edit Boardroom</h1>
      </Heading>
      <div className={styles.mainDiv}>
        <div className={styles.buttonDiv}>
          <div className={styles.card}>
            <form noValidate onSubmit={onSubmit} className={styles.cardForm}>
              <div className={styles.rowDiv}>
                <Input
                  required
                  label="Name"
                  aria-label="name"
                  placeholder={"Enter name"}
                  name="name"
                  className={styles.input}
                  error={!!errors?.name}
                  helperText={errors?.name?.message}
                  register={register}
                  defaultValue={boardRooms.name}
                />
                <Input
                  required
                  label="Registration Number"
                  aria-label="description"
                  placeholder={"Enter description"}
                  name="description"
                  className={styles.input}
                  error={!!errors?.description}
                  helperText={errors?.description?.message}
                  register={register}
                  defaultValue={boardRooms.description}
                />
                <Input
                  label="Whatsapp Group Link"
                  aria-label="whatsapp group link"
                  placeholder={"Enter group link"}
                  name="whatsAppGroupLink"
                  className={styles.input}
                  error={!!errors?.whatsAppGroupLink}
                  helperText={errors?.whatsAppGroupLink?.message}
                  register={register}
                  defaultValue={boardRooms?.whatsapp_link}
                />
                <InputLabel style={{ color: "black" }}>
                  Date Of Incorporation
                </InputLabel>
                <DatePicker
                  selected={dateOfInCoperation}
                  onChange={(date) => setDateOfInCoperation(date)}
                  dateFormat="yyyy/MM/dd"
                />
                <Input
                  required
                  label="Address"
                  aria-label="address"
                  placeholder={"Enter address"}
                  name="address"
                  className={styles.input}
                  error={!!errors?.address}
                  helperText={errors?.address?.message}
                  register={register}
                  defaultValue={boardRooms.address}
                />
              </div>
              <div className={styles.rowDiv}>
                {/* {thumbnail !== null && thumbnail.length !== 0 ? ( */}
                <div
                  style={{ display: "flex", flexDirection: "row", gap: "10%" }}
                >
                  <img
                    src={thumbnail ? thumbnail : boardRooms.image}
                    alt="logo"
                    height={100}
                    width={100}
                    style={{ borderRadius: 10 }}
                  />
                  <div style={{ width: "100%" }}>
                    <DropzoneBox
                      multiple={false}
                      description="Change board room logo"
                      onDrop={onDropHandleFunc}
                      accept={accept}
                    >
                      + Choose Photo
                    </DropzoneBox>
                  </div>
                </div>
                {/* ) : ( */}
                {/* <DropzoneBox
                multiple={false}
                description="Choose board room logo"
                onDrop={onDropHandleFunc}
                accept={accept}
              >
                + Choose Photo
              </DropzoneBox> */}
                {/* )} */}
              </div>

              <div className={styles.buttonRow}>
                <Button
                  type="button"
                  size="large"
                  variant="outlined"
                  bold
                  // className={styles.cancel}
                  onClick={() => {
                    navigate(AppRoute.manageBoardRoom, { replace: true });
                  }}
                  style={{
                    color: colors.primary,
                    borderColor: colors.primary,
                  }}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  size="large"
                  variant="outlined"
                  bold
                  // className={styles.submit}
                  style={{
                    backgroundColor: colors.primary,
                    color: "white",
                    marginLeft: 20,
                  }}
                >
                  Submit
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
