import { toast, ToastContainer } from "react-toastify";
import { useClient } from "../../../hooks/useClient/useClient";
import { Settings } from "./Settings";
import { useEditSetting, useGetSetting } from "../../../api/settings/settings";
import { updateSettingInputs } from "./Settings.types";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { AppRoute } from "../../../routing/AppRoute.enum";
import { useEffect } from "react";

export const SettingsContainer = () => {
  const client = useClient();
  const navigate = useNavigate();

  const { data: settingData } = useGetSetting({
    client,
  });

  const { register, handleSubmit, setValue, watch } =
    useForm<updateSettingInputs>({
      // mode: "onBlur",
      // reValidateMode: "onBlur",
    });

  const {
    expressDescription,
    standardDescription,
    expressAmount,
    standardAmount,
    expressId,
    standardId,
    pickupId,
    pickupDescription,
    pickupAmount,
  } = watch();

  const { mutate: editSetting } = useEditSetting({
    onSuccess: () => {
      toast.success("Setting updated successfully");
      navigate(AppRoute.settings);
    },
    onError: () => {
      toast.error("Failed to update setting");
    },
  });

  useEffect(() => {
    if (settingData?.data) {
      settingData?.data.map((data) => {
        if (data.key === "standard_delivery") {
          setValue("standardId", data.id);
          setValue("standardDescription", data.description);
          setValue("standardAmount", data.value);
        } else if (data.key === "express_delivery") {
          setValue("expressId", data.id);
          setValue("expressDescription", data.description);
          setValue("expressAmount", data.value);
        } else if (data.key === "pickup") {
          setValue("pickupId", data.id);
          setValue("pickupDescription", data.description);
          setValue("pickupAmount", data.value);
        }
      });
    }
  }, [settingData, setValue]);

  // const { mutate: editAdminStatus } = useEditAdminStatus({
  //   onSuccess: () => {
  //     toast.success("Admin status updated successfully");
  //   },
  //   onError: () => {
  //     toast.error("Failed to update admin status");
  //   },
  // });

  const handleOnSubmit = () => {
    const data = {
      data: [
        {
          id: standardId,
          description: standardDescription,
          value: standardAmount,
        },
        {
          id: expressId,
          description: expressDescription,
          value: expressAmount,
        },
        {
          id: pickupId,
          description: pickupDescription,
          value: pickupAmount,
        },
      ],
    };
    editSetting({
      client,
      body: data,
    });
  };

  return (
    <>
      <Settings
        onSubmit={handleSubmit(handleOnSubmit)}
        register={register}
        settingData={settingData?.data}
      />
      <ToastContainer />
    </>
  );
};
