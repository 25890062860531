import { useStyles } from "./ModalFooter.styles";
import { ModalFooterProps } from "./ModalFooter.types";

export const ModalFooter = ({ children }: ModalFooterProps) => {
  const classes = useStyles();

  return (
    <div className={classes.actionsWrapper}>
      <div className={classes.actions}>{children}</div>
    </div>
  );
};
