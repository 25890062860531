import { useMediaQuery, useTheme } from "@mui/material";
import clsx from "clsx";
import ReactSelect, { Props } from "react-select";

import { Tabs as MaterialTabs, Tab } from "../../atoms";

import { useStyles } from "./Tabs.styles";
import { TabsProps, TabType } from "./Tabs.types";

const Select = <T extends string | number>(props: Props<TabType<T>>) => (
  <ReactSelect {...props} />
);

export const Tabs = <T extends string | number>({
  tabs,
  activeTab,
  setTab,
}: TabsProps<T>) => {
  const classes = useStyles({ tabsCount: tabs.length });
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <>
      {isMobile && tabs.length > 1 && (
        <Select
          value={tabs.find(({ value }) => value === activeTab)}
          options={tabs}
          onChange={(value) => {
            setTab((value as TabType<T>).value);
          }}
          isMulti={false}
          isSearchable={false}
          isClearable={false}
          hideSelectedOptions
          className={classes.select}
          classNamePrefix="tabs-select"
        />
      )}
      {!isMobile && (
        <div className={classes.tabColor}>
          <MaterialTabs
            TabIndicatorProps={{}}
            classes={{ root: classes.tabsContainer }}
            onChange={(e, value) => setTab(value)}
            // indicatorColor="secondary"
            value={activeTab}
            variant="fullWidth"
            scrollButtons="auto"
          >
            {tabs.map(
              ({ label, value, isHide }) =>
                !isHide && (
                  <Tab
                    key={value}
                    value={value}
                    label={
                      <div
                        className={clsx(
                          classes.tabLink,
                          activeTab === value && classes.selectedTab
                        )}
                      >
                        {label}
                      </div>
                    }
                    classes={{
                      root: classes.tab,
                      selected: classes.selectedTab,
                    }}
                  />
                )
            )}
          </MaterialTabs>
        </div>
      )}
      {tabs.map(
        ({ value, component }) => value === activeTab && <>{component}</>
      )}
    </>
  );
};
