import { useForm } from "react-hook-form";
import {
  EditRegistrationDeskFormInputs,
  ManageRequestModalContainersProps,
} from "./EditRegistrationDeskModal.types";
import { ManageRequestModal } from "./EditRegistrationDeskModal";
// import { useClient } from "../../../../../hooks/useClient/useClient";
import { useEffect, useState } from "react";
import { CreateRegistrationDeskSchema } from "../RegistrationDesk.utils";
import { useLocale } from "../../../../../hooks/useLocale/useLocale";
import {
  useEditRegistrationDesk,
  useGetRegistrationDesk,
} from "../../../../../api/boardRoom/registrationDesk/RegistartionDesk";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useClient } from "../../../../../hooks/useClient/useClient";
import { AppRoute } from "../../../../../routing/AppRoute.enum";
import { TabTypes } from "../../BoardRoomTabsEnum";

export const EditRegistrationDeskModalContainer = ({
  getRegistrationDeskDataById,
  registrationDeskId,
  onClose,
  ...props
}: ManageRequestModalContainersProps) => {
  const client = useClient();
  const { formatMessage } = useLocale();
  const navigate = useNavigate();

  const [thisFile, setThisFile] = useState<any>("");
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [file, setFile] = useState<File>();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isLoading, setIsLoading] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    reset,
    formState: { errors },
  } = useForm<EditRegistrationDeskFormInputs>({
    resolver: CreateRegistrationDeskSchema(formatMessage),
    mode: "onBlur",
    reValidateMode: "onBlur",
  });

  const { name, description, index } = watch();

  const onDropHandleFunction = async (acceptedFiles: any) => {
    localStorage.removeItem("RegFile");
    try {
      const acceptedFile: File = acceptedFiles[0];
      setFile(acceptedFile);
      const reader = new FileReader();
      reader.readAsDataURL(acceptedFile);
      reader.addEventListener("load", () => {
        setThisFile(reader.result);
        localStorage.setItem("RegFile", reader.result as string);
      });
    } catch (error: unknown) {
      throw Error;
    }
  };

  const docUrlToBase64 = async () => {
    try {
      if (getRegistrationDeskDataById?.data?.files) {
        const URL = JSON.parse(getRegistrationDeskDataById.data.files)[0];
        const data = await fetch(URL);
        const blob = await data.blob();
        console.log("URL", URL);
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          const file = new File([blob], getRegistrationDeskDataById.data.name);
          setFile(file);
          reader.readAsDataURL(blob);
          reader.onloadend = () => {
            const base64data = reader.result;
            resolve(base64data);
            setThisFile(base64data);
          };
          reader.onerror = reject;
        });
      } else {
        // Handle the case where getRegistrationDeskDataById or its properties are undefined
        console.error("Files data is undefined.");
        // Handle or return accordingly
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  const { data: registrationDesk, refetch } = useGetRegistrationDesk({
    client,
    id: `${getRegistrationDeskDataById?.data.boardroom_id}`,
  });

  useEffect(() => {
    setValue("name", getRegistrationDeskDataById?.data.name);
    setValue("description", getRegistrationDeskDataById?.data.description);
    setValue("index", `${getRegistrationDeskDataById?.data.sort_order}`);
    docUrlToBase64();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    getRegistrationDeskDataById,
    setValue,
    getRegistrationDeskDataById?.data.files,
  ]);

  const { mutate: handleEditRegistrationDesk } = useEditRegistrationDesk({
    onSuccess: () => {
      setIsLoading(false);
      toast.success("Registration Desk updated successfully");
      navigate(
        AppRoute.boardRoomDManagement.replace(
          ":id",
          getRegistrationDeskDataById?.data.boardroom_id as string
        ),
        {
          state: TabTypes.RegistrationDesk,
        }
      );
      onClose();
      reset();
      setInterval(() => window.location.reload(), 1000);
    },
    onError: () => {
      setIsLoading(false);
      toast.error("Fail to update data");
      navigate(
        AppRoute.boardRoomDManagement.replace(
          ":id",
          getRegistrationDeskDataById?.data.boardroom_id as string
        ),
        {
          state: TabTypes.RegistrationDesk,
        }
      );
      reset();
    },
  });

  const handleOnSubmit = () => {
    setIsLoading(true);
    setButtonDisabled(true);
    if (file) {
      const editRegistrationDeskData = new FormData();
      editRegistrationDeskData.append("documents[]", file as any);
      editRegistrationDeskData.append("name", `${name}`);
      editRegistrationDeskData.append("description", `${description}`);
      editRegistrationDeskData.append("sort_order", `${index}`);

      handleEditRegistrationDesk({
        client,
        body: editRegistrationDeskData,
        id: `${getRegistrationDeskDataById?.data.id}` as string,
      });
    }
    localStorage.removeItem("RegFile");
  };

  return (
    <ManageRequestModal
      buttonDisabled={buttonDisabled}
      setButtonDisabled={setButtonDisabled}
      onTrackingModalSubmit={handleOnSubmit}
      register={register}
      registrationDeskId={registrationDeskId}
      errors={errors}
      thumbnail={thisFile}
      onDropHandleFunc={onDropHandleFunction}
      getRegistrationDeskDataById={getRegistrationDeskDataById}
      onClose={onClose}
      {...props}
    />
  );
};
