import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

import { getValue } from "./input.utils";

export type UseStylesProps = {
  success?: boolean;
  boldLabel?: boolean;
  required?: boolean;
  warning?: boolean;
  filter?: boolean;
};

export const useStyles = makeStyles<Theme, UseStylesProps>(
  ({ palette, spacing }) => ({
    root: {
      width: "100%",
      fontSize: spacing(2),
      lineHeight: spacing(3),
      "& .Mui-disabled .MuiOutlinedInput-notchedOutline": {
        borderColor: palette.grey[500],
      },
      "& .MuiInputLabel-outlined": {
        pointerEvents: "visible",
      },
      "& .MuiFormLabel-root": {
        transform: "none",
        transition: "none",
        transformOrigin: "unset",
        position: "initial",
        lineHeight: ({ filter }) => filter && spacing(3),
        marginBottom: spacing(1),
        color: "#212121",
        fontWeight: ({ boldLabel }) =>
          getValue({
            condition: boldLabel,
            value: "bold",
            defaultValue: "normal",
          }),
        "& .MuiFormLabel-asterisk": {
          color: "#212121",
          display: ({ required }) => !required && "none",
        },
      },
      "& .MuiOutlinedInput-root": {
        fontSize: spacing(2.25),
        lineHeight: ({ filter }) => (filter ? spacing(3) : spacing(3.5)),
        marginRight: ({ filter }) => filter && spacing(2),
        borderRadius: spacing(0.75),
        "& .MuiOutlinedInput-input": {
          padding: spacing(1.5, 1.75),
          height: spacing(3),
        },
        "& .MuiOutlinedInput-inputMultiline": {
          padding: 0,
          height: "auto",
        },
        "& fieldset": {
          borderColor: ({ success, warning }) =>
            `${
              warning
                ? palette.grey[500]
                : success
                ? palette.grey[500]
                : palette.grey[500]
            }`,
          borderWidth: spacing(0.25),
          "& legend": {
            width: 0,
          },
        },
        "& input": {
          "&::placeholder": {
            color: "#212121",
          },
          minWidth: ({ filter }) => filter && spacing(33),
        },
        "&:not(.Mui-error):not(.Mui-disabled)": {
          "&:hover fieldset": {
            borderColor: ({ success, warning }) =>
              `${
                warning
                  ? palette.grey[500]
                  : success
                  ? palette.grey[500]
                  : palette.grey[500]
              }`,
          },
          "&.Mui-focused fieldset": {
            borderColor: ({ success, warning }) =>
              `${
                warning
                  ? palette.grey[500]
                  : success
                  ? palette.grey[500]
                  : palette.grey[500]
              }`,
          },
        },
        "& .Mui-disabled": {
          backgroundColor: palette.primary.main,
        },
      },
      "& .MuiFormHelperText-root": {
        margin: spacing(1, 0, 0),
        fontStyle: "normal",
        fontSize: "inherit",
        lineHeight: "inherit",
        display: "flex",
        alignItems: "center",
        "&:not(.Mui-error)": {
          color: ({ success, warning }) =>
            `${warning ? "#212121" : success ? "#212121" : "#212121"}`,
          "& svg": {
            color: "#212121",
          },
        },
        "& svg": {
          color: "red",
        },
      },
    },
    checkIcon: {
      color: "#212121",
    },
  })
);
