import { ReactNode } from "react";

export type DashboardLayoutProps = {
  children: ReactNode;
};

export type DashbordStyleProps = {
  isSidebarExpanded: boolean;
  isSidebarDisplayed: boolean;
};

export type UseStylesProps = DashbordStyleProps;

export enum MenuOptionTypes {
  Single = "Single",
  Group = "Group",
}
