import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import ReportProblemOutlinedIcon from "@mui/icons-material/ReportProblemOutlined";

import { NotificationIcons } from "./NotificationMessage.types";

export const notificationIcons: NotificationIcons = {
  success: <CheckCircleOutlineOutlinedIcon />,
  error: <ReportProblemOutlinedIcon />,
  info: <InfoOutlinedIcon />,
};
