import { useQuery } from "react-query";
import {
  AddDocumentSuccessResponse,
  GetAllDocumentProps,
  DeleteRoomByIdProps,
  deleteRoomByIdResponse,
  AddDocumentPdfSuccessResponse,
} from "./DocumetnGenerator.types";
import { UseMutationOptions, useMutation } from "react-query";

const getDocuments = ({ client, id }: GetAllDocumentProps) =>
  client
    .get<AddDocumentSuccessResponse>(`/api/auth/documentgenerator/${id}`)
    .then(({ data }) => data);

export const useGetDocuments = ({ client, id }: GetAllDocumentProps) =>
  useQuery(["getDocuments"], () => getDocuments({ client, id }));

const deleteDocumentById = ({ client, id }: DeleteRoomByIdProps) =>
  client
    .delete<deleteRoomByIdResponse>(`/api/auth/boardroom/${id}`)
    .then(({ data }) => data);

export const useDeleteDocumentById = (
  options: UseMutationOptions<unknown, unknown, unknown, unknown>
) =>
  useMutation(
    ({ client, id }: DeleteRoomByIdProps) => deleteDocumentById({ client, id }),
    options
  );

const getDocumentsPdf = ({ client, id }: DeleteRoomByIdProps) =>
  client
    .get<AddDocumentPdfSuccessResponse>(`/api/auth/accountopening/pdf/${id}`)
    .then(({ data }) => data);

export const useGetDocumentsPdf = (
  options: UseMutationOptions<
    AddDocumentPdfSuccessResponse,
    unknown,
    unknown,
    unknown
  >
) =>
  useMutation(
    ({ client, id }: GetAllDocumentProps) => getDocumentsPdf({ client, id }),
    options
  );
