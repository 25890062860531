import * as React from "react";
import { useStyles } from "./RequestCCSettings.styles";
import { Button, Heading, Input, Typography } from "../../../../ui/atoms";
import { updateSettingProps } from "./RequestCCSettings.types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { PushNotificationContainer } from "../../../pushNotification/PushNotificationContainer";
import { ManageUsersContainer } from "../../../userManagement/manageUser/ManageUsersContainer";
import { colors } from "../../../../constants/colors";
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

export const RequestCCSettings = ({
  onSubmit,
  register,
  reqCCSettingData,
}: updateSettingProps) => {
  const styles = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <div className={styles.root2}>
      <div className={styles.flexBox}>
        <h2
          style={{
            fontWeight: "bold",
            marginRight: 20,
            height: 280,
            // backgroundColor: "rosybrown",
          }}
        >
          Co Secretary
        </h2>
        <h2
          style={{
            fontWeight: "bold",
            marginRight: 20,
            // backgroundColor: "rosybrown",
          }}
        >
          ROC
        </h2>
      </div>
      <form noValidate onSubmit={onSubmit} className={styles.formCard}>
        {/* {reqCCSettingData?.map((data, index) => ( */}
        <div className={styles.right1}>
          <Input
            multiline
            rows="3"
            register={register}
            name="certified_by_co_title"
            label="Title"
            defaultValue={
              reqCCSettingData?.find((data) => data.id === 1)?.title
            }
            style={{
              marginBottom: 20,
            }}
            InputLabelProps={{
              style: { fontWeight: "500", color: "#344054" },
            }}
          />

          <Input
            register={register}
            name="certified_by_co_amount"
            defaultValue={
              reqCCSettingData?.find((data) => data.id === 1)?.amount
            }
            label="Amount"
            style={{ marginBottom: 20 }}
            InputLabelProps={{
              style: { fontWeight: "500", color: "#344054" },
            }}
          />
          <hr />
          <Input
            multiline
            rows="3"
            register={register}
            name="certified_by_roc_title"
            label="Title"
            defaultValue={
              reqCCSettingData?.find((data) => data.id === 2)?.title
            }
            style={{
              marginBottom: 20,
            }}
            InputLabelProps={{
              style: { fontWeight: "500", color: "#344054" },
            }}
          />

          <Input
            register={register}
            name="certified_by_roc_amount"
            defaultValue={
              reqCCSettingData?.find((data) => data.id === 2)?.amount
            }
            label="Amount"
            style={{ marginBottom: 20 }}
            InputLabelProps={{
              style: { fontWeight: "500", color: "#344054" },
            }}
          />
        </div>
        {/* ))} */}

        <div className={styles.updateButton}>
          <Button
            size="large"
            type="submit"
            variant="contained"
            bold
            className={styles.buttonUpdate}
            style={{ backgroundColor: colors.primary }}
          >
            Update
          </Button>
        </div>
      </form>
    </div>
  );
};
