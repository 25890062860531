import { useQuery } from "react-query";
import { UseMutationOptions, useMutation } from "react-query";
import { DeleteMemberByIdProps } from "../resolutions/Resolutions.types";
import {
  CreateTicket,
  AddTicketSuccessResponse,
  GetAllBoardRoomProps,
  AddBoardRoomSuccessResponse,
  deleteMemberByIdResponse,
  getTicketByIdProps,
  GetTicketByIdSuccessResponse,
  GetTicketStatusProps,
  GetStatusDescriptionProps,
  GetTicketStatusSuccessResponse,
} from "./tickets.types";
import { UseQueryOptionsType } from "../../index.types";

const getAllTickets = ({ client, id }: GetAllBoardRoomProps) =>
  client
    .get<AddBoardRoomSuccessResponse>(`/api/auth/ticket/${id}`)
    .then(({ data }) => {
      return data;
    });

export const useGetAllTickets = ({ client, id }: GetAllBoardRoomProps) =>
  useQuery(["getAllTickets"], () => getAllTickets({ client, id }));

const getTicket = ({ client, id }: GetAllBoardRoomProps) =>
  client
    .get<AddBoardRoomSuccessResponse>(`/api/auth/ticket/${id}`)
    .then(({ data }) => {
      return data;
    });

export const useGetTicket = ({ client, id }: GetAllBoardRoomProps) =>
  useQuery(["getTicket"], () => getTicket({ client, id }));

const addTicket = ({ client, body, id }: CreateTicket) =>
  client
    .post<AddTicketSuccessResponse>(`api/auth/ticket/${id}`, body, {
      headers: { "Content-Type": "multipart/form-data" },
    })
    .then(({ data }) => {
      return data;
    });

export const useAddTicket = (
  options?:
    | UseMutationOptions<
        AddTicketSuccessResponse,
        unknown,
        Pick<CreateTicket, "body">,
        unknown
      >
    | undefined
) => useMutation(addTicket, options);

const deleteTicket = ({ client, id }: DeleteMemberByIdProps) =>
  client
    .delete<deleteMemberByIdResponse>(`/api/auth/ticket/${id}`)
    .then(({ data }) => data);

export const useDeleteTicket = (
  options: UseMutationOptions<unknown, unknown, unknown, unknown>
) =>
  useMutation(
    ({ client, id }: DeleteMemberByIdProps) => deleteTicket({ client, id }),
    options
  );

const getTicketById = ({ client, id }: getTicketByIdProps) =>
  client
    .get<GetTicketByIdSuccessResponse>(`/api/auth/ticketbyid/${id}`)
    .then(({ data }) => data);

export const useGetTicketById = ({
  client,
  id,
  options,
}: getTicketByIdProps & {
  options?: UseQueryOptionsType<GetTicketByIdSuccessResponse, unknown>;
}) =>
  useQuery(["getTicketById"], () => getTicketById({ client, id }), {
    ...options,
  });

const editTicket = ({ client, body, id }: CreateTicket) =>
  client
    .post<AddTicketSuccessResponse>(`api/auth/updateticket/${id}`, body, {
      headers: { "Content-Type": "multipart/form-data" },
    })
    .then(({ data }) => data);

export const useEditTicket = (
  options?:
    | UseMutationOptions<
        AddTicketSuccessResponse,
        unknown,
        Pick<CreateTicket, "body">,
        unknown
      >
    | undefined
) => useMutation(editTicket, options);

const getTicketStatus = ({ client, docName }: GetStatusDescriptionProps) => {
  if (docName == "Waiting for client Action")
    docName = "Waiting-for-client-Action";
  if (docName == "Docs Generated") docName = "Docs-Generated";
  if (docName == "") return;

  // console.log("HELLLOOOOOOOOOOO DOCNAMEMEEE", docName);
  return client
    .get<GetTicketStatusSuccessResponse>(`/api/auth/ticketstatus/${docName}`)
    .then(({ data }) => {
      // console.log(
      //   "GET DATA SUCCESS GETTICKETSTATUS API FILE SUCCESSSSSSSSS",
      //   data
      // );
      return data;
    })
    .catch((er) => console.log("ERRRRRRRRRRRRRRROR:::::", er));
};

export const useGetTicketStatus = ({
  client,
  docName,
}: GetStatusDescriptionProps) => {
  return useQuery(["getTicketStatus"], () =>
    getTicketStatus({ client, docName })
  );
};

const editTicketStatus = ({ client, body, id }: CreateTicket) =>
  client
    .post<AddTicketSuccessResponse>(
      `api/auth/updateticketdescription/${id}`,
      body,
      {
        headers: { "Content-Type": "multipart/form-data" },
      }
    )
    .then(({ data }) => data);

export const useEditTicketStatus = (
  options?:
    | UseMutationOptions<
        AddTicketSuccessResponse,
        unknown,
        Pick<CreateTicket, "body">,
        unknown
      >
    | undefined
) => useMutation(editTicketStatus, options);
