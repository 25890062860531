export enum AppRoute {
  main = "/",
  login = "/login",
  logout = "/logout",
  forgotPassword = "/forgot-password",
  dashboard = "/dashboard",
  createBoardRoom = "/create-boardroom",
  manageBoardRoom = "/manage-boardroom",
  createUser = "/create-user",
  manageUser = "/manage-user",
  manageCustomers = "/manage-customers",
  createCustomers = "/create-customers",
  boardRoomDManagement = "/boardRoomManagement/:id",
  document = "/document",
  editBoardRoom = "/boardroom/:id/edit",
  reuestCC = "/requestcc",
  settings = "/settings",
  pushNotification = "/send-push-notification",
}
