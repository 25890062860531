import { Correspondents } from "./Correspondents";
import { useClient } from "../../../../hooks/useClient/useClient";
import { useLocale } from "../../../../hooks/useLocale/useLocale";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { managerBoardMembersModel } from "./Correspondents.types";
import { CreateBoardMemberSchema } from "./Correspondents.utils";
import { toast, ToastContainer } from "react-toastify";
import {
  useAddCorrespondent,
  useDeleteCorrespondentById,
  useGetCorrespondent,
  useGetCorrespondentsDataById,
} from "../../../../api/boardRoom/correspondents/Correspondents";

export const CorrespondentsContainer = ({ id }: { id: string | undefined }) => {
  const client = useClient();
  const { formatMessage } = useLocale();

  const [thisFile, setThisFile] = useState<string | null>("");
  const [file, setFile] = useState<File>();
  const [isOpenAddModal, setOpenAddModal] = useState(false);
  const [isOpenEditCorrespondentModal, setOpenEditCorrespondentModal] =
    useState(false);
  const [correspondentId, setCorrespondentId] = useState("");
  const [disableSubmitButton, setDisableSubmitButton] = useState(false);

  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm<managerBoardMembersModel>({
    resolver: CreateBoardMemberSchema(formatMessage),
    mode: "onBlur",
    reValidateMode: "onBlur",
  });

  const { name, description } = watch();

  const { mutate: handleAddBoardMember } = useAddCorrespondent({
    onSuccess: () => {
      toast.success("Correspondent added successfully");
      setOpenAddModal(false);
      refetch();
      reset();
      // navigate(AppRoute.manageBoardRoom);
    },
    onError: () => {
      toast.error("Fail to add correspondent");
      refetch();
      reset();
    },
  });

  const { data: boardRooms, refetch } = useGetCorrespondent({
    client,
    id: id as string,
  });

  const { data: getCorrespondentDataById } = useGetCorrespondentsDataById({
    client,
    id: correspondentId,
    options: {
      enabled: correspondentId === "" ? false : true,
    },
  });

  const onDropHandleFunction = async (acceptedFiles: any) => {
    localStorage.removeItem("corrFile");
    try {
      const acceptedFile: File = acceptedFiles[0];
      const objectURL = URL.createObjectURL(acceptedFile);
      setFile(acceptedFile);
      const reader = new FileReader();
      reader.readAsDataURL(acceptedFile);
      reader.addEventListener("load", () => {
        localStorage.setItem("corrFile", reader.result as string);
      });
      // const thumbnail = localStorage.getItem("corrFile");
      setThisFile(objectURL);
    } catch (error: unknown) {
      throw Error;
    }
  };

  const onSubmit = () => {
    setDisableSubmitButton(true);
    if (file) {
      const boardRoomData = new FormData();
      boardRoomData.append("documents[]", file as any);
      boardRoomData.append("name", name);
      boardRoomData.append("description", description);
      handleAddBoardMember({
        client,
        body: boardRoomData,
        id: id as string,
      });
    } else {
      console.log("No filessss");
    }
    localStorage.removeItem("corrFile");
  };

  const { mutate: deleteBoardMember } = useDeleteCorrespondentById({
    onSuccess: () => {
      toast.success("Correspondent deleted successfully");
      refetch();
      reset();
    },
    onError: () => {
      toast.error("Failed to delete correspondent");
      refetch();
      reset();
    },
  });

  const handleDelete = async (id: number) => {
    await deleteBoardMember({ client, id });
  };

  return (
    <>
      <Correspondents
        register={register}
        errors={errors}
        onDropHandleFunc={onDropHandleFunction}
        onSubmit={handleSubmit(onSubmit)}
        thumbnail={thisFile}
        handleDelete={handleDelete}
        id={id}
        setOpenAddModal={setOpenAddModal}
        isOpenAddModal={isOpenAddModal}
        boardRooms={boardRooms}
        correspondentId={correspondentId}
        setCorrespondentId={setCorrespondentId}
        isOpenEditCorrespondentModal={isOpenEditCorrespondentModal}
        setOpenEditCorrespondentModal={setOpenEditCorrespondentModal}
        getCorrespondentDataById={getCorrespondentDataById}
        refetch={refetch}
        disableSubmitButton={disableSubmitButton}
      />
      <ToastContainer />
    </>
  );
};
