import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

import { UseStylesProps } from "./Dashboard.types";
import {
  SIDEBAR_WIDTH,
  SIDEBAR_WIDTH_EXPANDED,
  TOPBAR_HEIGHT,
} from "./Dashboard.utils";

export const useStyles = makeStyles<Theme, UseStylesProps>(
  ({ breakpoints, spacing, palette: { primary, white } }) => ({
    wrapper: {
      display: "flex",
      flexDirection: "column",
      height: "100vh",
      backgroundColor: primary.contrastText,

      [breakpoints.up("lg")]: {
        paddingLeft: ({ isSidebarExpanded }) =>
          isSidebarExpanded ? SIDEBAR_WIDTH_EXPANDED : SIDEBAR_WIDTH,
      },

      [breakpoints.between("sm", "lg")]: {
        paddingLeft: ({ isSidebarDisplayed }) =>
          isSidebarDisplayed ? SIDEBAR_WIDTH_EXPANDED : 0,
      },

      [breakpoints.down("sm")]: {
        paddingTop: spacing(7.75),
      },
    },
    content: {
      position: "relative",
      display: "flex",
      gap: spacing(5),
      flexDirection: "column",
      flexGrow: 1,
      backgroundColor: white.main,
    },
    topbar: {
      display: "flex",
      alignItems: "center",
      position: "fixed",
      top: 0,
      left: 0,
      width: "100%",
      background: "white",
      borderBottom: spacing(0.25, "solid", "grey"),
      height: TOPBAR_HEIGHT,
      zIndex: 5,
      padding: spacing(2),

      [breakpoints.up("lg")]: {
        display: "none",
      },

      [breakpoints.down("sm")]: {
        top: 0,
        borderBottom: ({ isSidebarDisplayed }) =>
          isSidebarDisplayed ? "none" : spacing(0.25, "solid", "gray"),
        padding: spacing(1.5, 3),
        height: ({ isSidebarDisplayed }) =>
          spacing(isSidebarDisplayed ? 7.75 : 8),
      },
    },
    hamburgerMenu: {
      color: "black",
      border: "none",
      cursor: "pointer",
      backgroundColor: "transparent",
      backgroundSize: "100%",
      display: "flex",
      [breakpoints.up("sm")]: {
        marginRight: spacing(3),
      },
    },
  })
);
