import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles<Theme>(({ spacing, breakpoints }) => ({
  modal: {
    "& .MuiDialog-paperScrollPaper": {
      maxHeight: `calc(100vh - ${spacing(13.5)})`,

      [breakpoints.up("sm")]: {
        minWidth: spacing(67.5),
      },
    },
    "& .MuiDialog-container": {
      padding: spacing(5.5, 0, 8, 0),

      [breakpoints.down("sm")]: {
        padding: 0,
      },
    },
    "& .MuiDialogTitle-root": {
      padding: spacing(2, 4, 0),
    },
    "& .MuiDialogContent-root": {
      padding: spacing(4, 4, 2),
    },
    [breakpoints.down("sm")]: {
      "& .MuiPaper-root": {
        width: "100%",
        height: "100%",
        margin: 0,
        maxWidth: "100%",
        maxHeight: "none",
        borderRadius: 0,
      },
    },
    "& svg": {
      transition: "opacity .3s ease-out",
    },
  },
  dialogHeader: {
    display: "flex",
    flex: "0 0 auto",
    margin: 0,
    padding: spacing(3),
    justifyContent: "flex-end",
    position: "absolute",
    width: "100%",
  },
  close: {
    cursor: "pointer",
    zIndex: 999999,
  },
  isDisabled: {
    pointerEvents: "none",
    "& svg": {
      opacity: 0.3,
    },
  },
}));
