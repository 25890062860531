import { useQuery } from "react-query";
import {
  CreateBoardMember,
  AddBoardMemberSuccessResponse,
  GetAllBoardMemberProps,
  DeleteMemberByIdProps,
  deleteMemberByIdResponse,
  GetCorrespondentsDataByIdProps,
  GetCorrespondentsDataByIdSuccessResponse,
} from "./Correspondents.types";
import { UseMutationOptions, useMutation } from "react-query";
import { UseQueryOptionsType } from "../../index.types";

const addCorrespondent = ({ client, body, id }: CreateBoardMember) =>
  client
    .post<AddBoardMemberSuccessResponse>(
      `/api/auth/correspondence/${id}`,
      body,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    )
    .then(({ data }) => data);

export const useAddCorrespondent = (
  options?:
    | UseMutationOptions<
        AddBoardMemberSuccessResponse,
        unknown,
        Pick<CreateBoardMember, "body">,
        unknown
      >
    | undefined
) => useMutation(addCorrespondent, options);

const getCorrespondent = ({ client, id }: GetAllBoardMemberProps) =>
  client
    .get<AddBoardMemberSuccessResponse>(`/api/auth/correspondence/${id}`)
    .then(({ data }) => data);

export const useGetCorrespondent = ({ client, id }: GetAllBoardMemberProps) =>
  useQuery(["getCorrespondent"], () => getCorrespondent({ client, id }));

const deleteCorrespondentById = ({ client, id }: DeleteMemberByIdProps) =>
  client
    .delete<deleteMemberByIdResponse>(`/api/auth/correspondence/${id}`)
    .then(({ data }) => data);

export const useDeleteCorrespondentById = (
  options: UseMutationOptions<unknown, unknown, unknown, unknown>
) =>
  useMutation(
    ({ client, id }: DeleteMemberByIdProps) =>
      deleteCorrespondentById({ client, id }),
    options
  );

const getCorrespondentsDataById = ({
  client,
  id,
}: GetCorrespondentsDataByIdProps) =>
  client
    .get<GetCorrespondentsDataByIdSuccessResponse>(
      `/api/auth/getcorrespondence/${id}`
    )
    .then(({ data }) => data);

export const useGetCorrespondentsDataById = ({
  client,
  id,
  options,
}: GetCorrespondentsDataByIdProps & {
  options?: UseQueryOptionsType<
    GetCorrespondentsDataByIdSuccessResponse,
    unknown
  >;
}) =>
  useQuery(
    ["getCorrespondentsDataById"],
    () => getCorrespondentsDataById({ client, id }),
    {
      ...options,
    }
  );

const editCorrespondent = ({ client, body, id }: CreateBoardMember) =>
  client
    .post<AddBoardMemberSuccessResponse>(
      `/api/auth/updatecorrespondence/${id}`,
      body,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    )
    .then(({ data }) => data);

export const useEditCorrespondent = (
  options?:
    | UseMutationOptions<
        AddBoardMemberSuccessResponse,
        unknown,
        Pick<CreateBoardMember, "body">,
        unknown
      >
    | undefined
) => useMutation(editCorrespondent, options);
