import { Avatar, Card, CardContent, CardHeader } from "@mui/material";
import { useStyles } from "./Dashboard.styles";
import { Heading, Typography } from "../../ui/atoms";
import { GetDashBoardSuccessResponse } from "../../api/dashboard/Dashboard.types";
import { useEffect, useState } from "react";
import image1 from "./../../assets/img/1.png";
import image2 from "./../../assets/img/2.png";
import image3 from "./../../assets/img/3.png";
import image4 from "./../../assets/img/4.png";

export const Dashboard = ({
  dashboard,
}: {
  dashboard: GetDashBoardSuccessResponse | undefined;
}) => {
  console.log("dashboard", dashboard);
  const initialData = [
    {
      id: 0,
      heading: "Customers",
      count: dashboard?.no_of_users,
      avatarSrc: image1,
    },
    {
      id: 1,
      heading: "Board rooms",
      count: dashboard?.no_of_boardroom,
      avatarSrc: image2,
    },
    {
      id: 2,
      heading: "Activated customers",
      count: dashboard?.no_of_active_users,
      avatarSrc: image3,
    },
    {
      id: 3,
      heading: "Documents generated",
      count: dashboard?.no_of_document_generated,
      avatarSrc: image4,
    },
  ];
  const [data, setData] = useState(initialData);

  useEffect(() => {
    if (dashboard) {
      setData([
        {
          id: 0,
          heading: "Customers",
          count: dashboard.no_of_users,
          avatarSrc: image1,
        },
        {
          id: 1,
          heading: "Board rooms",
          count: dashboard.no_of_boardroom,
          avatarSrc: image2,
        },
        {
          id: 2,
          heading: "Activated customers",
          count: dashboard.no_of_active_users,
          avatarSrc: image3,
        },
        {
          id: 3,
          heading: "Documents generated",
          count: dashboard.no_of_document_generated,
          avatarSrc: image4,
        },
      ]);
    }
  }, [dashboard]);

  const styles = useStyles();
  return (
    <div className={styles.root}>
      <Heading>
        <h1 style={{ marginBottom: 0 }}>Dashboard</h1>
      </Heading>
      <p style={{ color: "#667085", marginTop: 0 }}>
        Your current summary and activity.
      </p>
      <div className={styles.mainDiv}>
        {data.map((item, index) => (
          <Card sx={{ width: 345, backgroundColor: "#F2F0FC" }}>
            <CardHeader
              avatar={
                <Avatar
                  src={`${item.avatarSrc}`}
                  // className={}
                  style={{ borderRadius: 0, width: 60, height: 60 }}
                  variant="rounded"
                />
              }
              key={index}
              title={
                <div>
                  <Typography variant="h6" style={{}}>
                    No of
                  </Typography>
                  <Typography variant="h6" fontWeight={"bold"}>
                    {item.heading}
                  </Typography>
                </div>
              }
            />

            <CardContent>
              <Typography
                variant="h4"
                color="text.secondary"
                fontWeight={"bold"}
              >
                {item.count}
              </Typography>
            </CardContent>
          </Card>
        ))}
      </div>
      {/* <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: 30,
            marginTop: 20,
          }}
        >
          <Card elevation={10} className={styles.bigCard}>
            <CChart
              type="bar"
              width={900}
              height={400}
              data={{
                labels: [
                  "January",
                  "February",
                  "March",
                  "April",
                  "May",
                  "June",
                  "July",
                  "August",
                  "September",
                  "October",
                  "November",
                  "December",
                ],
                datasets: [
                  {
                    label: "New customers",
                    backgroundColor: "rgba(255, 205, 86, 0.2)",
                    data: [
                      40, 20, 12, 39, 10, 40, 39, 80, 40, 2, 54, 65, 23, 54, 32,
                    ],
                    borderColor: "rgb(255, 205, 86)",
                    borderWidth: 1,
                  },
                ],
              }}
            />
          </Card>
        </div> */}
    </div>
  );
};
