import { useQuery } from "react-query";
import { UseMutationOptions, useMutation } from "react-query";
import { DeleteMemberByIdProps } from "../resolutions/Resolutions.types";
import {
  CreateRegistrationDesk,
  AddRegistrationDeskSuccessResponse,
  GetAllBoardRoomProps,
  AddBoardRoomSuccessResponse,
  deleteMemberByIdResponse,
  getRegistrationDeskByIdProps,
  GetRegistrationDeskByIdSuccessResponse,
} from "./RegistrationDesk.types";
import { UseQueryOptionsType } from "../../index.types";

const addRegistrationDesk = ({ client, body, id }: CreateRegistrationDesk) =>
  client
    .post<AddRegistrationDeskSuccessResponse>(
      `api/auth/registrationdesk/${id}`,
      body,
      {
        headers: { "Content-Type": "multipart/form-data" },
      }
    )
    .then(({ data }) => data);

export const useAddRegistrationDesk = (
  options?:
    | UseMutationOptions<
        AddRegistrationDeskSuccessResponse,
        unknown,
        Pick<CreateRegistrationDesk, "body">,
        unknown
      >
    | undefined
) => useMutation(addRegistrationDesk, options);

const getRegistrationDesk = ({ client, id }: GetAllBoardRoomProps) =>
  client
    .get<AddBoardRoomSuccessResponse>(`/api/auth/registrationdesk/${id}`)
    .then(({ data }) => data);

export const useGetRegistrationDesk = ({ client, id }: GetAllBoardRoomProps) =>
  useQuery(["getRegistrationDesk"], () => getRegistrationDesk({ client, id }));

const deleteRegistrationDesk = ({ client, id }: DeleteMemberByIdProps) =>
  client
    .delete<deleteMemberByIdResponse>(`/api/auth/registrationdesk/${id}`)
    .then(({ data }) => data);

export const useDeleteRegistrationDesk = (
  options: UseMutationOptions<unknown, unknown, unknown, unknown>
) =>
  useMutation(
    ({ client, id }: DeleteMemberByIdProps) =>
      deleteRegistrationDesk({ client, id }),
    options
  );

const getRegistrationDeskById = ({
  client,
  id,
}: getRegistrationDeskByIdProps) =>
  client
    .get<GetRegistrationDeskByIdSuccessResponse>(
      `/api/auth/getregistrationdesk/${id}`
    )
    .then(({ data }) => data);

export const useGetRegistrationDeskById = ({
  client,
  id,
  options,
}: getRegistrationDeskByIdProps & {
  options?: UseQueryOptionsType<
    GetRegistrationDeskByIdSuccessResponse,
    unknown
  >;
}) =>
  useQuery(
    ["getRegistrationDeskById"],
    () => getRegistrationDeskById({ client, id }),
    {
      ...options,
    }
  );

const editRegistrationDesk = ({ client, body, id }: CreateRegistrationDesk) =>
  client
    .post<AddRegistrationDeskSuccessResponse>(
      `api/auth/updateregistrationdesk/${id}`,
      body,
      {
        headers: { "Content-Type": "multipart/form-data" },
      }
    )
    .then(({ data }) => data);

export const useEditRegistrationDesk = (
  options?:
    | UseMutationOptions<
        AddRegistrationDeskSuccessResponse,
        unknown,
        Pick<CreateRegistrationDesk, "body">,
        unknown
      >
    | undefined
) => useMutation(editRegistrationDesk, options);
