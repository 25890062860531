import { useClient } from "../../../../hooks/useClient/useClient";
import { useLocale } from "../../../../hooks/useLocale/useLocale";
import { Tickets } from "./Tickets";
import { useForm } from "react-hook-form";
import { CreateRegistrationDeskInputs } from "./Tickets.types";
import { CreateRegistrationDeskSchema } from "./Tickets.utils";
import { toast, ToastContainer } from "react-toastify";
import {
  useAddTicket,
  useDeleteTicket,
  useEditTicketStatus,
  useGetAllTickets,
  useGetTicket,
  useGetTicketById,
  useGetTicketStatus,
} from "../../../../api/boardRoom/tickets/tickets";
import { useEffect, useState } from "react";
import { AppRoute } from "../../../../routing/AppRoute.enum";
import { useNavigate } from "react-router-dom";
import { TabTypes } from "../BoardRoomTabsEnum";
import LoadingIcon from "../../../../assets/icons/loading.gif";
import moment from "moment";

export const TicketContainer = ({ id }: { id: string | undefined }) => {
  const client = useClient();
  const { formatMessage } = useLocale();
  const navigate = useNavigate();

  const [thisFile, setThisFile] = useState<any>("");
  const [file, setFile] = useState<File>();
  const [isLoading, setIsLoading] = useState(false);
  const [isOpenContactsModal, setOpenContactsModal] = useState(false);
  const [isOpenAddModal, setOpenAddModal] = useState(false);
  const [openEditTicketModal, setOpenEditTicketModal] = useState(false);
  const [ticketId, setTicketId] = useState("");
  const [disableSubmitButton, setDisableSubmitButton] = useState(false);
  const [date, setDate] = useState<Date | null>(new Date());
  const [effectiveDate, setEffectiveDate] = useState<Date | null>(new Date());
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm<CreateRegistrationDeskInputs>({
    resolver: CreateRegistrationDeskSchema(formatMessage),
    mode: "onBlur",
    reValidateMode: "onBlur",
  });

  const [reqId, setReqId] = useState(0);
  const [statusId, setStatusId] = useState(0);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [selectedDescription, setSelectedDescription] = useState("");

  const [selectedRow, setSelectedRow] = useState<number>(0);
  const [reloadEditModal, setReloadEditModal] = useState(false);

  const { document_name, description } = watch();

  const { mutate: handleAddTicket } = useAddTicket({
    onSuccess: () => {
      setIsLoading(false);
      toast.success("Ticket added successfully");
      navigate(AppRoute.boardRoomDManagement.replace(":id", id as string), {
        state: TabTypes.Tickets,
      });
      setOpenAddModal(false);
      refetch();
      reset();
    },
    onError: () => {
      setIsLoading(false);
      toast.error("Fail to add data");
      navigate(AppRoute.boardRoomDManagement.replace(":id", id as string), {
        state: TabTypes.Tickets,
      });
      refetch();
      reset();
    },
  });

  const { data: getTicketsDataById, refetch: editRefetch } = useGetTicketById({
    client,
    id: ticketId,
    options: {
      enabled: ticketId !== "" ? true : false,
    },
  });

  const { data: ticketsData, refetch } = useGetAllTickets({
    client,
    id: id as string,
  });

  const { data: descriptionsData, refetch: myrefresh } = useGetTicketStatus({
    client,
    docName: selectedStatus,
  });

  useEffect(() => {
    setIsLoading(true);

    myrefresh()
      .then(() => setIsLoading(false))
      .catch(() => setIsLoading(false));
  }, [selectedStatus]);

  useEffect(() => {
    if (reloadEditModal) {
      refetch();
      editRefetch();
    }
    setReloadEditModal(false);
  }, [getTicketsDataById, id, selectedRow, reloadEditModal]);

  const onDropHandleFunction = async (acceptedFiles: any) => {
    localStorage.removeItem("RegFile");
    try {
      const acceptedFile: File = acceptedFiles[0];
      setFile(acceptedFile);
      const reader = new FileReader();
      reader.readAsDataURL(acceptedFile);
      reader.addEventListener("load", () => {
        setThisFile(reader.result);
        localStorage.setItem("RegFile", reader.result as string);
      });
      // const thumbnail = localStorage.getItem("RegFile");
      // setThisFile(thumbnail);
    } catch (error: unknown) {
      throw Error;
    }
  };

  const onSubmit = async () => {
    setDisableSubmitButton(true);
    setIsLoading(true);

    const ticketPayload = new FormData();

    ticketPayload.append("document_name", document_name);
    ticketPayload.append("description", description);
    ticketPayload.append(
      "document_effect_date",
      moment(effectiveDate).format("YYYY-MM-D")
    );

    handleAddTicket({
      client,
      body: ticketPayload,
      id: id as string,
    });
  };

  const handleDeleteTicket = async (id: number) => {
    deleteTicket({ client, id });
  };

  const { mutate: deleteTicket } = useDeleteTicket({
    onSuccess: () => {
      toast.success("Ticket Deleted Successfully");
      refetch();
      // setInterval(() => window.location.reload(), 1000);
    },
    onError: () => {
      toast.error("Unable to delete Ticket");
      refetch();
    },
  });

  const { mutate: handleEditTicketStatus } = useEditTicketStatus({
    onSuccess: () => {
      setIsLoading(false);
      toast.success("Status updated successfully");
      refetch();
      // reset();
      // setInterval(() => window.location.reload(), 1000);
    },
    onError: () => {
      setIsLoading(false);
      toast.error("Fail to update status");
      // refetch();
      // reset();
    },
  });

  const handleOnSubmit = async () => {
    // console.log("DOCUMENT NAME", getDataByReqId?.data.document_name);
    // console.log("EFFECTIVE DATE", getDataByReqId?.data.document_effect_date);
    // console.log("DESCRIPTION", getDataByReqId?.data.description);
    setIsLoading(true);
    console.log("STATUS_DESCRIPTION", selectedDescription);
    console.log("ID", reqId);
    console.log("STATUS::", selectedStatus);
    console.log("STATUS_ID::", statusId);

    if (reqId === 0) {
      toast.error("Please select a ticket to update");
    }

    // editTicket.append("document_name", `${getDataByReqId?.data.document_name}`);
    // editTicket.append("description", `${getDataByReqId?.data.description}`);
    // editTicket.append(
    //   "document_effect_date",
    //   `${moment(getDataByReqId?.data.document_effect_date).format("YYYY-MM-D")}`
    // );
    const editTicket = new FormData();
    editTicket.append("status", `${selectedStatus}`);
    editTicket.append("status_description", `${selectedDescription}`);
    editTicket.append("status_id", `${statusId}`);

    if (selectedDescription == "") {
      toast.error("Please select a description");
      setIsLoading(false);
      return;
    }

    await handleEditTicketStatus({
      client,
      body: editTicket,
      id: `${reqId}` as string,
    });
  };

  return (
    <>
      {isLoading && (
        <div
          style={{
            height: "100%",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "fixed",
            top: 0,
            left: 0,
            backgroundColor: "rgba(255, 255, 255, 0.8)",
            zIndex: 9999,
          }}
        >
          <img src={LoadingIcon} alt="my-gif" />
        </div>
      )}
      <Tickets
        reqId={reqId}
        setReqId={setReqId}
        register={register}
        errors={errors}
        onDropHandleFunc={onDropHandleFunction}
        onSubmit={handleSubmit(onSubmit)}
        thumbnail={thisFile}
        ticketsData={ticketsData}
        setOpenContactsModal={setOpenContactsModal}
        setOpenAddModal={setOpenAddModal}
        isOpenContactsModal={isOpenContactsModal}
        isOpenAddModal={isOpenAddModal}
        handleDeleteTicket={handleDeleteTicket}
        openEditTicketModal={openEditTicketModal}
        setOpenEditTicketModal={setOpenEditTicketModal}
        ticketId={ticketId}
        selectedStatus={selectedStatus}
        setSelectedStatus={setSelectedStatus}
        selectedDescription={selectedDescription}
        statusId={statusId}
        setStatusId={setStatusId}
        setSelectedDescription={setSelectedDescription}
        setTicketId={setTicketId}
        getTicketsDataById={getTicketsDataById}
        disableSubmitButton={disableSubmitButton}
        date={date}
        setDate={setDate}
        effectiveDate={effectiveDate}
        setEffectiveDate={setEffectiveDate}
        descriptionsData={descriptionsData?.data}
        handleOnSubmit={handleOnSubmit}
        selectedRow={selectedRow}
        setSelectedRow={setSelectedRow}
        reloadEditModal={reloadEditModal}
        setReloadEditModal={setReloadEditModal}
      />
      <ToastContainer />
    </>
  );
};
