import { useForm } from "react-hook-form";
import {
  ManageRequestModalContainersProps,
  updateTrackingModalBody,
} from "./ManageRequestModal.types";
import { ManageRequestModal } from "./ManageRequestModal";
import { toast } from "react-toastify";
import { useUpdateTrackingData } from "../../../api/requestCC/requestCC";
import { useClient } from "../../../hooks/useClient/useClient";

export const ManageRequestModalContainer = ({
  requestCCopyId,
  handleStatusChangeAPICall,
  ...props
}: ManageRequestModalContainersProps) => {
  const client = useClient();

  const { mutate: handleUpdateTrackingData } = useUpdateTrackingData({
    onSuccess: async () => {
      toast.success("Tracking Data updated successfully");
      setInterval(() => window.location.reload(), 1000);
    },
    onError: () => {
      toast.error("Failed to update Tracking Data");
    },
  });

  const { register, handleSubmit } = useForm<updateTrackingModalBody>({});

  const handleOnSubmit = async (values: updateTrackingModalBody) => {
    const TrackingData = new URLSearchParams();
    TrackingData.append("tracking_id", `${values.tracking_id}`);
    TrackingData.append("tracking_url", `${values.tracking_url}`);

    handleUpdateTrackingData({
      client,
      body: TrackingData,
      id: requestCCopyId,
    });
    handleStatusChangeAPICall();
  };
  return (
    <ManageRequestModal
      onTrackingModalSubmit={handleSubmit(handleOnSubmit)}
      register={register}
      requestCCopyId={requestCCopyId}
      {...props}
    />
  );
};
