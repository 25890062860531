import { useQuery } from "react-query";
import {
  CreateBoardRoom,
  AddBoardRoomSuccessResponse,
  GetAllBoardRoomProps,
  DeleteRoomByIdProps,
  deleteRoomByIdResponse,
  AssignBoardRoom,
  AssignBoardRoomSuccessResponse,
  GetBoardRoomByIDProps,
  GetBoardRoomSuccessResponse,
  EditBoardRoom,
  GetBoardRoomUserSuccessResponse,
  AddBoardRoomNoPaginationSuccessResponse,
  GetAssignedBoardRoomUsersProps,
  GetAssignedBoardRoomUsersSuccessResponse,
} from "./boardRoom.types";
import { UseMutationOptions, useMutation } from "react-query";
import { UseQueryOptionsType } from "../index.types";

const addBoardRoom = ({ client, body }: CreateBoardRoom) =>
  client
    .post<AddBoardRoomSuccessResponse>(`/api/auth/boardroom`, body, {
      headers: { "Content-Type": "multipart/form-data" },
    })
    .then(({ data }) => data);

export const useAddBoardRoom = (
  options?:
    | UseMutationOptions<
        AddBoardRoomSuccessResponse,
        unknown,
        Pick<CreateBoardRoom, "body">,
        unknown
      >
    | undefined
) => useMutation(addBoardRoom, options);

const getBoardRooms = ({ client, searchValue, page }: GetAllBoardRoomProps) =>
  client
    .get<AddBoardRoomSuccessResponse>(
      `/api/auth/boardroom?search=${searchValue}&page=${page}`
    )
    .then(({ data }) => data);

export const useGetBoardRooms = ({
  client,
  searchValue,
  page,
}: GetAllBoardRoomProps) =>
  useQuery(
    ["getBoardRooms"],
    () => getBoardRooms({ client, searchValue, page }),
    {
      refetchOnWindowFocus: true,
      refetchOnReconnect: true,
      retry: true,
    }
  );

const getBoardRoomsNoPagination = ({
  client,
  searchValue,
  page,
}: GetAllBoardRoomProps) =>
  client
    .get<AddBoardRoomNoPaginationSuccessResponse>(
      `/api/auth/boardroom?search=${searchValue}&page=${page}`
    )
    .then(({ data }) => data);

export const useGetBoardRoomsNoPagination = ({
  client,
  searchValue,
  page,
}: GetAllBoardRoomProps) =>
  useQuery(
    ["getBoardRoomsNoPagination"],
    () => getBoardRoomsNoPagination({ client, searchValue, page }),
    {
      refetchOnWindowFocus: true,
      refetchOnReconnect: true,
      retry: true,
    }
  );

const deleteBoardRoomById = ({ client, id }: DeleteRoomByIdProps) =>
  client
    .delete<deleteRoomByIdResponse>(`/api/auth/boardroom/${id}`)
    .then(({ data }) => data);

export const useDeleteBoardRoomById = (
  options: UseMutationOptions<unknown, unknown, unknown, unknown>
) =>
  useMutation(
    ({ client, id }: DeleteRoomByIdProps) =>
      deleteBoardRoomById({ client, id }),
    options
  );

const assignBoardRoom = ({ client, body }: AssignBoardRoom) =>
  client
    .post<AssignBoardRoomSuccessResponse>(`/api/auth/assign/boardroom`, body)
    .then(({ data }) => data);

export const useAssignBoardRoom = (
  options?:
    | UseMutationOptions<
        AssignBoardRoomSuccessResponse,
        unknown,
        Pick<AssignBoardRoom, "body">,
        unknown
      >
    | undefined
) => useMutation(assignBoardRoom, options);

const editBoardRoom = ({ client, body, id }: EditBoardRoom) =>
  client
    .post<AddBoardRoomSuccessResponse>(`/api/auth/boardroom/${id}`, body, {
      headers: { "Content-Type": "multipart/form-data" },
    })
    .then(({ data }) => data);

export const useEditBoardRoom = (
  options?:
    | UseMutationOptions<
        AddBoardRoomSuccessResponse,
        unknown,
        Pick<CreateBoardRoom, "body">,
        unknown
      >
    | undefined
) => useMutation(editBoardRoom, options);

const getBoardRoomById = ({ client, id }: GetBoardRoomByIDProps) =>
  client
    .get<GetBoardRoomSuccessResponse>(`/api/auth/boardroom/${id}`)
    .then(({ data }) => data);

export const useGetBoardRoomById = ({ client, id }: GetBoardRoomByIDProps) =>
  useQuery([`getBoardRoomById-${id}`], () => getBoardRoomById({ client, id }));

// const getBoardRoomUsersId = ({ client, id }: GetBoardRoomByIDProps) =>
//   client
//     .get<GetBoardRoomUserSuccessResponse>(
//       `/api/auth/assignedboardroomusers/${id}`
//     )
//     .then(({ data }) => data);

// export const useGetBoardRoomUsersId = ({ client, id }: GetBoardRoomByIDProps) =>
//   useQuery(["getBoardRoomUsersId"], () => getBoardRoomUsersId({ client, id }));

const unAssignBoardRoom = ({ client, body }: AssignBoardRoom) =>
  client
    .post<AssignBoardRoomSuccessResponse>(`/api/auth/unassign/boardroom`, body)
    .then(({ data }) => data);

export const useUnAssignBoardRoom = (
  options?:
    | UseMutationOptions<
        AssignBoardRoomSuccessResponse,
        unknown,
        Pick<AssignBoardRoom, "body">,
        unknown
      >
    | undefined
) => useMutation(unAssignBoardRoom, options);

const getBoardRoomUsersId = ({ client, id }: GetBoardRoomByIDProps) =>
  client
    .get<GetBoardRoomUserSuccessResponse>(
      `/api/auth/assignedboardroomusers/${id}`
    )
    .then(({ data }) => data);

export const useGetBoardRoomUsersId = (
  options: UseMutationOptions<
    GetBoardRoomUserSuccessResponse,
    unknown,
    unknown,
    unknown
  >
) =>
  useMutation(
    ({ client, id }: GetBoardRoomByIDProps) =>
      getBoardRoomUsersId({ client, id }),
    options
  );

const getAssignedBoardRoomUsers = ({
  client,
  id,
}: GetAssignedBoardRoomUsersProps) =>
  client
    .get<GetAssignedBoardRoomUsersSuccessResponse>(
      `/api/auth/assignedboardroomusers/${id}`
    )
    .then(({ data }) => data);

export const useGetAssignedBoardRoomUsers = ({
  client,
  id,
  options,
}: GetAssignedBoardRoomUsersProps & {
  options?: UseQueryOptionsType<
    GetAssignedBoardRoomUsersSuccessResponse,
    unknown
  >;
}) =>
  useQuery(
    ["getAssignedBoardRoomUsers"],
    () => getAssignedBoardRoomUsers({ client, id }),
    {
      ...options,
    }
  );
