import { Tooltip } from "@mui/material";
import { manageBoardRoomProps } from "./DocumentGenerator.types";
import { useGetDocuments } from "../../../../api/document/Document";
import { useClient } from "../../../../hooks/useClient/useClient";
import { SuccessData } from "../../../../api/document/Document.types";
import { Column } from "react-table";
import { useMemo } from "react";
import { useStyles } from "./DocumentGenerator.styles";
import { Table } from "../../../../ui/organisms/table/Table";
import { useGetDocumentsPdf } from "../../../../api/boardRoom/documentGenerator/DocumentGenerator";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";

export const DocumentGenerator = ({
  handleDelete,
  id,
}: manageBoardRoomProps) => {
  const client = useClient();
  const styles = useStyles();

  const { data: boardRooms } = useGetDocuments({
    client,
    id,
  });

  const { mutate: mutateOnClick } = useGetDocumentsPdf({
    onSuccess: (data) => {
      openInNewTab(data ? data.url : "");
    },
  });

  const openInNewTab = (url: string) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  const columns: Column<SuccessData>[] = useMemo(
    () => [
      {
        id: "name",
        Header: "Document Name",
        accessor: "name",
        Cell: ({ value }) => (
          <div className={styles.truncatedText}>{value}</div>
        ),
      },
      {
        id: "created_at",
        Header: "Created At",
        accessor: "created_at",
        Cell: ({ value }) => (
          <div className={styles.truncatedText}>{value}</div>
        ),
      },
      {
        id: "id",
        Header: "Actions",
        accessor: "id",
        Cell: ({ value }) => (
          <div style={{ gap: 20, display: "flex", flexDirection: "row" }}>
            <div className={styles.truncatedText}>
              <div
                className={styles.actionButtons}
                onClick={async (event: React.MouseEvent<HTMLElement>) => {
                  event.stopPropagation();
                  await mutateOnClick({ client, id: value });
                }}
              >
                <Tooltip title="View Document">
                  <PictureAsPdfIcon color="info" />
                </Tooltip>
              </div>
            </div>
          </div>
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [boardRooms?.data]
  );

  return (
    <div>
      <Table
        columns={columns}
        data={boardRooms?.data || []}
        className={styles.table}
      />
    </div>
  );
};
