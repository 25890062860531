import { UseMutationOptions, useMutation, useQuery } from "react-query";

import {
  GetAllBoardRoomProps,
  GetAllBoardRoomResponse,
  GetAllUserProps,
  GetAllUserResponse,
  sendPushNotificationProps,
  sendPushNotificationSucessResponse,
} from "./sendPushNotification.types";
import { UseQueryOptionsType } from "../index.types";

const getAllBoardRoomData = ({ client }: GetAllBoardRoomProps) =>
  client
    .get<GetAllBoardRoomResponse>(`/api/auth/boardroom?page=0`)
    .then(({ data }) => data);

export const useGetAllBoardRoomData = ({
  client,
}: GetAllBoardRoomProps & {
  options?: UseQueryOptionsType<GetAllBoardRoomResponse, unknown>;
}) =>
  useQuery(["getAllBoardRoomData"], () => getAllBoardRoomData({ client }), {
    refetchOnWindowFocus: true,
    refetchOnReconnect: true,
    retry: true,
  });

const getAllUserData = ({ client }: GetAllUserProps) =>
  client.get<GetAllUserResponse>(`/api/auth/allusers`).then(({ data }) => data);

export const useGetAllUserData = ({
  client,
}: GetAllUserProps & {
  options?: UseQueryOptionsType<GetAllUserResponse, unknown>;
}) =>
  useQuery(["getAllUserData"], () => getAllUserData({ client }), {
    refetchOnWindowFocus: true,
    refetchOnReconnect: true,
    retry: true,
  });

const sendPushNotification = ({ client, body }: sendPushNotificationProps) =>
  client
    .post<sendPushNotificationSucessResponse>(
      `/api/auth/sendnotification`,
      body,
      {
        headers: { "Content-Type": "multipart/form-data" },
      }
    )
    .then(({ data }) => data);

export const useSendPushNotification = (
  options?:
    | UseMutationOptions<
        sendPushNotificationSucessResponse,
        unknown,
        Pick<sendPushNotificationProps, "body">,
        unknown
      >
    | undefined
) => useMutation(sendPushNotification, options);
