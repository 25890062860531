import { Resolution } from "./Resolution";
import { useLocale } from "../../../../hooks/useLocale/useLocale";
import { useClient } from "../../../../hooks/useClient/useClient";
import {
  useAddResolutions,
  useAddShareholdersResolutions,
  useDeleteResolutionById,
  useDeleteShareHolderResolutionById,
  useGetResolutions,
  useGetResolutionsById,
  useGetShareHolderResolutionsById,
  useGetshareholdersResolutions,
} from "../../../../api/boardRoom/resolutions/Resolutions";
import { toast, ToastContainer } from "react-toastify";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { CreateRegistrationDeskInputs } from "./Resolution.types";
import {
  CreateRegistrationDeskSchema,
  CreateShareHolderResolutionSchema,
} from "../registrationDesk/RegistrationDesk.utils";
import { AppRoute } from "../../../../routing/AppRoute.enum";
import { TabTypes } from "../BoardRoomTabsEnum";
import LoadingIcon from "../../../../assets/icons/loading.gif";

export const ResolutionContainer = ({ id }: { id: string | undefined }) => {
  const { formatMessage } = useLocale();
  const client = useClient();
  const navigate = useNavigate();

  const [thisFile, setThisFile] = useState<any>("");
  const [file, setFile] = useState<File>();
  const [isLoading, setIsLoading] = useState(false);
  const [isOpenAddBusinessModal, setOpenAddBusinessModal] = useState(false);

  const [thisFileShare, setThisFileShare] = useState<any>("");
  const [fileShare, setFileShare] = useState<File>();
  const [isShareLoading, setIsShareLoading] = useState(false);
  const [isOpenAddShareModal, setOpenAddShareModal] = useState(false);
  const [isOpenEditBoardResolutionModal, setIsOpenEditBoardResolutionModal] =
    useState(false);
  const [boardResolutionId, setBoardResolutionId] = useState("");
  const [
    isOpenEditShareHolderResolutionModal,
    setIsOpenEditShareHolderResolutionModal,
  ] = useState(false);
  const [shareHolderResolutionId, setShareHolderResolutionId] = useState("");
  const [
    disableBoardResolutionSubmitButton,
    setDisableBoardResolutionSubmitButton,
  ] = useState(false);
  const [disableShreHolderSubmitButton, setDisableShreHolderSubmitButton] =
    useState(false);

  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm<CreateRegistrationDeskInputs>({
    resolver: CreateRegistrationDeskSchema(formatMessage),
    mode: "onBlur",
    reValidateMode: "onBlur",
  });

  const {
    register: shareRegister,
    handleSubmit: shareSubmit,
    watch: shareWatch,
    reset: shareReset,
    formState: { errors: shareErrors },
  } = useForm<CreateRegistrationDeskInputs>({
    resolver: CreateShareHolderResolutionSchema(formatMessage),
    mode: "onBlur",
    reValidateMode: "onBlur",
  });

  const { name, description, resolution_number } = watch();
  const { name: shareName, description: shareDescription, resolution_number: shareResolution_number } = shareWatch();

  const { mutate: handleAddRegistrationDesk } = useAddResolutions({
    onSuccess: () => {
      setIsLoading(false);
      toast.success("Board Resolution added successfully");
      navigate(AppRoute.boardRoomDManagement.replace(":id", id as string), {
        state: TabTypes.Resolutions,
      });
      resoluRefresh();
      reset();
    },
    onError: () => {
      setIsLoading(false);
      toast.error("Fail to add data");
      navigate(AppRoute.boardRoomDManagement.replace(":id", id as string), {
        state: TabTypes.Resolutions,
      });
      resoluRefresh();
      reset();
    },
  });

  const { mutate: handleAddShareHlderRegnDesk } = useAddShareholdersResolutions(
    {
      onSuccess: () => {
        setIsShareLoading(false);
        toast.success("Shareholder Resolution added successfully");
        navigate(AppRoute.boardRoomDManagement.replace(":id", id as string), {
          state: TabTypes.Resolutions,
        });
        refetch();
        shareReset();
      },
      onError: () => {
        setIsShareLoading(false);
        toast.error("Fail to add data");
        navigate(AppRoute.boardRoomDManagement.replace(":id", id as string), {
          state: TabTypes.Resolutions,
        });
        refetch();
        shareReset();
      },
    }
  );

  const onDropBoardHandleFunction = async (acceptedFiles: any) => {
    try {
      const acceptedFile: File = acceptedFiles[0];
      setFile(acceptedFile);
      const reader = new FileReader();
      reader.readAsDataURL(acceptedFile);
      reader.addEventListener("load", () => {
        setThisFile(reader.result);
      });
    } catch (error: unknown) {
      throw Error;
    }
  };

  const onDropShareHandleFunction = async (acceptedFiles: any) => {
    try {
      const acceptedFile: File = acceptedFiles[0];

      setFileShare(acceptedFile);
      const reader = new FileReader();
      reader.readAsDataURL(acceptedFile);
      reader.addEventListener("load", () => {
        setThisFileShare(reader.result);
      });
    } catch (error: unknown) {
      throw Error;
    }
  };

  const { data: resolutions, refetch: resoluRefresh } = useGetResolutions({
    client,
    id: id as string,
  });

  const { data: shareholders, refetch } = useGetshareholdersResolutions({
    client,
    id: id as string,
  });

  const { data: getBoardResolutionById } = useGetResolutionsById({
    client,
    id: boardResolutionId,
    options: {
      enabled: boardResolutionId === "" ? false : true,
    },
  });

  const { data: getShareHolderResolutionById } =
    useGetShareHolderResolutionsById({
      client,
      id: shareHolderResolutionId,
      options: {
        enabled: shareHolderResolutionId === "" ? false : true,
      },
    });

  const onBoardSubmit = async () => {
    setDisableBoardResolutionSubmitButton(true);
    setIsLoading(true);
    if (file) {
      const boardRoomData = new FormData();
      boardRoomData.append("documents[]", file as any);
      boardRoomData.append("name", name);
      boardRoomData.append("description", description);
      // @ts-ignore
      boardRoomData.append("resolution_number", resolution_number);

      handleAddRegistrationDesk({
        client,
        body: boardRoomData,
        id: id as string,
      });
    }
    await resoluRefresh();
    setOpenAddBusinessModal(false);
    localStorage.removeItem("thumbnail");
  };

  const onShareSubmit = async () => {
    setDisableShreHolderSubmitButton(true);
    setIsShareLoading(true);
    if (fileShare) {
      const boardRoomData = new FormData();
      boardRoomData.append("documents[]", fileShare as any);
      boardRoomData.append("name", shareName);
      boardRoomData.append("description", shareDescription);
      boardRoomData.append("resolution_number", shareResolution_number);

      handleAddShareHlderRegnDesk({
        client,
        body: boardRoomData,
        id: id as string,
      });
    }
    await refetch();
    setOpenAddShareModal(false);
    localStorage.removeItem("thumbnail1");
  };

  const { mutate: deleteBoardResolution } = useDeleteResolutionById({
    onSuccess: () => {
      toast.success("Board Resolution Deleted Successfully");
      resoluRefresh();
    },
    onError: () => {
      toast.error("Unable to delete Board Resolution");
      resoluRefresh();
    },
  });

  const handleDeleteBoardResolution = async (id: number) => {
    await deleteBoardResolution({ client, id });
  };

  const { mutate: deleteShareHolderResolution } =
    useDeleteShareHolderResolutionById({
      onSuccess: () => {
        toast.success("Board Resolution Deleted Successfully");
        refetch();
      },
      onError: () => {
        toast.error("Unable to delete Board Resolution");
        refetch();
      },
    });

  const handleDeleteShareHolderResolution = async (id: number) => {
    await deleteShareHolderResolution({ client, id });
  };

  return (
    <>
      <Resolution
        register={register}
        errors={errors}
        onDropBoardHandleFunction={onDropBoardHandleFunction}
        onBoardSubmit={handleSubmit(onBoardSubmit)}
        resolutions={resolutions}
        setOpenAddBusinessModal={setOpenAddBusinessModal}
        isOpenAddBusinessModal={isOpenAddBusinessModal}
        id={id}
        handleDeleteBoardResolution={handleDeleteBoardResolution}
        handleDeleteShareHolderResolution={handleDeleteShareHolderResolution}
        thumbnail={thisFile}
        resoluRefresh={resoluRefresh}
        onDropShareHandleFunction={onDropShareHandleFunction}
        onShareSubmit={shareSubmit(onShareSubmit)}
        shareholders={shareholders}
        setOpenAddShareModal={setOpenAddShareModal}
        isOpenAddShareModal={isOpenAddShareModal}
        thumbnail2={thisFileShare}
        refetch={refetch}
        shareRegister={shareRegister}
        shareErrors={shareErrors}
        isOpenEditBoardResolutionModal={isOpenEditBoardResolutionModal}
        setIsOpenEditBoardResolutionModal={setIsOpenEditBoardResolutionModal}
        boardResolutionId={boardResolutionId}
        setBoardResolutionId={setBoardResolutionId}
        getBoardResolutionById={getBoardResolutionById}
        isOpenEditShareHolderResolutionModal={
          isOpenEditShareHolderResolutionModal
        }
        setIsOpenEditShareHolderResolutionModal={
          setIsOpenEditShareHolderResolutionModal
        }
        shareHolderResolutionId={shareHolderResolutionId}
        setShareHolderResolutionId={setShareHolderResolutionId}
        getShareHolderResolutionById={getShareHolderResolutionById}
        disableBoardResolutionSubmitButton={disableBoardResolutionSubmitButton}
        disableShreHolderSubmitButton={disableShreHolderSubmitButton}
      />
      <ToastContainer />
      {(isLoading || isShareLoading) && (
        <div
          style={{
            height: "100%",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
            alignItems: "center",
          }}
        >
          <img src={LoadingIcon} alt="my-gif" />
        </div>
      )}
    </>
  );
};
