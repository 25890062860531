import {
  Button,
  DialogContent,
  Input,
  Typography,
} from "../../../../../ui/atoms";
import { useStyles } from "./EditRegistrationDeskModal.styles";
import { ManageRequestModalProps } from "./EditRegistrationDeskModal.types";
import { Modal } from "../../../../../ui/organisms/modal/Modal";
import { DropzoneBox } from "../../../../../ui/atoms/dropZoneBox/DropZoneBox";
import { PDFViewer } from "react-view-pdf";

export const ManageRequestModal = ({
  register,
  isOpen,
  onClose,
  onTrackingModalSubmit,
  errors,
  thumbnail,
  onDropHandleFunc,
  buttonDisabled,
  setButtonDisabled,
  getRegistrationDeskDataById,
}: ManageRequestModalProps) => {
  const styles = useStyles();
  const accept = {
    "application/pdf": [],
  };
  return (
    <>
      <Modal open={isOpen} onClose={() => onClose()}>
        <form
          noValidate
          // onSubmit={onTrackingModalSubmit}
          className={styles.cardForm}
        >
          <DialogContent>
            {/* {getRegistrationDeskDataById} */}
            <div className={styles.head}>
              <div className={styles.rowDiv}>
                <Typography
                  align="center"
                  variant="h4"
                  className={styles.lable}
                >
                  Edit Registration Desk
                </Typography>
                <Input
                  required
                  label="Index"
                  aria-label="index"
                  placeholder={"Enter sort order"}
                  name="index"
                  className={styles.input}
                  error={!!errors?.index}
                  helperText={errors?.index?.message}
                  type="number"
                  // defaultValue={getRegistrationDeskDataById?.data.name}
                  register={register}
                />
                <Input
                  required
                  label="Name"
                  aria-label="name"
                  placeholder={"Enter name"}
                  name="name"
                  className={styles.input}
                  error={!!errors?.name}
                  helperText={errors?.name?.message}
                  // defaultValue={getRegistrationDeskDataById?.data.name}
                  register={register}
                />
                <Input
                  required
                  label="Description"
                  aria-label="description"
                  placeholder={"Enter Description"}
                  name="description"
                  className={styles.input}
                  error={!!errors?.description}
                  helperText={errors?.description?.message}
                  // defaultValue={getRegistrationDeskDataById?.data.description}
                  register={register}
                />
                <div className={styles.rowDiv}>
                  {thumbnail !== null && thumbnail.length !== 0 ? (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "5%",
                        width: "100%",
                      }}
                    >
                      <PDFViewer url={thumbnail} />
                      <div style={{ width: "28%" }}>
                        <DropzoneBox
                          multiple={false}
                          description="Choose document"
                          onDrop={onDropHandleFunc}
                          accept={accept}
                        >
                          + Choose Document
                        </DropzoneBox>
                      </div>
                    </div>
                  ) : (
                    <DropzoneBox
                      multiple={false}
                      description="Choose document"
                      onDrop={onDropHandleFunc}
                      accept={accept}
                    >
                      + Choose Document
                    </DropzoneBox>
                  )}
                </div>
              </div>
            </div>
          </DialogContent>
          <Modal.Footer>
            <div className={styles.buttonsContainer}>
              <Button
                type="button"
                bold
                size="large"
                // onClick={() => setOpenAddModal(false)}
                variant="outlined"
                className={styles.buttonyes}
                onClick={() => onClose()}
              >
                Cancel
              </Button>

              <Button
                // disabled={buttonDisabled}
                onClick={() => onTrackingModalSubmit()}
                size="large"
                type="button"
                variant="outlined"
                color="inherit"
                bold
                className={styles.closeButton}
              >
                Update
              </Button>
            </div>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};
