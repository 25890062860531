import { useMemo, useState } from "react";
import { Column } from "react-table";
import { useStyles } from "./ManageUser.styles";
import { Table } from "../../../ui/organisms/table/Table";
import { Button, DialogContent, Typography } from "../../../ui/atoms";
import { Modal } from "../../../ui/organisms/modal/Modal";
import { useGetAdminUsers } from "../../../api/userManagement/userManagement";
import { useClient } from "../../../hooks/useClient/useClient";
import { getAdminUsers } from "../../../api/userManagement/userManagement.types";
import moment from "moment";
import { ManageUsersProps } from "./ManageUser.types";
import { Tooltip } from "@mui/material";
import ToggleOnIcon from "@mui/icons-material/ToggleOn";
import ToggleOffIcon from "@mui/icons-material/ToggleOff";

export const ManageUsers = ({
  onSubmit,
  setAdminStatus,
  setId,
}: ManageUsersProps) => {
  const styles = useStyles();
  const [isOpenContactsModal, setOpenContactsModal] = useState(false);
  const [isOpenActivateModal, setOpenActivateModal] = useState(false);
  const [activated, setActivated] = useState("");
  const client = useClient();

  const { data: userData, refetch } = useGetAdminUsers({
    client,
  });

  const columns: Column<getAdminUsers>[] = useMemo(
    () => [
      {
        id: "first_name",
        Header: "First Name",
        accessor: "first_name",
        Cell: ({ value }) => (
          <div className={styles.truncatedText}>{value}</div>
        ),
      },
      {
        id: "last_name",
        Header: "Last Name",
        accessor: "last_name",
        Cell: ({ value }) => (
          <div className={styles.truncatedText}>{value}</div>
        ),
      },
      {
        id: "email",
        Header: "Email",
        accessor: "email",
        Cell: ({ value }) => (
          <div className={styles.truncatedText}>{value}</div>
        ),
      },
      {
        id: "phone",
        Header: "Phone",
        accessor: "phone",
        Cell: ({ value }) => (
          <div className={styles.truncatedText}>{value}</div>
        ),
      },
      {
        id: "created_at",
        Header: "Created At",
        accessor: "created_at",
        Cell: ({ value }) => (
          <div className={styles.truncatedText}>
            {moment(value).format("YYYY-MM-DD")}
          </div>
        ),
      },
      // {
      //   id: "updated_at",
      //   Header: "Updated At",
      //   accessor: "updated_at",
      //   Cell: ({ value }) => (
      //     <div className={styles.truncatedText}>
      //       {moment(value).format("YYYY-MM-DD")}
      //     </div>
      //   ),
      // },
      {
        id: "is_active",
        Header: "Is Activated",
        accessor: "is_active",
        Cell: ({ value }) => (
          <div className={styles.truncatedText}>
            {value === "1" ? "Activated" : "Not activated"}
          </div>
        ),
      },
      {
        id: "id",
        Header: "Actions",
        accessor: "id",
        Cell: ({
          row: {
            original: { is_active, id },
          },
        }) => (
          <div className={styles.truncatedText}>
            <div className={styles.actionButtons}>
              {/* <Button
                className={styles.buttonView}
                variant="outlined"
                onClick={() => setOpenContactsModal(true)}
              >
                Delete
              </Button> */}
              <Button
                variant="text"
                onClick={async () => {
                  await setId(id);
                  await setActivated(is_active);
                  if (is_active === "1") {
                    await setAdminStatus("0");
                  } else {
                    await setAdminStatus("1");
                  }
                  await setOpenActivateModal(true);
                }}
              >
                {is_active === "1" ? (
                  <Tooltip title="Activated">
                    <ToggleOnIcon color="success" />
                  </Tooltip>
                ) : (
                  <Tooltip title="De-activated">
                    <ToggleOffIcon color="error" />
                  </Tooltip>
                )}
              </Button>
              {/* <Button
                variant="outlined"
                onClick={() => navigate(AppRoute.createUser)}
              >
                Edit
              </Button> */}
            </div>
          </div>
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [userData]
  );

  return (
    <div className={styles.root}>
      <div className={styles.mainDiv}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: 15,
            width: "100%",
          }}
        >
          <Table
            columns={columns}
            data={userData?.data || []}
            className={styles.table}
          />
        </div>
      </div>
      {isOpenContactsModal && (
        <Modal
          open={isOpenContactsModal}
          onClose={() => setOpenContactsModal(false)}
        >
          <DialogContent>
            <div className={styles.head}>
              <Typography align="center" variant="h5" className={styles.lable}>
                Are you sure you want to delete this user?
              </Typography>
            </div>
          </DialogContent>
          <Modal.Footer>
            <div className={styles.buttonsContainer}>
              <Button
                type="button"
                bold
                size="large"
                onClick={() => setOpenContactsModal(false)}
                variant="outlined"
                className={styles.closeButton}
              >
                No
              </Button>

              <Button
                size="large"
                type="button"
                variant="outlined"
                color="inherit"
                bold
                className={styles.buttonyes}
              >
                Yes
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
      )}
      {isOpenActivateModal && (
        <Modal
          open={isOpenActivateModal}
          onClose={() => setOpenActivateModal(false)}
        >
          <DialogContent>
            <div className={styles.head}>
              <Typography align="center" variant="h5" className={styles.lable}>
                {` Are you sure you want to ${
                  activated === "1" ? "inactive" : "activate"
                } this user?`}
              </Typography>
            </div>
          </DialogContent>
          <Modal.Footer>
            <div className={styles.buttonsContainer}>
              <Button
                type="button"
                bold
                size="large"
                onClick={() => {
                  setOpenActivateModal(false);
                }}
                variant="outlined"
                className={styles.buttonyes}
              >
                No
              </Button>

              <Button
                size="large"
                type="button"
                variant="outlined"
                color="inherit"
                bold
                className={styles.closeButton}
                onClick={async () => {
                  await onSubmit();
                  await refetch();
                  setOpenActivateModal(false);
                }}
              >
                Yes
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
      )}
    </div>
  );
};
