import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles<Theme>(
  ({ breakpoints, palette, spacing, typography }) => ({
    root: {
      width: "105%",
      padding: "5%",
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      // alignContent: "center",
      // alignItems: "center",
      alignSelf: "center",
    },
    card: {
      backgroundColor: palette.primary.contrastText,
      width: "95%",
      padding: "5%",
      flexDirection: "row",
      alignContent: "center",
      alignItems: "center",
      alignSelf: "center",
      flexWrap: "unset",
    },
    header: {
      fontFamily: "",
      marginBottom: spacing(5),
      fontSize: spacing(6),
      alignItems: "center",
      justifyContent: "center",
      alignSelf: "center",
      [breakpoints.only("sm")]: {
        marginBottom: spacing(4),
      },

      [breakpoints.down("lg")]: {
        fontSize: typography.h3.fontSize,
        lineHeight: typography.h3.lineHeight,
      },
    },
    input: {
      fontFamily: "",
      marginBottom: spacing(3),
      color: "#212121",
      borderColor: ` ${palette.grey[300]} !important`,
    },
    // submit: {
    //   // color: "white"
    //   display: "flex",
    //   justifyContent: "center",
    //   alignContent: "center",
    //   alignItems: "center",
    //   width: `200px !important`,
    //   margin: `${spacing(1, 2)} !important`,
    //   color: `${palette.green.main} !important`,
    //   borderColor: `${palette.green.main} !important`,
    // },
    cancel: {
      // color: "white"
      display: "flex",
      justifyContent: "center",
      alignContent: "center",
      alignItems: "center",
      width: `200px !important`,
      margin: `${spacing(1, 2)} !important`,
      color: `${palette.red.main} !important`,
      borderColor: `${palette.red.main} !important`,
    },
    rowDiv: {
      display: "flex",
      flexDirection: "column",
      gap: spacing(2),
      marginBottom: spacing(3),
    },
    buttonRow: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-around",
    },
    heading: {
      paddingBottom: spacing(2),
    },
    mainDiv: {
      padding: spacing(1),
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      alignItems: "center",
      alignSelf: "flex-start",
      gap: spacing(2),
      width: "65% !important",
    },
    mainDiv2: {
      padding: spacing(1),
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      alignItems: "center",
      alignSelf: "flex-start",
      gap: spacing(2),
      width: "55% !important",
      marginLeft: "2%",
    },
    table: {
      gridTemplateColumns: () =>
        `${spacing(25)} ${spacing(25)} ${spacing(25)} !important`,
      '& [class*="td"]:last-child': {
        justifyContent: "flex-start",
        paddingRight: spacing(7),
        paddingTop: spacing(2),
        paddingBottom: spacing(2),
      },
    },
    submit: {
      color: "black !important",
      display: "flex !important",
      justifyContent: "flex-end !important",
      alignItems: "flex-end !important",
      alignSelf: "flex-end !important",
      borderColor: "black !important",
      fontWeight: "bold !important",
      marginRight: "10% !important",
    },
    buttonDiv: {
      width: "20%",
      display: "contents",
      marginBottom: "200px !important",
      backgroundColor: "red !important",
      // display: "flex",
      marginRight: "20% !important",
    },
    actionButtons: {
      display: "flex",
      gap: spacing(1),
      justifyContent: "center !important",
      alignItems: "center !important",
      alignSelf: "center !important",
    },
    buttonsContainer: {
      display: "flex",
      gap: spacing(2),
    },
    buttonView2: {
      color: `${palette.red.main} !important`,
      borderColor: `${palette.red.main} !important`,
    },
  })
);
