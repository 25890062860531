import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { palette } from "../../theme/palette";

export const useStyles = makeStyles<Theme>(
  ({ breakpoints, spacing, typography: { fontWeightBold, h3 }, palette }) => ({
    root: {
      width: "100%",
      display: "flex",
      flexDirection: "row",
    },
    flexBox: {
      // backgroundColor: "green",
    },
    formCard: {
      // backgroundColor: "greenyellow",
      padding: 25,
    },
    head: {
      display: "flex",
      FlexDirection: "column",
      width: "100%",
    },
    heading: {
      padding: `${spacing(2)}`,
      margin: `${spacing(0)} ${spacing(10)} ${spacing(0)} ${spacing(2)} `,
    },
    row: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      gap: `${spacing(20)}`,
      marginLeft: `${spacing(6)}`,
    },
    input: {
      fontFamily: "",
      marginBottom: `${spacing(3)} !important`,
      color: "#212121",
      borderColor: ` ${palette.grey[300]} !important`,
    },
    amountInput: {
      width: `${spacing(60)}`,
    },
    updateButton: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-end",
      justifyContent: "flex-end",
    },
    buttonUpdate: {
      // margin: `${spacing(1, 2)} !important`,
      color: `${palette.white} !important`,
      borderColor: `${palette.blue} !important`,
      // background: '##213c97 !important',
    },
    columnField: {
      margin: `${spacing(2)}`,
    },
    card: {
      backgroundColor: palette.primary.contrastText,
      // width: "95%",
      padding: "5%",
      flexDirection: "row",
      alignContent: "center",
      alignItems: "center",
      alignSelf: "center",
      flexWrap: "unset",
    },
  })
);
