import { useDispatch, useSelector } from "react-redux";

import { createClient } from "../../api/index";
import { allReducerStates } from "../../store/store.utils";
import { onSetTokens } from "../../store/authenticationReducer/authenticationReducer";

export const useClient = () => {
  const dispatch = useDispatch();
  const {
    authentication: {
      loginUserDetails: { access_token },
    },
  } = useSelector(allReducerStates);

  const tokens = { access_token };
  const setTokens = (tokens: { access_token: string }) =>
    dispatch(onSetTokens(tokens));

  const axiosInstance = createClient(setTokens, tokens);

  return axiosInstance;
};
