import { useState } from "react";
import { useForm } from "react-hook-form";
import { CreateBoardroomInputs } from "./CreateBoardRoom.types";
import { CreateBoardroomSchema } from "./CreateBoardRoom.utils";
import { useLocale } from "../../../hooks/useLocale/useLocale";
import { toast, ToastContainer } from "react-toastify";
import { AppRoute } from "../../../routing/AppRoute.enum";
import { useNavigate } from "react-router-dom";
import { useAddBoardRoom } from "../../../api/boardRoom/boardRoom";
import { useClient } from "../../../hooks/useClient/useClient";
import { CreateBoardRoom } from "./CreateBoardRoom";
import moment from "moment";

export const CreateBoardRoomContainer = () => {
  const client = useClient();
  const { formatMessage } = useLocale();
  const navigate = useNavigate();

  const [thisFile, setThisFile] = useState<any>("");
  const [file, setFile] = useState<File>();
  const [dateOfInCoperation, setDateOfInCoperation] = useState<Date | null>(
    new Date()
  );

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<CreateBoardroomInputs>({
    resolver: CreateBoardroomSchema(formatMessage),
    mode: "onBlur",
    reValidateMode: "onBlur",
  });

  const { name, description, address, whatsAppGroupLink } = watch();

  const { mutate: handleAddBoardRoom } = useAddBoardRoom({
    onSuccess: () => {
      toast.success("Boardroom created successfully");
      navigate(AppRoute.manageBoardRoom);
    },
    onError: () => {
      toast.error("Failed to create boardroom");
    },
  });

  const onDropHandleFunction = async (acceptedFiles: any) => {
    localStorage.removeItem("thumbnail");
    try {
      const acceptedFile: File = acceptedFiles[0];
      setFile(acceptedFile);
      const reader = new FileReader();
      reader.readAsDataURL(acceptedFile);
      reader.addEventListener("load", () => {
        setThisFile(reader.result);
      });
    } catch (error: unknown) {
      throw Error;
    }
  };
  const onSubmit = () => {
    if (file) {
      const boardRoomData = new FormData();
      boardRoomData.append("image", file as any);
      boardRoomData.append("name", name);
      boardRoomData.append("description", description);
      boardRoomData.append("address", address);
      boardRoomData.append(
        "incorporation_date",
        moment(dateOfInCoperation).format("YYYY-MM-D")
      );
      boardRoomData.append("whatsapp_link", `${whatsAppGroupLink}`);
      handleAddBoardRoom({
        client,
        body: boardRoomData,
      });
    }
  };

  return (
    <>
      <CreateBoardRoom
        register={register}
        errors={errors}
        onDropHandleFunc={onDropHandleFunction}
        onSubmit={handleSubmit(onSubmit)}
        thumbnail={thisFile}
        dateOfInCoperation={dateOfInCoperation}
        setDateOfInCoperation={setDateOfInCoperation}
      />
      <ToastContainer autoClose={3000} />
    </>
  );
};
