import { UseMutationOptions, useMutation } from "react-query";

import { api } from "../index";

import { LoginBody, LoginSuccessResponse } from "./signin.types";

const login = (body: LoginBody) =>
  api
    .post<LoginSuccessResponse>("/api/auth/adminlogin", body)
    .then(({ data }) => data);

export const useLogin = (
  options?:
    | UseMutationOptions<LoginSuccessResponse, unknown, LoginBody, unknown>
    | undefined
) => useMutation(login, options);
