import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles<Theme>(
  ({ breakpoints, spacing, palette: { gray, background } }) => ({
    actionsWrapper: {
      [breakpoints.down("sm")]: {
        padding: 0,
      },
      backgroundColor: background.paper,
    },
    actions: {
      padding: spacing(2, 0),
      borderTop: spacing(0.125, "solid", gray.main),
      display: "flex",
      justifyContent: "flex-end",
      [breakpoints.down("sm")]: {
        padding: spacing(2),
      },
    },
  })
);
