import { useMediaQuery, useTheme } from "@mui/material";
import { Menu, Close } from "@mui/icons-material";
import { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { AppRoute } from "../../../routing/AppRoute.enum";

import { useStyles } from "./Dashboard.styles";
import { DashboardLayoutProps } from "./Dashboard.types";
import { GlobalLayout } from "./globalLayout/GlobalLayout";
import { Sidebar } from "./sidebar/Sidebar";

export const DashboardLayout = ({ children }: DashboardLayoutProps) => {
  const navigate = useNavigate();
  // it's necessary because we always want to display the sidebar on desktop devices but on tablet and mobile on-demand
  const isDesktop = useMediaQuery(useTheme().breakpoints.up("lg"));
  const [isSidebarDisplayed, setIsSidebarDisplayed] = useState(false);
  const [isSidebarExpanded, setIsSidebarExpanded] = useState(true);
  const isInitialMount = useRef(true);

  const styles = useStyles({
    isSidebarExpanded,
    isSidebarDisplayed,
  });

  const handleLogoutConfirmation = () => {
    navigate(AppRoute.login);
  };

  const handleSidebarExpanded = () => setIsSidebarExpanded(true);
  // setIsSidebarExpanded((previousIsExpanded) => !previousIsExpanded);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
      return;
    }

    if (!isDesktop && isSidebarDisplayed) {
      setIsSidebarExpanded(true);
    }
  }, [isDesktop, isSidebarDisplayed]);

  return (
    <GlobalLayout>
      <div className={styles.wrapper}>
        <div className={styles.topbar}>
          <div
            className={styles.hamburgerMenu}
            onClick={() =>
              setIsSidebarDisplayed(
                (previousShowSidebar) => !previousShowSidebar
              )
            }
            role="button"
            aria-label="Menu"
            tabIndex={0}
          >
            {isSidebarDisplayed ? <Close /> : <Menu />}
          </div>
          {/* <StageClipLogoWithText className={styles.stageClipLogo} /> */}
          {/* <StageClipLogoWithTextMobile className={styles.stageClipLogoMobile} /> */}
        </div>
        {(isSidebarDisplayed || isDesktop) && (
          <Sidebar
            isSidebarExpanded={isSidebarExpanded}
            isSidebarDisplayed={isSidebarDisplayed}
            onSidebarExpanded={handleSidebarExpanded}
            onLogoutConfirmation={handleLogoutConfirmation}
            handleSidebarDisplayed={setIsSidebarDisplayed}
          />
        )}
        <div className={styles.content}>{children}</div>
      </div>
    </GlobalLayout>
  );
};
